const scriptRel = "modulepreload";
const assetsURL = function(dep, importerUrl) {
  return new URL(dep, importerUrl).href;
};
const seen = {};
const __vitePreload = function preload(baseModule, deps, importerUrl) {
  if (!deps || deps.length === 0) {
    return baseModule();
  }
  const links = document.getElementsByTagName("link");
  return Promise.all(deps.map((dep) => {
    dep = assetsURL(dep, importerUrl);
    if (dep in seen)
      return;
    seen[dep] = true;
    const isCss = dep.endsWith(".css");
    const cssSelector = isCss ? '[rel="stylesheet"]' : "";
    const isBaseRelative = !!importerUrl;
    if (isBaseRelative) {
      for (let i = links.length - 1; i >= 0; i--) {
        const link2 = links[i];
        if (link2.href === dep && (!isCss || link2.rel === "stylesheet")) {
          return;
        }
      }
    } else if (document.querySelector(`link[href="${dep}"]${cssSelector}`)) {
      return;
    }
    const link = document.createElement("link");
    link.rel = isCss ? "stylesheet" : scriptRel;
    if (!isCss) {
      link.as = "script";
      link.crossOrigin = "";
    }
    link.href = dep;
    document.head.appendChild(link);
    if (isCss) {
      return new Promise((res, rej) => {
        link.addEventListener("load", res);
        link.addEventListener("error", () => rej(new Error(`Unable to preload CSS for ${dep}`)));
      });
    }
  })).then(() => baseModule()).catch((err) => {
    const e = new Event("vite:preloadError", { cancelable: true });
    e.payload = err;
    window.dispatchEvent(e);
    if (!e.defaultPrevented) {
      throw err;
    }
  });
};
(function polyfill() {
  const relList = document.createElement("link").relList;
  if (relList && relList.supports && relList.supports("modulepreload")) {
    return;
  }
  for (const link of document.querySelectorAll('link[rel="modulepreload"]')) {
    processPreload(link);
  }
  new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.type !== "childList") {
        continue;
      }
      for (const node of mutation.addedNodes) {
        if (node.tagName === "LINK" && node.rel === "modulepreload")
          processPreload(node);
      }
    }
  }).observe(document, { childList: true, subtree: true });
  function getFetchOpts(link) {
    const fetchOpts = {};
    if (link.integrity)
      fetchOpts.integrity = link.integrity;
    if (link.referrerPolicy)
      fetchOpts.referrerPolicy = link.referrerPolicy;
    if (link.crossOrigin === "use-credentials")
      fetchOpts.credentials = "include";
    else if (link.crossOrigin === "anonymous")
      fetchOpts.credentials = "omit";
    else
      fetchOpts.credentials = "same-origin";
    return fetchOpts;
  }
  function processPreload(link) {
    if (link.ep)
      return;
    link.ep = true;
    const fetchOpts = getFetchOpts(link);
    fetch(link.href, fetchOpts);
  }
})();
/*! (c) Andrea Giammarchi @webreflection ISC */
(function() {
  var attributesObserver = function(whenDefined2, MutationObserver2) {
    var attributeChanged = function attributeChanged2(records) {
      for (var i = 0, length = records.length; i < length; i++)
        dispatch(records[i]);
    };
    var dispatch = function dispatch2(_ref2) {
      var target = _ref2.target, attributeName = _ref2.attributeName, oldValue = _ref2.oldValue;
      target.attributeChangedCallback(attributeName, oldValue, target.getAttribute(attributeName));
    };
    return function(target, is2) {
      var attributeFilter = target.constructor.observedAttributes;
      if (attributeFilter) {
        whenDefined2(is2).then(function() {
          new MutationObserver2(attributeChanged).observe(target, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter
          });
          for (var i = 0, length = attributeFilter.length; i < length; i++) {
            if (target.hasAttribute(attributeFilter[i]))
              dispatch({
                target,
                attributeName: attributeFilter[i],
                oldValue: null
              });
          }
        });
      }
      return target;
    };
  };
  function _unsupportedIterableToArray(o, minLen) {
    if (!o)
      return;
    if (typeof o === "string")
      return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor)
      n = o.constructor.name;
    if (n === "Map" || n === "Set")
      return Array.from(o);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
      return _arrayLikeToArray(o, minLen);
  }
  function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length)
      len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++)
      arr2[i] = arr[i];
    return arr2;
  }
  function _createForOfIteratorHelper(o, allowArrayLike) {
    var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];
    if (!it) {
      if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
        if (it)
          o = it;
        var i = 0;
        var F = function() {
        };
        return {
          s: F,
          n: function() {
            if (i >= o.length)
              return {
                done: true
              };
            return {
              done: false,
              value: o[i++]
            };
          },
          e: function(e) {
            throw e;
          },
          f: F
        };
      }
      throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }
    var normalCompletion = true, didErr = false, err;
    return {
      s: function() {
        it = it.call(o);
      },
      n: function() {
        var step = it.next();
        normalCompletion = step.done;
        return step;
      },
      e: function(e) {
        didErr = true;
        err = e;
      },
      f: function() {
        try {
          if (!normalCompletion && it.return != null)
            it.return();
        } finally {
          if (didErr)
            throw err;
        }
      }
    };
  }
  /*! (c) Andrea Giammarchi - ISC */
  var TRUE = true, FALSE = false, QSA$1 = "querySelectorAll";
  var notify = function notify2(callback) {
    var root = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : document;
    var MO = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : MutationObserver;
    var query2 = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : ["*"];
    var loop2 = function loop3(nodes, selectors, added, removed, connected, pass) {
      var _iterator = _createForOfIteratorHelper(nodes), _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done; ) {
          var node = _step.value;
          if (pass || QSA$1 in node) {
            if (connected) {
              if (!added.has(node)) {
                added.add(node);
                removed["delete"](node);
                callback(node, connected);
              }
            } else if (!removed.has(node)) {
              removed.add(node);
              added["delete"](node);
              callback(node, connected);
            }
            if (!pass)
              loop3(node[QSA$1](selectors), selectors, added, removed, connected, TRUE);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    };
    var mo = new MO(function(records) {
      if (query2.length) {
        var selectors = query2.join(",");
        var added = /* @__PURE__ */ new Set(), removed = /* @__PURE__ */ new Set();
        var _iterator2 = _createForOfIteratorHelper(records), _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done; ) {
            var _step2$value = _step2.value, addedNodes = _step2$value.addedNodes, removedNodes = _step2$value.removedNodes;
            loop2(removedNodes, selectors, added, removed, FALSE, FALSE);
            loop2(addedNodes, selectors, added, removed, TRUE, FALSE);
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
    });
    var observe = mo.observe;
    (mo.observe = function(node) {
      return observe.call(mo, node, {
        subtree: TRUE,
        childList: TRUE
      });
    })(root);
    return mo;
  };
  var QSA = "querySelectorAll";
  var _self$1 = self, document$2 = _self$1.document, Element$1 = _self$1.Element, MutationObserver$2 = _self$1.MutationObserver, Set$2 = _self$1.Set, WeakMap$1 = _self$1.WeakMap;
  var elements = function elements2(element) {
    return QSA in element;
  };
  var filter = [].filter;
  var qsaObserver = function(options) {
    var live = new WeakMap$1();
    var drop = function drop2(elements2) {
      for (var i = 0, length = elements2.length; i < length; i++)
        live["delete"](elements2[i]);
    };
    var flush = function flush2() {
      var records = observer.takeRecords();
      for (var i = 0, length = records.length; i < length; i++) {
        parse2(filter.call(records[i].removedNodes, elements), false);
        parse2(filter.call(records[i].addedNodes, elements), true);
      }
    };
    var matches = function matches2(element) {
      return element.matches || element.webkitMatchesSelector || element.msMatchesSelector;
    };
    var notifier = function notifier2(element, connected) {
      var selectors;
      if (connected) {
        for (var q, m = matches(element), i = 0, length = query2.length; i < length; i++) {
          if (m.call(element, q = query2[i])) {
            if (!live.has(element))
              live.set(element, new Set$2());
            selectors = live.get(element);
            if (!selectors.has(q)) {
              selectors.add(q);
              options.handle(element, connected, q);
            }
          }
        }
      } else if (live.has(element)) {
        selectors = live.get(element);
        live["delete"](element);
        selectors.forEach(function(q2) {
          options.handle(element, connected, q2);
        });
      }
    };
    var parse2 = function parse3(elements2) {
      var connected = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
      for (var i = 0, length = elements2.length; i < length; i++)
        notifier(elements2[i], connected);
    };
    var query2 = options.query;
    var root = options.root || document$2;
    var observer = notify(notifier, root, MutationObserver$2, query2);
    var attachShadow2 = Element$1.prototype.attachShadow;
    if (attachShadow2)
      Element$1.prototype.attachShadow = function(init2) {
        var shadowRoot = attachShadow2.call(this, init2);
        observer.observe(shadowRoot);
        return shadowRoot;
      };
    if (query2.length)
      parse2(root[QSA](query2));
    return {
      drop,
      flush,
      observer,
      parse: parse2
    };
  };
  var _self = self, document$1 = _self.document, Map = _self.Map, MutationObserver$1 = _self.MutationObserver, Object$1 = _self.Object, Set$1 = _self.Set, WeakMap = _self.WeakMap, Element = _self.Element, HTMLElement2 = _self.HTMLElement, Node = _self.Node, Error2 = _self.Error, TypeError$1 = _self.TypeError, Reflect = _self.Reflect;
  var defineProperty = Object$1.defineProperty, keys = Object$1.keys, getOwnPropertyNames = Object$1.getOwnPropertyNames, setPrototypeOf = Object$1.setPrototypeOf;
  var legacy = !self.customElements;
  var expando = function expando2(element) {
    var key = keys(element);
    var value = [];
    var ignore = new Set$1();
    var length = key.length;
    for (var i = 0; i < length; i++) {
      value[i] = element[key[i]];
      try {
        delete element[key[i]];
      } catch (SafariTP) {
        ignore.add(i);
      }
    }
    return function() {
      for (var _i = 0; _i < length; _i++)
        ignore.has(_i) || (element[key[_i]] = value[_i]);
    };
  };
  if (legacy) {
    var HTMLBuiltIn = function HTMLBuiltIn2() {
      var constructor = this.constructor;
      if (!classes2.has(constructor))
        throw new TypeError$1("Illegal constructor");
      var is2 = classes2.get(constructor);
      if (override)
        return augment(override, is2);
      var element = createElement2.call(document$1, is2);
      return augment(setPrototypeOf(element, constructor.prototype), is2);
    };
    var createElement2 = document$1.createElement;
    var classes2 = new Map();
    var defined = new Map();
    var prototypes2 = new Map();
    var registry = new Map();
    var query = [];
    var handle = function handle2(element, connected, selector) {
      var proto = prototypes2.get(selector);
      if (connected && !proto.isPrototypeOf(element)) {
        var redefine = expando(element);
        override = setPrototypeOf(element, proto);
        try {
          new proto.constructor();
        } finally {
          override = null;
          redefine();
        }
      }
      var method = "".concat(connected ? "" : "dis", "connectedCallback");
      if (method in proto)
        element[method]();
    };
    var _qsaObserver = qsaObserver({
      query,
      handle
    }), parse = _qsaObserver.parse;
    var override = null;
    var whenDefined = function whenDefined2(name) {
      if (!defined.has(name)) {
        var _, $ = new Promise(function($2) {
          _ = $2;
        });
        defined.set(name, {
          $,
          _
        });
      }
      return defined.get(name).$;
    };
    var augment = attributesObserver(whenDefined, MutationObserver$1);
    self.customElements = {
      define: function define2(is2, Class) {
        if (registry.has(is2))
          throw new Error2('the name "'.concat(is2, '" has already been used with this registry'));
        classes2.set(Class, is2);
        prototypes2.set(is2, Class.prototype);
        registry.set(is2, Class);
        query.push(is2);
        whenDefined(is2).then(function() {
          parse(document$1.querySelectorAll(is2));
        });
        defined.get(is2)._(Class);
      },
      get: function get2(is2) {
        return registry.get(is2);
      },
      whenDefined
    };
    defineProperty(HTMLBuiltIn.prototype = HTMLElement2.prototype, "constructor", {
      value: HTMLBuiltIn
    });
    self.HTMLElement = HTMLBuiltIn;
    document$1.createElement = function(name, options) {
      var is2 = options && options.is;
      var Class = is2 ? registry.get(is2) : registry.get(name);
      return Class ? new Class() : createElement2.call(document$1, name);
    };
    if (!("isConnected" in Node.prototype))
      defineProperty(Node.prototype, "isConnected", {
        configurable: true,
        get: function get2() {
          return !(this.ownerDocument.compareDocumentPosition(this) & this.DOCUMENT_POSITION_DISCONNECTED);
        }
      });
  } else {
    legacy = !self.customElements.get("extends-br");
    if (legacy) {
      try {
        var BR = function BR2() {
          return self.Reflect.construct(HTMLBRElement, [], BR2);
        };
        BR.prototype = HTMLLIElement.prototype;
        var is = "extends-br";
        self.customElements.define("extends-br", BR, {
          "extends": "br"
        });
        legacy = document$1.createElement("br", {
          is
        }).outerHTML.indexOf(is) < 0;
        var _self$customElements = self.customElements, get = _self$customElements.get, _whenDefined = _self$customElements.whenDefined;
        self.customElements.whenDefined = function(is2) {
          var _this = this;
          return _whenDefined.call(this, is2).then(function(Class) {
            return Class || get.call(_this, is2);
          });
        };
      } catch (o_O) {
      }
    }
  }
  if (legacy) {
    var _parseShadow = function _parseShadow2(element) {
      var root = shadowRoots.get(element);
      _parse(root.querySelectorAll(this), element.isConnected);
    };
    var customElements = self.customElements;
    var _createElement = document$1.createElement;
    var define = customElements.define, _get = customElements.get, upgrade = customElements.upgrade;
    var _ref = Reflect || {
      construct: function construct2(HTMLElement3) {
        return HTMLElement3.call(this);
      }
    }, construct = _ref.construct;
    var shadowRoots = new WeakMap();
    var shadows = new Set$1();
    var _classes = new Map();
    var _defined = new Map();
    var _prototypes = new Map();
    var _registry = new Map();
    var shadowed = [];
    var _query = [];
    var getCE = function getCE2(is2) {
      return _registry.get(is2) || _get.call(customElements, is2);
    };
    var _handle = function _handle2(element, connected, selector) {
      var proto = _prototypes.get(selector);
      if (connected && !proto.isPrototypeOf(element)) {
        var redefine = expando(element);
        _override = setPrototypeOf(element, proto);
        try {
          new proto.constructor();
        } finally {
          _override = null;
          redefine();
        }
      }
      var method = "".concat(connected ? "" : "dis", "connectedCallback");
      if (method in proto)
        element[method]();
    };
    var _qsaObserver2 = qsaObserver({
      query: _query,
      handle: _handle
    }), _parse = _qsaObserver2.parse;
    var _qsaObserver3 = qsaObserver({
      query: shadowed,
      handle: function handle2(element, connected) {
        if (shadowRoots.has(element)) {
          if (connected)
            shadows.add(element);
          else
            shadows["delete"](element);
          if (_query.length)
            _parseShadow.call(_query, element);
        }
      }
    }), parseShadowed = _qsaObserver3.parse;
    var attachShadow = Element.prototype.attachShadow;
    if (attachShadow)
      Element.prototype.attachShadow = function(init2) {
        var root = attachShadow.call(this, init2);
        shadowRoots.set(this, root);
        return root;
      };
    var _whenDefined2 = function _whenDefined22(name) {
      if (!_defined.has(name)) {
        var _, $ = new Promise(function($2) {
          _ = $2;
        });
        _defined.set(name, {
          $,
          _
        });
      }
      return _defined.get(name).$;
    };
    var _augment = attributesObserver(_whenDefined2, MutationObserver$1);
    var _override = null;
    getOwnPropertyNames(self).filter(function(k) {
      return /^HTML.*Element$/.test(k);
    }).forEach(function(k) {
      var HTMLElement3 = self[k];
      function HTMLBuiltIn2() {
        var constructor = this.constructor;
        if (!_classes.has(constructor))
          throw new TypeError$1("Illegal constructor");
        var _classes$get = _classes.get(constructor), is2 = _classes$get.is, tag = _classes$get.tag;
        if (is2) {
          if (_override)
            return _augment(_override, is2);
          var element = _createElement.call(document$1, tag);
          element.setAttribute("is", is2);
          return _augment(setPrototypeOf(element, constructor.prototype), is2);
        } else
          return construct.call(this, HTMLElement3, [], constructor);
      }
      defineProperty(HTMLBuiltIn2.prototype = HTMLElement3.prototype, "constructor", {
        value: HTMLBuiltIn2
      });
      defineProperty(self, k, {
        value: HTMLBuiltIn2
      });
    });
    document$1.createElement = function(name, options) {
      var is2 = options && options.is;
      if (is2) {
        var Class = _registry.get(is2);
        if (Class && _classes.get(Class).tag === name)
          return new Class();
      }
      var element = _createElement.call(document$1, name);
      if (is2)
        element.setAttribute("is", is2);
      return element;
    };
    customElements.get = getCE;
    customElements.whenDefined = _whenDefined2;
    customElements.upgrade = function(element) {
      var is2 = element.getAttribute("is");
      if (is2) {
        var _constructor = _registry.get(is2);
        if (_constructor) {
          _augment(setPrototypeOf(element, _constructor.prototype), is2);
          return;
        }
      }
      upgrade.call(customElements, element);
    };
    customElements.define = function(is2, Class, options) {
      if (getCE(is2))
        throw new Error2("'".concat(is2, "' has already been defined as a custom element"));
      var selector;
      var tag = options && options["extends"];
      _classes.set(Class, tag ? {
        is: is2,
        tag
      } : {
        is: "",
        tag: is2
      });
      if (tag) {
        selector = "".concat(tag, '[is="').concat(is2, '"]');
        _prototypes.set(selector, Class.prototype);
        _registry.set(is2, Class);
        _query.push(selector);
      } else {
        define.apply(customElements, arguments);
        shadowed.push(selector = is2);
      }
      _whenDefined2(is2).then(function() {
        if (tag) {
          _parse(document$1.querySelectorAll(selector));
          shadows.forEach(_parseShadow, [selector]);
        } else
          parseShadowed(document$1.querySelectorAll(selector));
      });
      _defined.get(is2)._(Class);
    };
  }
})();
/*! instant.page v5.2.0 - (C) 2019-2023 Alexandre Dieulot - https://instant.page/license */
let _chromiumMajorVersionInUserAgent = null, _allowQueryString, _allowExternalLinks, _useWhitelist, _delayOnHover = 65, _lastTouchTimestamp, _mouseoverTimer, _preloadedList = /* @__PURE__ */ new Set();
const DELAY_TO_NOT_BE_CONSIDERED_A_TOUCH_INITIATED_ACTION = 1111;
init$1();
function init$1() {
  const isSupported = document.createElement("link").relList.supports("prefetch");
  if (!isSupported) {
    return;
  }
  const handleVaryAcceptHeader = "instantVaryAccept" in document.body.dataset || "Shopify" in window;
  const chromiumUserAgentIndex = navigator.userAgent.indexOf("Chrome/");
  if (chromiumUserAgentIndex > -1) {
    _chromiumMajorVersionInUserAgent = parseInt(navigator.userAgent.substring(chromiumUserAgentIndex + "Chrome/".length));
  }
  if (handleVaryAcceptHeader && _chromiumMajorVersionInUserAgent && _chromiumMajorVersionInUserAgent < 110) {
    return;
  }
  const mousedownShortcut = "instantMousedownShortcut" in document.body.dataset;
  _allowQueryString = "instantAllowQueryString" in document.body.dataset;
  _allowExternalLinks = "instantAllowExternalLinks" in document.body.dataset;
  _useWhitelist = "instantWhitelist" in document.body.dataset;
  const eventListenersOptions = {
    capture: true,
    passive: true
  };
  let useMousedown = false;
  let useMousedownOnly = false;
  let useViewport = false;
  if ("instantIntensity" in document.body.dataset) {
    const intensity = document.body.dataset.instantIntensity;
    if (intensity.startsWith("mousedown")) {
      useMousedown = true;
      if (intensity == "mousedown-only") {
        useMousedownOnly = true;
      }
    } else if (intensity.startsWith("viewport")) {
      const isNavigatorConnectionSaveDataEnabled = navigator.connection && navigator.connection.saveData;
      const isNavigatorConnectionLike2g = navigator.connection && navigator.connection.effectiveType && navigator.connection.effectiveType.includes("2g");
      if (!isNavigatorConnectionSaveDataEnabled && !isNavigatorConnectionLike2g) {
        if (intensity == "viewport") {
          if (document.documentElement.clientWidth * document.documentElement.clientHeight < 45e4) {
            useViewport = true;
          }
        } else if (intensity == "viewport-all") {
          useViewport = true;
        }
      }
    } else {
      const milliseconds = parseInt(intensity);
      if (!isNaN(milliseconds)) {
        _delayOnHover = milliseconds;
      }
    }
  }
  if (!useMousedownOnly) {
    document.addEventListener("touchstart", touchstartListener, eventListenersOptions);
  }
  if (!useMousedown) {
    document.addEventListener("mouseover", mouseoverListener, eventListenersOptions);
  } else if (!mousedownShortcut) {
    document.addEventListener("mousedown", mousedownListener, eventListenersOptions);
  }
  if (mousedownShortcut) {
    document.addEventListener("mousedown", mousedownShortcutListener, eventListenersOptions);
  }
  if (useViewport) {
    let requestIdleCallbackOrFallback = window.requestIdleCallback;
    if (!requestIdleCallbackOrFallback) {
      requestIdleCallbackOrFallback = (callback) => {
        callback();
      };
    }
    requestIdleCallbackOrFallback(function observeIntersection() {
      const intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const anchorElement = entry.target;
            intersectionObserver.unobserve(anchorElement);
            preload$1(anchorElement.href);
          }
        });
      });
      document.querySelectorAll("a").forEach((anchorElement) => {
        if (isPreloadable(anchorElement)) {
          intersectionObserver.observe(anchorElement);
        }
      });
    }, {
      timeout: 1500
    });
  }
}
function touchstartListener(event) {
  _lastTouchTimestamp = performance.now();
  const anchorElement = event.target.closest("a");
  if (!isPreloadable(anchorElement)) {
    return;
  }
  preload$1(anchorElement.href, "high");
}
function mouseoverListener(event) {
  if (performance.now() - _lastTouchTimestamp < DELAY_TO_NOT_BE_CONSIDERED_A_TOUCH_INITIATED_ACTION) {
    return;
  }
  if (!("closest" in event.target)) {
    return;
  }
  const anchorElement = event.target.closest("a");
  if (!isPreloadable(anchorElement)) {
    return;
  }
  anchorElement.addEventListener("mouseout", mouseoutListener, { passive: true });
  _mouseoverTimer = setTimeout(() => {
    preload$1(anchorElement.href, "high");
    _mouseoverTimer = void 0;
  }, _delayOnHover);
}
function mousedownListener(event) {
  const anchorElement = event.target.closest("a");
  if (!isPreloadable(anchorElement)) {
    return;
  }
  preload$1(anchorElement.href, "high");
}
function mouseoutListener(event) {
  if (event.relatedTarget && event.target.closest("a") == event.relatedTarget.closest("a")) {
    return;
  }
  if (_mouseoverTimer) {
    clearTimeout(_mouseoverTimer);
    _mouseoverTimer = void 0;
  }
}
function mousedownShortcutListener(event) {
  if (performance.now() - _lastTouchTimestamp < DELAY_TO_NOT_BE_CONSIDERED_A_TOUCH_INITIATED_ACTION) {
    return;
  }
  const anchorElement = event.target.closest("a");
  if (event.which > 1 || event.metaKey || event.ctrlKey) {
    return;
  }
  if (!anchorElement) {
    return;
  }
  anchorElement.addEventListener("click", function(event2) {
    if (event2.detail == 1337) {
      return;
    }
    event2.preventDefault();
  }, { capture: true, passive: false, once: true });
  const customEvent = new MouseEvent("click", { view: window, bubbles: true, cancelable: false, detail: 1337 });
  anchorElement.dispatchEvent(customEvent);
}
function isPreloadable(anchorElement) {
  if (!anchorElement || !anchorElement.href) {
    return;
  }
  if (_useWhitelist && !("instant" in anchorElement.dataset)) {
    return;
  }
  if (anchorElement.origin != location.origin) {
    let allowed = _allowExternalLinks || "instant" in anchorElement.dataset;
    if (!allowed || !_chromiumMajorVersionInUserAgent) {
      return;
    }
  }
  if (!["http:", "https:"].includes(anchorElement.protocol)) {
    return;
  }
  if (anchorElement.protocol == "http:" && location.protocol == "https:") {
    return;
  }
  if (!_allowQueryString && anchorElement.search && !("instant" in anchorElement.dataset)) {
    return;
  }
  if (anchorElement.hash && anchorElement.pathname + anchorElement.search == location.pathname + location.search) {
    return;
  }
  if ("noInstant" in anchorElement.dataset) {
    return;
  }
  return true;
}
function preload$1(url, fetchPriority = "auto") {
  if (_preloadedList.has(url)) {
    return;
  }
  const linkElement = document.createElement("link");
  linkElement.rel = "prefetch";
  linkElement.href = url;
  linkElement.fetchPriority = fetchPriority;
  linkElement.as = "document";
  document.head.appendChild(linkElement);
  _preloadedList.add(url);
}
(function(doc, win) {
  if (typeof doc.createEvent !== "function")
    return false;
  var pointerEvent = function(type) {
    var lo = type.toLowerCase(), ms = "MS" + type;
    return navigator.msPointerEnabled ? ms : window.PointerEvent ? lo : false;
  }, touchEvent = function(name) {
    return "on" + name in window ? name : false;
  }, defaults2 = {
    useJquery: !win.IGNORE_JQUERY && typeof jQuery !== "undefined",
    swipeThreshold: win.SWIPE_THRESHOLD || 100,
    tapThreshold: win.TAP_THRESHOLD || 150,
    // range of time where a tap event could be detected
    dbltapThreshold: win.DBL_TAP_THRESHOLD || 200,
    // delay needed to detect a double tap
    longtapThreshold: win.LONG_TAP_THRESHOLD || 1e3,
    // delay needed to detect a long tap
    tapPrecision: win.TAP_PRECISION / 2 || 60 / 2,
    // touch events boundaries ( 60px by default )
    justTouchEvents: win.JUST_ON_TOUCH_DEVICES
  }, wasTouch = false, touchevents = {
    touchstart: touchEvent("touchstart") || pointerEvent("PointerDown"),
    touchend: touchEvent("touchend") || pointerEvent("PointerUp"),
    touchmove: touchEvent("touchmove") || pointerEvent("PointerMove")
  }, isTheSameFingerId = function(e) {
    return !e.pointerId || typeof pointerId === "undefined" || e.pointerId === pointerId;
  }, setListener = function(elm, events2, callback) {
    var eventsArray = events2.split(" "), i = eventsArray.length;
    while (i--) {
      elm.addEventListener(eventsArray[i], callback, false);
    }
  }, getPointerEvent = function(event) {
    var hasTargetTouches = Boolean(event.targetTouches && event.targetTouches.length);
    switch (true) {
      case Boolean(event.target.touches):
        return event.target.touches[0];
      case (hasTargetTouches && typeof event.targetTouches[0].pageX !== "undefined"):
        return event.targetTouches[0];
      case (hasTargetTouches && Boolean(event.targetTouches[0].touches)):
        return event.targetTouches[0].touches[0];
      default:
        return event;
    }
  }, isMultipleTouches = function(event) {
    return (event.targetTouches || event.target.touches || []).length > 1;
  }, getTimestamp = function() {
    return (/* @__PURE__ */ new Date()).getTime();
  }, sendEvent = function(elm, eventName, originalEvent, data) {
    var customEvent = doc.createEvent("Event");
    customEvent.originalEvent = originalEvent;
    data = data || {};
    data.x = currX;
    data.y = currY;
    if (defaults2.useJquery) {
      customEvent = jQuery.Event(eventName, { originalEvent });
      jQuery(elm).trigger(customEvent, data);
    }
    if (customEvent.initEvent) {
      for (var key in data) {
        customEvent[key] = data[key];
      }
      customEvent.initEvent(eventName, true, true);
      elm.dispatchEvent(customEvent);
    }
    while (elm) {
      if (elm["on" + eventName])
        elm["on" + eventName](customEvent);
      elm = elm.parentNode;
    }
  }, onTouchStart2 = function(e) {
    if (!isTheSameFingerId(e) || isMultipleTouches(e))
      return;
    pointerId = e.pointerId;
    if (e.type !== "mousedown")
      wasTouch = true;
    if (e.type === "mousedown" && wasTouch)
      return;
    var pointer = getPointerEvent(e);
    cachedX = currX = pointer.pageX;
    cachedY = currY = pointer.pageY;
    longtapTimer = setTimeout(function() {
      sendEvent(e.target, "longtap", e);
      target = e.target;
    }, defaults2.longtapThreshold);
    timestamp = getTimestamp();
    tapNum++;
  }, onTouchEnd2 = function(e) {
    if (!isTheSameFingerId(e) || isMultipleTouches(e))
      return;
    pointerId = void 0;
    if (e.type === "mouseup" && wasTouch) {
      wasTouch = false;
      return;
    }
    var eventsArr = [], now2 = getTimestamp(), deltaY = cachedY - currY, deltaX = cachedX - currX;
    clearTimeout(dblTapTimer);
    clearTimeout(longtapTimer);
    if (deltaX <= -defaults2.swipeThreshold)
      eventsArr.push("swiperight");
    if (deltaX >= defaults2.swipeThreshold)
      eventsArr.push("swipeleft");
    if (deltaY <= -defaults2.swipeThreshold)
      eventsArr.push("swipedown");
    if (deltaY >= defaults2.swipeThreshold)
      eventsArr.push("swipeup");
    if (eventsArr.length) {
      for (var i = 0; i < eventsArr.length; i++) {
        var eventName = eventsArr[i];
        sendEvent(e.target, eventName, e, {
          distance: {
            x: Math.abs(deltaX),
            y: Math.abs(deltaY)
          }
        });
      }
      tapNum = 0;
    } else {
      if (cachedX >= currX - defaults2.tapPrecision && cachedX <= currX + defaults2.tapPrecision && cachedY >= currY - defaults2.tapPrecision && cachedY <= currY + defaults2.tapPrecision) {
        if (timestamp + defaults2.tapThreshold - now2 >= 0) {
          sendEvent(e.target, tapNum >= 2 && target === e.target ? "dbltap" : "tap", e);
          target = e.target;
        }
      }
      dblTapTimer = setTimeout(function() {
        tapNum = 0;
      }, defaults2.dbltapThreshold);
    }
  }, onTouchMove2 = function(e) {
    if (!isTheSameFingerId(e))
      return;
    if (e.type === "mousemove" && wasTouch)
      return;
    var pointer = getPointerEvent(e);
    currX = pointer.pageX;
    currY = pointer.pageY;
  }, tapNum = 0, pointerId, currX, currY, cachedX, cachedY, timestamp, target, dblTapTimer, longtapTimer;
  setListener(doc, touchevents.touchstart + (defaults2.justTouchEvents ? "" : " mousedown"), onTouchStart2);
  setListener(doc, touchevents.touchend + (defaults2.justTouchEvents ? "" : " mouseup"), onTouchEnd2);
  setListener(doc, touchevents.touchmove + (defaults2.justTouchEvents ? "" : " mousemove"), onTouchMove2);
  win.tocca = function(options) {
    for (var opt in options) {
      defaults2[opt] = options[opt];
    }
    return defaults2;
  };
})(document, window);
!function() {
  var a = {}, b = {};
  !function(a2, b2) {
    function c(a3) {
      if ("number" == typeof a3)
        return a3;
      var b3 = {};
      for (var c2 in a3)
        b3[c2] = a3[c2];
      return b3;
    }
    function d() {
      this._delay = 0, this._endDelay = 0, this._fill = "none", this._iterationStart = 0, this._iterations = 1, this._duration = 0, this._playbackRate = 1, this._direction = "normal", this._easing = "linear", this._easingFunction = x;
    }
    function e() {
      return a2.isDeprecated("Invalid timing inputs", "2016-03-02", "TypeError exceptions will be thrown instead.", true);
    }
    function f(b3, c2, e2) {
      var f2 = new d();
      return c2 && (f2.fill = "both", f2.duration = "auto"), "number" != typeof b3 || isNaN(b3) ? void 0 !== b3 && Object.getOwnPropertyNames(b3).forEach(function(c3) {
        if ("auto" != b3[c3]) {
          if (("number" == typeof f2[c3] || "duration" == c3) && ("number" != typeof b3[c3] || isNaN(b3[c3])))
            return;
          if ("fill" == c3 && -1 == v.indexOf(b3[c3]))
            return;
          if ("direction" == c3 && -1 == w.indexOf(b3[c3]))
            return;
          if ("playbackRate" == c3 && 1 !== b3[c3] && a2.isDeprecated("AnimationEffectTiming.playbackRate", "2014-11-28", "Use Animation.playbackRate instead."))
            return;
          f2[c3] = b3[c3];
        }
      }) : f2.duration = b3, f2;
    }
    function g(a3) {
      return "number" == typeof a3 && (a3 = isNaN(a3) ? { duration: 0 } : { duration: a3 }), a3;
    }
    function h(b3, c2) {
      return b3 = a2.numericTimingToObject(b3), f(b3, c2);
    }
    function i(a3, b3, c2, d2) {
      return a3 < 0 || a3 > 1 || c2 < 0 || c2 > 1 ? x : function(e2) {
        function f2(a4, b4, c3) {
          return 3 * a4 * (1 - c3) * (1 - c3) * c3 + 3 * b4 * (1 - c3) * c3 * c3 + c3 * c3 * c3;
        }
        if (e2 <= 0) {
          var g2 = 0;
          return a3 > 0 ? g2 = b3 / a3 : !b3 && c2 > 0 && (g2 = d2 / c2), g2 * e2;
        }
        if (e2 >= 1) {
          var h2 = 0;
          return c2 < 1 ? h2 = (d2 - 1) / (c2 - 1) : 1 == c2 && a3 < 1 && (h2 = (b3 - 1) / (a3 - 1)), 1 + h2 * (e2 - 1);
        }
        for (var i2 = 0, j2 = 1; i2 < j2; ) {
          var k2 = (i2 + j2) / 2, l2 = f2(a3, c2, k2);
          if (Math.abs(e2 - l2) < 1e-5)
            return f2(b3, d2, k2);
          l2 < e2 ? i2 = k2 : j2 = k2;
        }
        return f2(b3, d2, k2);
      };
    }
    function j(a3, b3) {
      return function(c2) {
        if (c2 >= 1)
          return 1;
        var d2 = 1 / a3;
        return (c2 += b3 * d2) - c2 % d2;
      };
    }
    function k(a3) {
      C || (C = document.createElement("div").style), C.animationTimingFunction = "", C.animationTimingFunction = a3;
      var b3 = C.animationTimingFunction;
      if ("" == b3 && e())
        throw new TypeError(a3 + " is not a valid value for easing");
      return b3;
    }
    function l(a3) {
      if ("linear" == a3)
        return x;
      var b3 = E.exec(a3);
      if (b3)
        return i.apply(this, b3.slice(1).map(Number));
      var c2 = F.exec(a3);
      if (c2)
        return j(Number(c2[1]), A);
      var d2 = G.exec(a3);
      return d2 ? j(Number(d2[1]), { start: y, middle: z, end: A }[d2[2]]) : B[a3] || x;
    }
    function m(a3) {
      return Math.abs(n(a3) / a3.playbackRate);
    }
    function n(a3) {
      return 0 === a3.duration || 0 === a3.iterations ? 0 : a3.duration * a3.iterations;
    }
    function o(a3, b3, c2) {
      if (null == b3)
        return H;
      var d2 = c2.delay + a3 + c2.endDelay;
      return b3 < Math.min(c2.delay, d2) ? I : b3 >= Math.min(c2.delay + a3, d2) ? J : K;
    }
    function p(a3, b3, c2, d2, e2) {
      switch (d2) {
        case I:
          return "backwards" == b3 || "both" == b3 ? 0 : null;
        case K:
          return c2 - e2;
        case J:
          return "forwards" == b3 || "both" == b3 ? a3 : null;
        case H:
          return null;
      }
    }
    function q(a3, b3, c2, d2, e2) {
      var f2 = e2;
      return 0 === a3 ? b3 !== I && (f2 += c2) : f2 += d2 / a3, f2;
    }
    function r(a3, b3, c2, d2, e2, f2) {
      var g2 = a3 === 1 / 0 ? b3 % 1 : a3 % 1;
      return 0 !== g2 || c2 !== J || 0 === d2 || 0 === e2 && 0 !== f2 || (g2 = 1), g2;
    }
    function s(a3, b3, c2, d2) {
      return a3 === J && b3 === 1 / 0 ? 1 / 0 : 1 === c2 ? Math.floor(d2) - 1 : Math.floor(d2);
    }
    function t(a3, b3, c2) {
      var d2 = a3;
      if ("normal" !== a3 && "reverse" !== a3) {
        var e2 = b3;
        "alternate-reverse" === a3 && (e2 += 1), d2 = "normal", e2 !== 1 / 0 && e2 % 2 != 0 && (d2 = "reverse");
      }
      return "normal" === d2 ? c2 : 1 - c2;
    }
    function u(a3, b3, c2) {
      var d2 = o(a3, b3, c2), e2 = p(a3, c2.fill, b3, d2, c2.delay);
      if (null === e2)
        return null;
      var f2 = q(c2.duration, d2, c2.iterations, e2, c2.iterationStart), g2 = r(f2, c2.iterationStart, d2, c2.iterations, e2, c2.duration), h2 = s(d2, c2.iterations, g2, f2), i2 = t(c2.direction, h2, g2);
      return c2._easingFunction(i2);
    }
    var v = "backwards|forwards|both|none".split("|"), w = "reverse|alternate|alternate-reverse".split("|"), x = function(a3) {
      return a3;
    };
    d.prototype = { _setMember: function(b3, c2) {
      this["_" + b3] = c2, this._effect && (this._effect._timingInput[b3] = c2, this._effect._timing = a2.normalizeTimingInput(this._effect._timingInput), this._effect.activeDuration = a2.calculateActiveDuration(this._effect._timing), this._effect._animation && this._effect._animation._rebuildUnderlyingAnimation());
    }, get playbackRate() {
      return this._playbackRate;
    }, set delay(a3) {
      this._setMember("delay", a3);
    }, get delay() {
      return this._delay;
    }, set endDelay(a3) {
      this._setMember("endDelay", a3);
    }, get endDelay() {
      return this._endDelay;
    }, set fill(a3) {
      this._setMember("fill", a3);
    }, get fill() {
      return this._fill;
    }, set iterationStart(a3) {
      if ((isNaN(a3) || a3 < 0) && e())
        throw new TypeError("iterationStart must be a non-negative number, received: " + a3);
      this._setMember("iterationStart", a3);
    }, get iterationStart() {
      return this._iterationStart;
    }, set duration(a3) {
      if ("auto" != a3 && (isNaN(a3) || a3 < 0) && e())
        throw new TypeError("duration must be non-negative or auto, received: " + a3);
      this._setMember("duration", a3);
    }, get duration() {
      return this._duration;
    }, set direction(a3) {
      this._setMember("direction", a3);
    }, get direction() {
      return this._direction;
    }, set easing(a3) {
      this._easingFunction = l(k(a3)), this._setMember("easing", a3);
    }, get easing() {
      return this._easing;
    }, set iterations(a3) {
      if ((isNaN(a3) || a3 < 0) && e())
        throw new TypeError("iterations must be non-negative, received: " + a3);
      this._setMember("iterations", a3);
    }, get iterations() {
      return this._iterations;
    } };
    var y = 1, z = 0.5, A = 0, B = { ease: i(0.25, 0.1, 0.25, 1), "ease-in": i(0.42, 0, 1, 1), "ease-out": i(0, 0, 0.58, 1), "ease-in-out": i(0.42, 0, 0.58, 1), "step-start": j(1, y), "step-middle": j(1, z), "step-end": j(1, A) }, C = null, D = "\\s*(-?\\d+\\.?\\d*|-?\\.\\d+)\\s*", E = new RegExp("cubic-bezier\\(" + D + "," + D + "," + D + "," + D + "\\)"), F = /steps\(\s*(\d+)\s*\)/, G = /steps\(\s*(\d+)\s*,\s*(start|middle|end)\s*\)/, H = 0, I = 1, J = 2, K = 3;
    a2.cloneTimingInput = c, a2.makeTiming = f, a2.numericTimingToObject = g, a2.normalizeTimingInput = h, a2.calculateActiveDuration = m, a2.calculateIterationProgress = u, a2.calculatePhase = o, a2.normalizeEasing = k, a2.parseEasingFunction = l;
  }(a), function(a2, b2) {
    function c(a3, b3) {
      return a3 in k ? k[a3][b3] || b3 : b3;
    }
    function d(a3) {
      return "display" === a3 || 0 === a3.lastIndexOf("animation", 0) || 0 === a3.lastIndexOf("transition", 0);
    }
    function e(a3, b3, e2) {
      if (!d(a3)) {
        var f2 = h[a3];
        if (f2) {
          i.style[a3] = b3;
          for (var g2 in f2) {
            var j2 = f2[g2], k2 = i.style[j2];
            e2[j2] = c(j2, k2);
          }
        } else
          e2[a3] = c(a3, b3);
      }
    }
    function f(a3) {
      var b3 = [];
      for (var c2 in a3)
        if (!(c2 in ["easing", "offset", "composite"])) {
          var d2 = a3[c2];
          Array.isArray(d2) || (d2 = [d2]);
          for (var e2, f2 = d2.length, g2 = 0; g2 < f2; g2++)
            e2 = {}, e2.offset = "offset" in a3 ? a3.offset : 1 == f2 ? 1 : g2 / (f2 - 1), "easing" in a3 && (e2.easing = a3.easing), "composite" in a3 && (e2.composite = a3.composite), e2[c2] = d2[g2], b3.push(e2);
        }
      return b3.sort(function(a4, b4) {
        return a4.offset - b4.offset;
      }), b3;
    }
    function g(b3) {
      function c2() {
        var a3 = d2.length;
        null == d2[a3 - 1].offset && (d2[a3 - 1].offset = 1), a3 > 1 && null == d2[0].offset && (d2[0].offset = 0);
        for (var b4 = 0, c3 = d2[0].offset, e2 = 1; e2 < a3; e2++) {
          var f2 = d2[e2].offset;
          if (null != f2) {
            for (var g3 = 1; g3 < e2 - b4; g3++)
              d2[b4 + g3].offset = c3 + (f2 - c3) * g3 / (e2 - b4);
            b4 = e2, c3 = f2;
          }
        }
      }
      if (null == b3)
        return [];
      window.Symbol && Symbol.iterator && Array.prototype.from && b3[Symbol.iterator] && (b3 = Array.from(b3)), Array.isArray(b3) || (b3 = f(b3));
      for (var d2 = b3.map(function(b4) {
        var c3 = {};
        for (var d3 in b4) {
          var f2 = b4[d3];
          if ("offset" == d3) {
            if (null != f2) {
              if (f2 = Number(f2), !isFinite(f2))
                throw new TypeError("Keyframe offsets must be numbers.");
              if (f2 < 0 || f2 > 1)
                throw new TypeError("Keyframe offsets must be between 0 and 1.");
            }
          } else if ("composite" == d3) {
            if ("add" == f2 || "accumulate" == f2)
              throw { type: DOMException.NOT_SUPPORTED_ERR, name: "NotSupportedError", message: "add compositing is not supported" };
            if ("replace" != f2)
              throw new TypeError("Invalid composite mode " + f2 + ".");
          } else
            f2 = "easing" == d3 ? a2.normalizeEasing(f2) : "" + f2;
          e(d3, f2, c3);
        }
        return void 0 == c3.offset && (c3.offset = null), void 0 == c3.easing && (c3.easing = "linear"), c3;
      }), g2 = true, h2 = -1 / 0, i2 = 0; i2 < d2.length; i2++) {
        var j2 = d2[i2].offset;
        if (null != j2) {
          if (j2 < h2)
            throw new TypeError("Keyframes are not loosely sorted by offset. Sort or specify offsets.");
          h2 = j2;
        } else
          g2 = false;
      }
      return d2 = d2.filter(function(a3) {
        return a3.offset >= 0 && a3.offset <= 1;
      }), g2 || c2(), d2;
    }
    var h = { background: ["backgroundImage", "backgroundPosition", "backgroundSize", "backgroundRepeat", "backgroundAttachment", "backgroundOrigin", "backgroundClip", "backgroundColor"], border: ["borderTopColor", "borderTopStyle", "borderTopWidth", "borderRightColor", "borderRightStyle", "borderRightWidth", "borderBottomColor", "borderBottomStyle", "borderBottomWidth", "borderLeftColor", "borderLeftStyle", "borderLeftWidth"], borderBottom: ["borderBottomWidth", "borderBottomStyle", "borderBottomColor"], borderColor: ["borderTopColor", "borderRightColor", "borderBottomColor", "borderLeftColor"], borderLeft: ["borderLeftWidth", "borderLeftStyle", "borderLeftColor"], borderRadius: ["borderTopLeftRadius", "borderTopRightRadius", "borderBottomRightRadius", "borderBottomLeftRadius"], borderRight: ["borderRightWidth", "borderRightStyle", "borderRightColor"], borderTop: ["borderTopWidth", "borderTopStyle", "borderTopColor"], borderWidth: ["borderTopWidth", "borderRightWidth", "borderBottomWidth", "borderLeftWidth"], flex: ["flexGrow", "flexShrink", "flexBasis"], font: ["fontFamily", "fontSize", "fontStyle", "fontVariant", "fontWeight", "lineHeight"], margin: ["marginTop", "marginRight", "marginBottom", "marginLeft"], outline: ["outlineColor", "outlineStyle", "outlineWidth"], padding: ["paddingTop", "paddingRight", "paddingBottom", "paddingLeft"] }, i = document.createElementNS("http://www.w3.org/1999/xhtml", "div"), j = { thin: "1px", medium: "3px", thick: "5px" }, k = { borderBottomWidth: j, borderLeftWidth: j, borderRightWidth: j, borderTopWidth: j, fontSize: { "xx-small": "60%", "x-small": "75%", small: "89%", medium: "100%", large: "120%", "x-large": "150%", "xx-large": "200%" }, fontWeight: { normal: "400", bold: "700" }, outlineWidth: j, textShadow: { none: "0px 0px 0px transparent" }, boxShadow: { none: "0px 0px 0px 0px transparent" } };
    a2.convertToArrayForm = f, a2.normalizeKeyframes = g;
  }(a), function(a2) {
    var b2 = {};
    a2.isDeprecated = function(a3, c, d, e) {
      var f = e ? "are" : "is", g = /* @__PURE__ */ new Date(), h = new Date(c);
      return h.setMonth(h.getMonth() + 3), !(g < h && (a3 in b2 || console.warn("Web Animations: " + a3 + " " + f + " deprecated and will stop working on " + h.toDateString() + ". " + d), b2[a3] = true, 1));
    }, a2.deprecated = function(b3, c, d, e) {
      var f = e ? "are" : "is";
      if (a2.isDeprecated(b3, c, d, e))
        throw new Error(b3 + " " + f + " no longer supported. " + d);
    };
  }(a), function() {
    if (document.documentElement.animate) {
      var c = document.documentElement.animate([], 0), d = true;
      if (c && (d = false, "play|currentTime|pause|reverse|playbackRate|cancel|finish|startTime|playState".split("|").forEach(function(a2) {
        void 0 === c[a2] && (d = true);
      })), !d)
        return;
    }
    !function(a2, b2, c2) {
      function d2(a3) {
        for (var b3 = {}, c3 = 0; c3 < a3.length; c3++)
          for (var d3 in a3[c3])
            if ("offset" != d3 && "easing" != d3 && "composite" != d3) {
              var e2 = { offset: a3[c3].offset, easing: a3[c3].easing, value: a3[c3][d3] };
              b3[d3] = b3[d3] || [], b3[d3].push(e2);
            }
        for (var f in b3) {
          var g = b3[f];
          if (0 != g[0].offset || 1 != g[g.length - 1].offset)
            throw { type: DOMException.NOT_SUPPORTED_ERR, name: "NotSupportedError", message: "Partial keyframes are not supported" };
        }
        return b3;
      }
      function e(c3) {
        var d3 = [];
        for (var e2 in c3)
          for (var f = c3[e2], g = 0; g < f.length - 1; g++) {
            var h = g, i = g + 1, j = f[h].offset, k = f[i].offset, l = j, m = k;
            0 == g && (l = -1 / 0, 0 == k && (i = h)), g == f.length - 2 && (m = 1 / 0, 1 == j && (h = i)), d3.push({ applyFrom: l, applyTo: m, startOffset: f[h].offset, endOffset: f[i].offset, easingFunction: a2.parseEasingFunction(f[h].easing), property: e2, interpolation: b2.propertyInterpolation(e2, f[h].value, f[i].value) });
          }
        return d3.sort(function(a3, b3) {
          return a3.startOffset - b3.startOffset;
        }), d3;
      }
      b2.convertEffectInput = function(c3) {
        var f = a2.normalizeKeyframes(c3), g = d2(f), h = e(g);
        return function(a3, c4) {
          if (null != c4)
            h.filter(function(a4) {
              return c4 >= a4.applyFrom && c4 < a4.applyTo;
            }).forEach(function(d4) {
              var e2 = c4 - d4.startOffset, f2 = d4.endOffset - d4.startOffset, g2 = 0 == f2 ? 0 : d4.easingFunction(e2 / f2);
              b2.apply(a3, d4.property, d4.interpolation(g2));
            });
          else
            for (var d3 in g)
              "offset" != d3 && "easing" != d3 && "composite" != d3 && b2.clear(a3, d3);
        };
      };
    }(a, b), function(a2, b2, c2) {
      function d2(a3) {
        return a3.replace(/-(.)/g, function(a4, b3) {
          return b3.toUpperCase();
        });
      }
      function e(a3, b3, c3) {
        h[c3] = h[c3] || [], h[c3].push([a3, b3]);
      }
      function f(a3, b3, c3) {
        for (var f2 = 0; f2 < c3.length; f2++) {
          e(a3, b3, d2(c3[f2]));
        }
      }
      function g(c3, e2, f2) {
        var g2 = c3;
        /-/.test(c3) && !a2.isDeprecated("Hyphenated property names", "2016-03-22", "Use camelCase instead.", true) && (g2 = d2(c3)), "initial" != e2 && "initial" != f2 || ("initial" == e2 && (e2 = i[g2]), "initial" == f2 && (f2 = i[g2]));
        for (var j = e2 == f2 ? [] : h[g2], k = 0; j && k < j.length; k++) {
          var l = j[k][0](e2), m = j[k][0](f2);
          if (void 0 !== l && void 0 !== m) {
            var n = j[k][1](l, m);
            if (n) {
              var o = b2.Interpolation.apply(null, n);
              return function(a3) {
                return 0 == a3 ? e2 : 1 == a3 ? f2 : o(a3);
              };
            }
          }
        }
        return b2.Interpolation(false, true, function(a3) {
          return a3 ? f2 : e2;
        });
      }
      var h = {};
      b2.addPropertiesHandler = f;
      var i = { backgroundColor: "transparent", backgroundPosition: "0% 0%", borderBottomColor: "currentColor", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", borderBottomWidth: "3px", borderLeftColor: "currentColor", borderLeftWidth: "3px", borderRightColor: "currentColor", borderRightWidth: "3px", borderSpacing: "2px", borderTopColor: "currentColor", borderTopLeftRadius: "0px", borderTopRightRadius: "0px", borderTopWidth: "3px", bottom: "auto", clip: "rect(0px, 0px, 0px, 0px)", color: "black", fontSize: "100%", fontWeight: "400", height: "auto", left: "auto", letterSpacing: "normal", lineHeight: "120%", marginBottom: "0px", marginLeft: "0px", marginRight: "0px", marginTop: "0px", maxHeight: "none", maxWidth: "none", minHeight: "0px", minWidth: "0px", opacity: "1.0", outlineColor: "invert", outlineOffset: "0px", outlineWidth: "3px", paddingBottom: "0px", paddingLeft: "0px", paddingRight: "0px", paddingTop: "0px", right: "auto", strokeDasharray: "none", strokeDashoffset: "0px", textIndent: "0px", textShadow: "0px 0px 0px transparent", top: "auto", transform: "", verticalAlign: "0px", visibility: "visible", width: "auto", wordSpacing: "normal", zIndex: "auto" };
      b2.propertyInterpolation = g;
    }(a, b), function(a2, b2, c2) {
      function d2(b3) {
        var c3 = a2.calculateActiveDuration(b3), d3 = function(d4) {
          return a2.calculateIterationProgress(c3, d4, b3);
        };
        return d3._totalDuration = b3.delay + c3 + b3.endDelay, d3;
      }
      b2.KeyframeEffect = function(c3, e, f, g) {
        var h, i = d2(a2.normalizeTimingInput(f)), j = b2.convertEffectInput(e), k = function() {
          j(c3, h);
        };
        return k._update = function(a3) {
          return null !== (h = i(a3));
        }, k._clear = function() {
          j(c3, null);
        }, k._hasSameTarget = function(a3) {
          return c3 === a3;
        }, k._target = c3, k._totalDuration = i._totalDuration, k._id = g, k;
      };
    }(a, b), function(a2, b2) {
      function c2(a3, b3) {
        return !(!b3.namespaceURI || -1 == b3.namespaceURI.indexOf("/svg")) && (g in a3 || (a3[g] = /Trident|MSIE|IEMobile|Edge|Android 4/i.test(a3.navigator.userAgent)), a3[g]);
      }
      function d2(a3, b3, c3) {
        c3.enumerable = true, c3.configurable = true, Object.defineProperty(a3, b3, c3);
      }
      function e(a3) {
        this._element = a3, this._surrogateStyle = document.createElementNS("http://www.w3.org/1999/xhtml", "div").style, this._style = a3.style, this._length = 0, this._isAnimatedProperty = {}, this._updateSvgTransformAttr = c2(window, a3), this._savedTransformAttr = null;
        for (var b3 = 0; b3 < this._style.length; b3++) {
          var d3 = this._style[b3];
          this._surrogateStyle[d3] = this._style[d3];
        }
        this._updateIndices();
      }
      function f(a3) {
        if (!a3._webAnimationsPatchedStyle) {
          var b3 = new e(a3);
          try {
            d2(a3, "style", { get: function() {
              return b3;
            } });
          } catch (b4) {
            a3.style._set = function(b5, c3) {
              a3.style[b5] = c3;
            }, a3.style._clear = function(b5) {
              a3.style[b5] = "";
            };
          }
          a3._webAnimationsPatchedStyle = a3.style;
        }
      }
      var g = "_webAnimationsUpdateSvgTransformAttr", h = { cssText: 1, length: 1, parentRule: 1 }, i = { getPropertyCSSValue: 1, getPropertyPriority: 1, getPropertyValue: 1, item: 1, removeProperty: 1, setProperty: 1 }, j = { removeProperty: 1, setProperty: 1 };
      e.prototype = { get cssText() {
        return this._surrogateStyle.cssText;
      }, set cssText(a3) {
        for (var b3 = {}, c3 = 0; c3 < this._surrogateStyle.length; c3++)
          b3[this._surrogateStyle[c3]] = true;
        this._surrogateStyle.cssText = a3, this._updateIndices();
        for (var c3 = 0; c3 < this._surrogateStyle.length; c3++)
          b3[this._surrogateStyle[c3]] = true;
        for (var d3 in b3)
          this._isAnimatedProperty[d3] || this._style.setProperty(d3, this._surrogateStyle.getPropertyValue(d3));
      }, get length() {
        return this._surrogateStyle.length;
      }, get parentRule() {
        return this._style.parentRule;
      }, _updateIndices: function() {
        for (; this._length < this._surrogateStyle.length; )
          Object.defineProperty(this, this._length, { configurable: true, enumerable: false, get: function(a3) {
            return function() {
              return this._surrogateStyle[a3];
            };
          }(this._length) }), this._length++;
        for (; this._length > this._surrogateStyle.length; )
          this._length--, Object.defineProperty(this, this._length, { configurable: true, enumerable: false, value: void 0 });
      }, _set: function(b3, c3) {
        this._style[b3] = c3, this._isAnimatedProperty[b3] = true, this._updateSvgTransformAttr && "transform" == a2.unprefixedPropertyName(b3) && (null == this._savedTransformAttr && (this._savedTransformAttr = this._element.getAttribute("transform")), this._element.setAttribute("transform", a2.transformToSvgMatrix(c3)));
      }, _clear: function(b3) {
        this._style[b3] = this._surrogateStyle[b3], this._updateSvgTransformAttr && "transform" == a2.unprefixedPropertyName(b3) && (this._savedTransformAttr ? this._element.setAttribute("transform", this._savedTransformAttr) : this._element.removeAttribute("transform"), this._savedTransformAttr = null), delete this._isAnimatedProperty[b3];
      } };
      for (var k in i)
        e.prototype[k] = function(a3, b3) {
          return function() {
            var c3 = this._surrogateStyle[a3].apply(this._surrogateStyle, arguments);
            return b3 && (this._isAnimatedProperty[arguments[0]] || this._style[a3].apply(this._style, arguments), this._updateIndices()), c3;
          };
        }(k, k in j);
      for (var l in document.documentElement.style)
        l in h || l in i || function(a3) {
          d2(e.prototype, a3, { get: function() {
            return this._surrogateStyle[a3];
          }, set: function(b3) {
            this._surrogateStyle[a3] = b3, this._updateIndices(), this._isAnimatedProperty[a3] || (this._style[a3] = b3);
          } });
        }(l);
      a2.apply = function(b3, c3, d3) {
        f(b3), b3.style._set(a2.propertyName(c3), d3);
      }, a2.clear = function(b3, c3) {
        b3._webAnimationsPatchedStyle && b3.style._clear(a2.propertyName(c3));
      };
    }(b), function(a2) {
      window.Element.prototype.animate = function(b2, c2) {
        var d2 = "";
        return c2 && c2.id && (d2 = c2.id), a2.timeline._play(a2.KeyframeEffect(this, b2, c2, d2));
      };
    }(b), function(a2, b2) {
      function c2(a3, b3, d2) {
        if ("number" == typeof a3 && "number" == typeof b3)
          return a3 * (1 - d2) + b3 * d2;
        if ("boolean" == typeof a3 && "boolean" == typeof b3)
          return d2 < 0.5 ? a3 : b3;
        if (a3.length == b3.length) {
          for (var e = [], f = 0; f < a3.length; f++)
            e.push(c2(a3[f], b3[f], d2));
          return e;
        }
        throw "Mismatched interpolation arguments " + a3 + ":" + b3;
      }
      a2.Interpolation = function(a3, b3, d2) {
        return function(e) {
          return d2(c2(a3, b3, e));
        };
      };
    }(b), function(a2, b2) {
      function c2(a3, b3, c3) {
        return Math.max(Math.min(a3, c3), b3);
      }
      function d2(b3, d3, e2) {
        var f = a2.dot(b3, d3);
        f = c2(f, -1, 1);
        var g = [];
        if (1 === f)
          g = b3;
        else
          for (var h = Math.acos(f), i = 1 * Math.sin(e2 * h) / Math.sqrt(1 - f * f), j = 0; j < 4; j++)
            g.push(b3[j] * (Math.cos(e2 * h) - f * i) + d3[j] * i);
        return g;
      }
      var e = function() {
        function a3(a4, b4) {
          for (var c4 = [[0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]], d3 = 0; d3 < 4; d3++)
            for (var e2 = 0; e2 < 4; e2++)
              for (var f = 0; f < 4; f++)
                c4[d3][e2] += b4[d3][f] * a4[f][e2];
          return c4;
        }
        function b3(a4) {
          return 0 == a4[0][2] && 0 == a4[0][3] && 0 == a4[1][2] && 0 == a4[1][3] && 0 == a4[2][0] && 0 == a4[2][1] && 1 == a4[2][2] && 0 == a4[2][3] && 0 == a4[3][2] && 1 == a4[3][3];
        }
        function c3(c4, d3, e2, f, g) {
          for (var h = [[1, 0, 0, 0], [0, 1, 0, 0], [0, 0, 1, 0], [0, 0, 0, 1]], i = 0; i < 4; i++)
            h[i][3] = g[i];
          for (var i = 0; i < 3; i++)
            for (var j = 0; j < 3; j++)
              h[3][i] += c4[j] * h[j][i];
          var k = f[0], l = f[1], m = f[2], n = f[3], o = [[1, 0, 0, 0], [0, 1, 0, 0], [0, 0, 1, 0], [0, 0, 0, 1]];
          o[0][0] = 1 - 2 * (l * l + m * m), o[0][1] = 2 * (k * l - m * n), o[0][2] = 2 * (k * m + l * n), o[1][0] = 2 * (k * l + m * n), o[1][1] = 1 - 2 * (k * k + m * m), o[1][2] = 2 * (l * m - k * n), o[2][0] = 2 * (k * m - l * n), o[2][1] = 2 * (l * m + k * n), o[2][2] = 1 - 2 * (k * k + l * l), h = a3(h, o);
          var p = [[1, 0, 0, 0], [0, 1, 0, 0], [0, 0, 1, 0], [0, 0, 0, 1]];
          e2[2] && (p[2][1] = e2[2], h = a3(h, p)), e2[1] && (p[2][1] = 0, p[2][0] = e2[0], h = a3(h, p)), e2[0] && (p[2][0] = 0, p[1][0] = e2[0], h = a3(h, p));
          for (var i = 0; i < 3; i++)
            for (var j = 0; j < 3; j++)
              h[i][j] *= d3[i];
          return b3(h) ? [h[0][0], h[0][1], h[1][0], h[1][1], h[3][0], h[3][1]] : h[0].concat(h[1], h[2], h[3]);
        }
        return c3;
      }();
      a2.composeMatrix = e, a2.quat = d2;
    }(b), function(a2, b2, c2) {
      a2.sequenceNumber = 0;
      var d2 = function(a3, b3, c3) {
        this.target = a3, this.currentTime = b3, this.timelineTime = c3, this.type = "finish", this.bubbles = false, this.cancelable = false, this.currentTarget = a3, this.defaultPrevented = false, this.eventPhase = Event.AT_TARGET, this.timeStamp = Date.now();
      };
      b2.Animation = function(b3) {
        this.id = "", b3 && b3._id && (this.id = b3._id), this._sequenceNumber = a2.sequenceNumber++, this._currentTime = 0, this._startTime = null, this._paused = false, this._playbackRate = 1, this._inTimeline = true, this._finishedFlag = true, this.onfinish = null, this._finishHandlers = [], this._effect = b3, this._inEffect = this._effect._update(0), this._idle = true, this._currentTimePending = false;
      }, b2.Animation.prototype = { _ensureAlive: function() {
        this.playbackRate < 0 && 0 === this.currentTime ? this._inEffect = this._effect._update(-1) : this._inEffect = this._effect._update(this.currentTime), this._inTimeline || !this._inEffect && this._finishedFlag || (this._inTimeline = true, b2.timeline._animations.push(this));
      }, _tickCurrentTime: function(a3, b3) {
        a3 != this._currentTime && (this._currentTime = a3, this._isFinished && !b3 && (this._currentTime = this._playbackRate > 0 ? this._totalDuration : 0), this._ensureAlive());
      }, get currentTime() {
        return this._idle || this._currentTimePending ? null : this._currentTime;
      }, set currentTime(a3) {
        a3 = +a3, isNaN(a3) || (b2.restart(), this._paused || null == this._startTime || (this._startTime = this._timeline.currentTime - a3 / this._playbackRate), this._currentTimePending = false, this._currentTime != a3 && (this._idle && (this._idle = false, this._paused = true), this._tickCurrentTime(a3, true), b2.applyDirtiedAnimation(this)));
      }, get startTime() {
        return this._startTime;
      }, set startTime(a3) {
        a3 = +a3, isNaN(a3) || this._paused || this._idle || (this._startTime = a3, this._tickCurrentTime((this._timeline.currentTime - this._startTime) * this.playbackRate), b2.applyDirtiedAnimation(this));
      }, get playbackRate() {
        return this._playbackRate;
      }, set playbackRate(a3) {
        if (a3 != this._playbackRate) {
          var c3 = this.currentTime;
          this._playbackRate = a3, this._startTime = null, "paused" != this.playState && "idle" != this.playState && (this._finishedFlag = false, this._idle = false, this._ensureAlive(), b2.applyDirtiedAnimation(this)), null != c3 && (this.currentTime = c3);
        }
      }, get _isFinished() {
        return !this._idle && (this._playbackRate > 0 && this._currentTime >= this._totalDuration || this._playbackRate < 0 && this._currentTime <= 0);
      }, get _totalDuration() {
        return this._effect._totalDuration;
      }, get playState() {
        return this._idle ? "idle" : null == this._startTime && !this._paused && 0 != this.playbackRate || this._currentTimePending ? "pending" : this._paused ? "paused" : this._isFinished ? "finished" : "running";
      }, _rewind: function() {
        if (this._playbackRate >= 0)
          this._currentTime = 0;
        else {
          if (!(this._totalDuration < 1 / 0))
            throw new DOMException("Unable to rewind negative playback rate animation with infinite duration", "InvalidStateError");
          this._currentTime = this._totalDuration;
        }
      }, play: function() {
        this._paused = false, (this._isFinished || this._idle) && (this._rewind(), this._startTime = null), this._finishedFlag = false, this._idle = false, this._ensureAlive(), b2.applyDirtiedAnimation(this);
      }, pause: function() {
        this._isFinished || this._paused || this._idle ? this._idle && (this._rewind(), this._idle = false) : this._currentTimePending = true, this._startTime = null, this._paused = true;
      }, finish: function() {
        this._idle || (this.currentTime = this._playbackRate > 0 ? this._totalDuration : 0, this._startTime = this._totalDuration - this.currentTime, this._currentTimePending = false, b2.applyDirtiedAnimation(this));
      }, cancel: function() {
        this._inEffect && (this._inEffect = false, this._idle = true, this._paused = false, this._finishedFlag = true, this._currentTime = 0, this._startTime = null, this._effect._update(null), b2.applyDirtiedAnimation(this));
      }, reverse: function() {
        this.playbackRate *= -1, this.play();
      }, addEventListener: function(a3, b3) {
        "function" == typeof b3 && "finish" == a3 && this._finishHandlers.push(b3);
      }, removeEventListener: function(a3, b3) {
        if ("finish" == a3) {
          var c3 = this._finishHandlers.indexOf(b3);
          c3 >= 0 && this._finishHandlers.splice(c3, 1);
        }
      }, _fireEvents: function(a3) {
        if (this._isFinished) {
          if (!this._finishedFlag) {
            var b3 = new d2(this, this._currentTime, a3), c3 = this._finishHandlers.concat(this.onfinish ? [this.onfinish] : []);
            setTimeout(function() {
              c3.forEach(function(a4) {
                a4.call(b3.target, b3);
              });
            }, 0), this._finishedFlag = true;
          }
        } else
          this._finishedFlag = false;
      }, _tick: function(a3, b3) {
        this._idle || this._paused || (null == this._startTime ? b3 && (this.startTime = a3 - this._currentTime / this.playbackRate) : this._isFinished || this._tickCurrentTime((a3 - this._startTime) * this.playbackRate)), b3 && (this._currentTimePending = false, this._fireEvents(a3));
      }, get _needsTick() {
        return this.playState in { pending: 1, running: 1 } || !this._finishedFlag;
      }, _targetAnimations: function() {
        var a3 = this._effect._target;
        return a3._activeAnimations || (a3._activeAnimations = []), a3._activeAnimations;
      }, _markTarget: function() {
        var a3 = this._targetAnimations();
        -1 === a3.indexOf(this) && a3.push(this);
      }, _unmarkTarget: function() {
        var a3 = this._targetAnimations(), b3 = a3.indexOf(this);
        -1 !== b3 && a3.splice(b3, 1);
      } };
    }(a, b), function(a2, b2, c2) {
      function d2(a3) {
        var b3 = j;
        j = [], a3 < q.currentTime && (a3 = q.currentTime), q._animations.sort(e), q._animations = h(a3, true, q._animations)[0], b3.forEach(function(b4) {
          b4[1](a3);
        }), g();
      }
      function e(a3, b3) {
        return a3._sequenceNumber - b3._sequenceNumber;
      }
      function f() {
        this._animations = [], this.currentTime = window.performance && performance.now ? performance.now() : 0;
      }
      function g() {
        o.forEach(function(a3) {
          a3();
        }), o.length = 0;
      }
      function h(a3, c3, d3) {
        p = true, n = false, b2.timeline.currentTime = a3, m = false;
        var e2 = [], f2 = [], g2 = [], h2 = [];
        return d3.forEach(function(b3) {
          b3._tick(a3, c3), b3._inEffect ? (f2.push(b3._effect), b3._markTarget()) : (e2.push(b3._effect), b3._unmarkTarget()), b3._needsTick && (m = true);
          var d4 = b3._inEffect || b3._needsTick;
          b3._inTimeline = d4, d4 ? g2.push(b3) : h2.push(b3);
        }), o.push.apply(o, e2), o.push.apply(o, f2), m && requestAnimationFrame(function() {
        }), p = false, [g2, h2];
      }
      var i = window.requestAnimationFrame, j = [], k = 0;
      window.requestAnimationFrame = function(a3) {
        var b3 = k++;
        return 0 == j.length && i(d2), j.push([b3, a3]), b3;
      }, window.cancelAnimationFrame = function(a3) {
        j.forEach(function(b3) {
          b3[0] == a3 && (b3[1] = function() {
          });
        });
      }, f.prototype = { _play: function(c3) {
        c3._timing = a2.normalizeTimingInput(c3.timing);
        var d3 = new b2.Animation(c3);
        return d3._idle = false, d3._timeline = this, this._animations.push(d3), b2.restart(), b2.applyDirtiedAnimation(d3), d3;
      } };
      var m = false, n = false;
      b2.restart = function() {
        return m || (m = true, requestAnimationFrame(function() {
        }), n = true), n;
      }, b2.applyDirtiedAnimation = function(a3) {
        if (!p) {
          a3._markTarget();
          var c3 = a3._targetAnimations();
          c3.sort(e), h(b2.timeline.currentTime, false, c3.slice())[1].forEach(function(a4) {
            var b3 = q._animations.indexOf(a4);
            -1 !== b3 && q._animations.splice(b3, 1);
          }), g();
        }
      };
      var o = [], p = false, q = new f();
      b2.timeline = q;
    }(a, b), function(a2, b2) {
      function c2(a3, b3) {
        for (var c3 = 0, d3 = 0; d3 < a3.length; d3++)
          c3 += a3[d3] * b3[d3];
        return c3;
      }
      function d2(a3, b3) {
        return [a3[0] * b3[0] + a3[4] * b3[1] + a3[8] * b3[2] + a3[12] * b3[3], a3[1] * b3[0] + a3[5] * b3[1] + a3[9] * b3[2] + a3[13] * b3[3], a3[2] * b3[0] + a3[6] * b3[1] + a3[10] * b3[2] + a3[14] * b3[3], a3[3] * b3[0] + a3[7] * b3[1] + a3[11] * b3[2] + a3[15] * b3[3], a3[0] * b3[4] + a3[4] * b3[5] + a3[8] * b3[6] + a3[12] * b3[7], a3[1] * b3[4] + a3[5] * b3[5] + a3[9] * b3[6] + a3[13] * b3[7], a3[2] * b3[4] + a3[6] * b3[5] + a3[10] * b3[6] + a3[14] * b3[7], a3[3] * b3[4] + a3[7] * b3[5] + a3[11] * b3[6] + a3[15] * b3[7], a3[0] * b3[8] + a3[4] * b3[9] + a3[8] * b3[10] + a3[12] * b3[11], a3[1] * b3[8] + a3[5] * b3[9] + a3[9] * b3[10] + a3[13] * b3[11], a3[2] * b3[8] + a3[6] * b3[9] + a3[10] * b3[10] + a3[14] * b3[11], a3[3] * b3[8] + a3[7] * b3[9] + a3[11] * b3[10] + a3[15] * b3[11], a3[0] * b3[12] + a3[4] * b3[13] + a3[8] * b3[14] + a3[12] * b3[15], a3[1] * b3[12] + a3[5] * b3[13] + a3[9] * b3[14] + a3[13] * b3[15], a3[2] * b3[12] + a3[6] * b3[13] + a3[10] * b3[14] + a3[14] * b3[15], a3[3] * b3[12] + a3[7] * b3[13] + a3[11] * b3[14] + a3[15] * b3[15]];
      }
      function e(a3) {
        var b3 = a3.rad || 0;
        return ((a3.deg || 0) / 360 + (a3.grad || 0) / 400 + (a3.turn || 0)) * (2 * Math.PI) + b3;
      }
      function f(a3) {
        switch (a3.t) {
          case "rotatex":
            var b3 = e(a3.d[0]);
            return [1, 0, 0, 0, 0, Math.cos(b3), Math.sin(b3), 0, 0, -Math.sin(b3), Math.cos(b3), 0, 0, 0, 0, 1];
          case "rotatey":
            var b3 = e(a3.d[0]);
            return [Math.cos(b3), 0, -Math.sin(b3), 0, 0, 1, 0, 0, Math.sin(b3), 0, Math.cos(b3), 0, 0, 0, 0, 1];
          case "rotate":
          case "rotatez":
            var b3 = e(a3.d[0]);
            return [Math.cos(b3), Math.sin(b3), 0, 0, -Math.sin(b3), Math.cos(b3), 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
          case "rotate3d":
            var c3 = a3.d[0], d3 = a3.d[1], f2 = a3.d[2], b3 = e(a3.d[3]), g2 = c3 * c3 + d3 * d3 + f2 * f2;
            if (0 === g2)
              c3 = 1, d3 = 0, f2 = 0;
            else if (1 !== g2) {
              var h2 = Math.sqrt(g2);
              c3 /= h2, d3 /= h2, f2 /= h2;
            }
            var i2 = Math.sin(b3 / 2), j = i2 * Math.cos(b3 / 2), k = i2 * i2;
            return [1 - 2 * (d3 * d3 + f2 * f2) * k, 2 * (c3 * d3 * k + f2 * j), 2 * (c3 * f2 * k - d3 * j), 0, 2 * (c3 * d3 * k - f2 * j), 1 - 2 * (c3 * c3 + f2 * f2) * k, 2 * (d3 * f2 * k + c3 * j), 0, 2 * (c3 * f2 * k + d3 * j), 2 * (d3 * f2 * k - c3 * j), 1 - 2 * (c3 * c3 + d3 * d3) * k, 0, 0, 0, 0, 1];
          case "scale":
            return [a3.d[0], 0, 0, 0, 0, a3.d[1], 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
          case "scalex":
            return [a3.d[0], 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
          case "scaley":
            return [1, 0, 0, 0, 0, a3.d[0], 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
          case "scalez":
            return [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, a3.d[0], 0, 0, 0, 0, 1];
          case "scale3d":
            return [a3.d[0], 0, 0, 0, 0, a3.d[1], 0, 0, 0, 0, a3.d[2], 0, 0, 0, 0, 1];
          case "skew":
            var l = e(a3.d[0]), m = e(a3.d[1]);
            return [1, Math.tan(m), 0, 0, Math.tan(l), 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
          case "skewx":
            var b3 = e(a3.d[0]);
            return [1, 0, 0, 0, Math.tan(b3), 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
          case "skewy":
            var b3 = e(a3.d[0]);
            return [1, Math.tan(b3), 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
          case "translate":
            var c3 = a3.d[0].px || 0, d3 = a3.d[1].px || 0;
            return [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, c3, d3, 0, 1];
          case "translatex":
            var c3 = a3.d[0].px || 0;
            return [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, c3, 0, 0, 1];
          case "translatey":
            var d3 = a3.d[0].px || 0;
            return [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, d3, 0, 1];
          case "translatez":
            var f2 = a3.d[0].px || 0;
            return [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, f2, 1];
          case "translate3d":
            var c3 = a3.d[0].px || 0, d3 = a3.d[1].px || 0, f2 = a3.d[2].px || 0;
            return [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, c3, d3, f2, 1];
          case "perspective":
            return [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, a3.d[0].px ? -1 / a3.d[0].px : 0, 0, 0, 0, 1];
          case "matrix":
            return [a3.d[0], a3.d[1], 0, 0, a3.d[2], a3.d[3], 0, 0, 0, 0, 1, 0, a3.d[4], a3.d[5], 0, 1];
          case "matrix3d":
            return a3.d;
        }
      }
      function g(a3) {
        return 0 === a3.length ? [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1] : a3.map(f).reduce(d2);
      }
      function h(a3) {
        return [i(g(a3))];
      }
      var i = function() {
        function a3(a4) {
          return a4[0][0] * a4[1][1] * a4[2][2] + a4[1][0] * a4[2][1] * a4[0][2] + a4[2][0] * a4[0][1] * a4[1][2] - a4[0][2] * a4[1][1] * a4[2][0] - a4[1][2] * a4[2][1] * a4[0][0] - a4[2][2] * a4[0][1] * a4[1][0];
        }
        function b3(b4) {
          for (var c3 = 1 / a3(b4), d4 = b4[0][0], e3 = b4[0][1], f3 = b4[0][2], g3 = b4[1][0], h3 = b4[1][1], i3 = b4[1][2], j2 = b4[2][0], k = b4[2][1], l = b4[2][2], m = [[(h3 * l - i3 * k) * c3, (f3 * k - e3 * l) * c3, (e3 * i3 - f3 * h3) * c3, 0], [(i3 * j2 - g3 * l) * c3, (d4 * l - f3 * j2) * c3, (f3 * g3 - d4 * i3) * c3, 0], [(g3 * k - h3 * j2) * c3, (j2 * e3 - d4 * k) * c3, (d4 * h3 - e3 * g3) * c3, 0]], n = [], o = 0; o < 3; o++) {
            for (var p = 0, q = 0; q < 3; q++)
              p += b4[3][q] * m[q][o];
            n.push(p);
          }
          return n.push(1), m.push(n), m;
        }
        function d3(a4) {
          return [[a4[0][0], a4[1][0], a4[2][0], a4[3][0]], [a4[0][1], a4[1][1], a4[2][1], a4[3][1]], [a4[0][2], a4[1][2], a4[2][2], a4[3][2]], [a4[0][3], a4[1][3], a4[2][3], a4[3][3]]];
        }
        function e2(a4, b4) {
          for (var c3 = [], d4 = 0; d4 < 4; d4++) {
            for (var e3 = 0, f3 = 0; f3 < 4; f3++)
              e3 += a4[f3] * b4[f3][d4];
            c3.push(e3);
          }
          return c3;
        }
        function f2(a4) {
          var b4 = g2(a4);
          return [a4[0] / b4, a4[1] / b4, a4[2] / b4];
        }
        function g2(a4) {
          return Math.sqrt(a4[0] * a4[0] + a4[1] * a4[1] + a4[2] * a4[2]);
        }
        function h2(a4, b4, c3, d4) {
          return [c3 * a4[0] + d4 * b4[0], c3 * a4[1] + d4 * b4[1], c3 * a4[2] + d4 * b4[2]];
        }
        function i2(a4, b4) {
          return [a4[1] * b4[2] - a4[2] * b4[1], a4[2] * b4[0] - a4[0] * b4[2], a4[0] * b4[1] - a4[1] * b4[0]];
        }
        function j(j2) {
          var k = [j2.slice(0, 4), j2.slice(4, 8), j2.slice(8, 12), j2.slice(12, 16)];
          if (1 !== k[3][3])
            return null;
          for (var l = [], m = 0; m < 4; m++)
            l.push(k[m].slice());
          for (var m = 0; m < 3; m++)
            l[m][3] = 0;
          if (0 === a3(l))
            return null;
          var n, o = [];
          k[0][3] || k[1][3] || k[2][3] ? (o.push(k[0][3]), o.push(k[1][3]), o.push(k[2][3]), o.push(k[3][3]), n = e2(o, d3(b3(l)))) : n = [0, 0, 0, 1];
          var p = k[3].slice(0, 3), q = [];
          q.push(k[0].slice(0, 3));
          var r = [];
          r.push(g2(q[0])), q[0] = f2(q[0]);
          var s = [];
          q.push(k[1].slice(0, 3)), s.push(c2(q[0], q[1])), q[1] = h2(q[1], q[0], 1, -s[0]), r.push(g2(q[1])), q[1] = f2(q[1]), s[0] /= r[1], q.push(k[2].slice(0, 3)), s.push(c2(q[0], q[2])), q[2] = h2(q[2], q[0], 1, -s[1]), s.push(c2(q[1], q[2])), q[2] = h2(q[2], q[1], 1, -s[2]), r.push(g2(q[2])), q[2] = f2(q[2]), s[1] /= r[2], s[2] /= r[2];
          var t = i2(q[1], q[2]);
          if (c2(q[0], t) < 0)
            for (var m = 0; m < 3; m++)
              r[m] *= -1, q[m][0] *= -1, q[m][1] *= -1, q[m][2] *= -1;
          var u, v, w = q[0][0] + q[1][1] + q[2][2] + 1;
          return w > 1e-4 ? (u = 0.5 / Math.sqrt(w), v = [(q[2][1] - q[1][2]) * u, (q[0][2] - q[2][0]) * u, (q[1][0] - q[0][1]) * u, 0.25 / u]) : q[0][0] > q[1][1] && q[0][0] > q[2][2] ? (u = 2 * Math.sqrt(1 + q[0][0] - q[1][1] - q[2][2]), v = [0.25 * u, (q[0][1] + q[1][0]) / u, (q[0][2] + q[2][0]) / u, (q[2][1] - q[1][2]) / u]) : q[1][1] > q[2][2] ? (u = 2 * Math.sqrt(1 + q[1][1] - q[0][0] - q[2][2]), v = [(q[0][1] + q[1][0]) / u, 0.25 * u, (q[1][2] + q[2][1]) / u, (q[0][2] - q[2][0]) / u]) : (u = 2 * Math.sqrt(1 + q[2][2] - q[0][0] - q[1][1]), v = [(q[0][2] + q[2][0]) / u, (q[1][2] + q[2][1]) / u, 0.25 * u, (q[1][0] - q[0][1]) / u]), [p, r, s, v, n];
        }
        return j;
      }();
      a2.dot = c2, a2.makeMatrixDecomposition = h, a2.transformListToMatrix = g;
    }(b), function(a2) {
      function b2(a3, b3) {
        var c3 = a3.exec(b3);
        if (c3)
          return c3 = a3.ignoreCase ? c3[0].toLowerCase() : c3[0], [c3, b3.substr(c3.length)];
      }
      function c2(a3, b3) {
        b3 = b3.replace(/^\s*/, "");
        var c3 = a3(b3);
        if (c3)
          return [c3[0], c3[1].replace(/^\s*/, "")];
      }
      function d2(a3, d3, e2) {
        a3 = c2.bind(null, a3);
        for (var f2 = []; ; ) {
          var g2 = a3(e2);
          if (!g2)
            return [f2, e2];
          if (f2.push(g2[0]), e2 = g2[1], !(g2 = b2(d3, e2)) || "" == g2[1])
            return [f2, e2];
          e2 = g2[1];
        }
      }
      function e(a3, b3) {
        for (var c3 = 0, d3 = 0; d3 < b3.length && (!/\s|,/.test(b3[d3]) || 0 != c3); d3++)
          if ("(" == b3[d3])
            c3++;
          else if (")" == b3[d3] && (c3--, 0 == c3 && d3++, c3 <= 0))
            break;
        var e2 = a3(b3.substr(0, d3));
        return void 0 == e2 ? void 0 : [e2, b3.substr(d3)];
      }
      function f(a3, b3) {
        for (var c3 = a3, d3 = b3; c3 && d3; )
          c3 > d3 ? c3 %= d3 : d3 %= c3;
        return c3 = a3 * b3 / (c3 + d3);
      }
      function g(a3) {
        return function(b3) {
          var c3 = a3(b3);
          return c3 && (c3[0] = void 0), c3;
        };
      }
      function h(a3, b3) {
        return function(c3) {
          return a3(c3) || [b3, c3];
        };
      }
      function i(b3, c3) {
        for (var d3 = [], e2 = 0; e2 < b3.length; e2++) {
          var f2 = a2.consumeTrimmed(b3[e2], c3);
          if (!f2 || "" == f2[0])
            return;
          void 0 !== f2[0] && d3.push(f2[0]), c3 = f2[1];
        }
        if ("" == c3)
          return d3;
      }
      function j(a3, b3, c3, d3, e2) {
        for (var g2 = [], h2 = [], i2 = [], j2 = f(d3.length, e2.length), k2 = 0; k2 < j2; k2++) {
          var l = b3(d3[k2 % d3.length], e2[k2 % e2.length]);
          if (!l)
            return;
          g2.push(l[0]), h2.push(l[1]), i2.push(l[2]);
        }
        return [g2, h2, function(b4) {
          var d4 = b4.map(function(a4, b5) {
            return i2[b5](a4);
          }).join(c3);
          return a3 ? a3(d4) : d4;
        }];
      }
      function k(a3, b3, c3) {
        for (var d3 = [], e2 = [], f2 = [], g2 = 0, h2 = 0; h2 < c3.length; h2++)
          if ("function" == typeof c3[h2]) {
            var i2 = c3[h2](a3[g2], b3[g2++]);
            d3.push(i2[0]), e2.push(i2[1]), f2.push(i2[2]);
          } else
            !function(a4) {
              d3.push(false), e2.push(false), f2.push(function() {
                return c3[a4];
              });
            }(h2);
        return [d3, e2, function(a4) {
          for (var b4 = "", c4 = 0; c4 < a4.length; c4++)
            b4 += f2[c4](a4[c4]);
          return b4;
        }];
      }
      a2.consumeToken = b2, a2.consumeTrimmed = c2, a2.consumeRepeated = d2, a2.consumeParenthesised = e, a2.ignore = g, a2.optional = h, a2.consumeList = i, a2.mergeNestedRepeated = j.bind(null, null), a2.mergeWrappedNestedRepeated = j, a2.mergeList = k;
    }(b), function(a2) {
      function b2(b3) {
        function c3(b4) {
          var c4 = a2.consumeToken(/^inset/i, b4);
          return c4 ? (d3.inset = true, c4) : (c4 = a2.consumeLengthOrPercent(b4)) ? (d3.lengths.push(c4[0]), c4) : (c4 = a2.consumeColor(b4), c4 ? (d3.color = c4[0], c4) : void 0);
        }
        var d3 = { inset: false, lengths: [], color: null }, e2 = a2.consumeRepeated(c3, /^/, b3);
        if (e2 && e2[0].length)
          return [d3, e2[1]];
      }
      function c2(c3) {
        var d3 = a2.consumeRepeated(b2, /^,/, c3);
        if (d3 && "" == d3[1])
          return d3[0];
      }
      function d2(b3, c3) {
        for (; b3.lengths.length < Math.max(b3.lengths.length, c3.lengths.length); )
          b3.lengths.push({ px: 0 });
        for (; c3.lengths.length < Math.max(b3.lengths.length, c3.lengths.length); )
          c3.lengths.push({ px: 0 });
        if (b3.inset == c3.inset && !!b3.color == !!c3.color) {
          for (var d3, e2 = [], f2 = [[], 0], g = [[], 0], h = 0; h < b3.lengths.length; h++) {
            var i = a2.mergeDimensions(b3.lengths[h], c3.lengths[h], 2 == h);
            f2[0].push(i[0]), g[0].push(i[1]), e2.push(i[2]);
          }
          if (b3.color && c3.color) {
            var j = a2.mergeColors(b3.color, c3.color);
            f2[1] = j[0], g[1] = j[1], d3 = j[2];
          }
          return [f2, g, function(a3) {
            for (var c4 = b3.inset ? "inset " : " ", f3 = 0; f3 < e2.length; f3++)
              c4 += e2[f3](a3[0][f3]) + " ";
            return d3 && (c4 += d3(a3[1])), c4;
          }];
        }
      }
      function e(b3, c3, d3, e2) {
        function f2(a3) {
          return { inset: a3, color: [0, 0, 0, 0], lengths: [{ px: 0 }, { px: 0 }, { px: 0 }, { px: 0 }] };
        }
        for (var g = [], h = [], i = 0; i < d3.length || i < e2.length; i++) {
          var j = d3[i] || f2(e2[i].inset), k = e2[i] || f2(d3[i].inset);
          g.push(j), h.push(k);
        }
        return a2.mergeNestedRepeated(b3, c3, g, h);
      }
      var f = e.bind(null, d2, ", ");
      a2.addPropertiesHandler(c2, f, ["box-shadow", "text-shadow"]);
    }(b), function(a2, b2) {
      function c2(a3) {
        return a3.toFixed(3).replace(/0+$/, "").replace(/\.$/, "");
      }
      function d2(a3, b3, c3) {
        return Math.min(b3, Math.max(a3, c3));
      }
      function e(a3) {
        if (/^\s*[-+]?(\d*\.)?\d+\s*$/.test(a3))
          return Number(a3);
      }
      function f(a3, b3) {
        return [a3, b3, c2];
      }
      function g(a3, b3) {
        if (0 != a3)
          return i(0, 1 / 0)(a3, b3);
      }
      function h(a3, b3) {
        return [a3, b3, function(a4) {
          return Math.round(d2(1, 1 / 0, a4));
        }];
      }
      function i(a3, b3) {
        return function(e2, f2) {
          return [e2, f2, function(e3) {
            return c2(d2(a3, b3, e3));
          }];
        };
      }
      function j(a3) {
        var b3 = a3.trim().split(/\s*[\s,]\s*/);
        if (0 !== b3.length) {
          for (var c3 = [], d3 = 0; d3 < b3.length; d3++) {
            var f2 = e(b3[d3]);
            if (void 0 === f2)
              return;
            c3.push(f2);
          }
          return c3;
        }
      }
      function k(a3, b3) {
        if (a3.length == b3.length)
          return [a3, b3, function(a4) {
            return a4.map(c2).join(" ");
          }];
      }
      function l(a3, b3) {
        return [a3, b3, Math.round];
      }
      a2.clamp = d2, a2.addPropertiesHandler(j, k, ["stroke-dasharray"]), a2.addPropertiesHandler(e, i(0, 1 / 0), ["border-image-width", "line-height"]), a2.addPropertiesHandler(e, i(0, 1), ["opacity", "shape-image-threshold"]), a2.addPropertiesHandler(e, g, ["flex-grow", "flex-shrink"]), a2.addPropertiesHandler(e, h, ["orphans", "widows"]), a2.addPropertiesHandler(e, l, ["z-index"]), a2.parseNumber = e, a2.parseNumberList = j, a2.mergeNumbers = f, a2.numberToString = c2;
    }(b), function(a2, b2) {
      function c2(a3, b3) {
        if ("visible" == a3 || "visible" == b3)
          return [0, 1, function(c3) {
            return c3 <= 0 ? a3 : c3 >= 1 ? b3 : "visible";
          }];
      }
      a2.addPropertiesHandler(String, c2, ["visibility"]);
    }(b), function(a2, b2) {
      function c2(a3) {
        a3 = a3.trim(), f.fillStyle = "#000", f.fillStyle = a3;
        var b3 = f.fillStyle;
        if (f.fillStyle = "#fff", f.fillStyle = a3, b3 == f.fillStyle) {
          f.fillRect(0, 0, 1, 1);
          var c3 = f.getImageData(0, 0, 1, 1).data;
          f.clearRect(0, 0, 1, 1);
          var d3 = c3[3] / 255;
          return [c3[0] * d3, c3[1] * d3, c3[2] * d3, d3];
        }
      }
      function d2(b3, c3) {
        return [b3, c3, function(b4) {
          function c4(a3) {
            return Math.max(0, Math.min(255, a3));
          }
          if (b4[3])
            for (var d3 = 0; d3 < 3; d3++)
              b4[d3] = Math.round(c4(b4[d3] / b4[3]));
          return b4[3] = a2.numberToString(a2.clamp(0, 1, b4[3])), "rgba(" + b4.join(",") + ")";
        }];
      }
      var e = document.createElementNS("http://www.w3.org/1999/xhtml", "canvas");
      e.width = e.height = 1;
      var f = e.getContext("2d");
      a2.addPropertiesHandler(c2, d2, ["background-color", "border-bottom-color", "border-left-color", "border-right-color", "border-top-color", "color", "fill", "flood-color", "lighting-color", "outline-color", "stop-color", "stroke", "text-decoration-color"]), a2.consumeColor = a2.consumeParenthesised.bind(null, c2), a2.mergeColors = d2;
    }(b), function(a2, b2) {
      function c2(a3) {
        function b3() {
          var b4 = h2.exec(a3);
          g2 = b4 ? b4[0] : void 0;
        }
        function c3() {
          var a4 = Number(g2);
          return b3(), a4;
        }
        function d3() {
          if ("(" !== g2)
            return c3();
          b3();
          var a4 = f2();
          return ")" !== g2 ? NaN : (b3(), a4);
        }
        function e2() {
          for (var a4 = d3(); "*" === g2 || "/" === g2; ) {
            var c4 = g2;
            b3();
            var e3 = d3();
            "*" === c4 ? a4 *= e3 : a4 /= e3;
          }
          return a4;
        }
        function f2() {
          for (var a4 = e2(); "+" === g2 || "-" === g2; ) {
            var c4 = g2;
            b3();
            var d4 = e2();
            "+" === c4 ? a4 += d4 : a4 -= d4;
          }
          return a4;
        }
        var g2, h2 = /([\+\-\w\.]+|[\(\)\*\/])/g;
        return b3(), f2();
      }
      function d2(a3, b3) {
        if ("0" == (b3 = b3.trim().toLowerCase()) && "px".search(a3) >= 0)
          return { px: 0 };
        if (/^[^(]*$|^calc/.test(b3)) {
          b3 = b3.replace(/calc\(/g, "(");
          var d3 = {};
          b3 = b3.replace(a3, function(a4) {
            return d3[a4] = null, "U" + a4;
          });
          for (var e2 = "U(" + a3.source + ")", f2 = b3.replace(/[-+]?(\d*\.)?\d+([Ee][-+]?\d+)?/g, "N").replace(new RegExp("N" + e2, "g"), "D").replace(/\s[+-]\s/g, "O").replace(/\s/g, ""), g2 = [/N\*(D)/g, /(N|D)[*\/]N/g, /(N|D)O\1/g, /\((N|D)\)/g], h2 = 0; h2 < g2.length; )
            g2[h2].test(f2) ? (f2 = f2.replace(g2[h2], "$1"), h2 = 0) : h2++;
          if ("D" == f2) {
            for (var i2 in d3) {
              var j2 = c2(b3.replace(new RegExp("U" + i2, "g"), "").replace(new RegExp(e2, "g"), "*0"));
              if (!isFinite(j2))
                return;
              d3[i2] = j2;
            }
            return d3;
          }
        }
      }
      function e(a3, b3) {
        return f(a3, b3, true);
      }
      function f(b3, c3, d3) {
        var e2, f2 = [];
        for (e2 in b3)
          f2.push(e2);
        for (e2 in c3)
          f2.indexOf(e2) < 0 && f2.push(e2);
        return b3 = f2.map(function(a3) {
          return b3[a3] || 0;
        }), c3 = f2.map(function(a3) {
          return c3[a3] || 0;
        }), [b3, c3, function(b4) {
          var c4 = b4.map(function(c5, e3) {
            return 1 == b4.length && d3 && (c5 = Math.max(c5, 0)), a2.numberToString(c5) + f2[e3];
          }).join(" + ");
          return b4.length > 1 ? "calc(" + c4 + ")" : c4;
        }];
      }
      var g = "px|em|ex|ch|rem|vw|vh|vmin|vmax|cm|mm|in|pt|pc", h = d2.bind(null, new RegExp(g, "g")), i = d2.bind(null, new RegExp(g + "|%", "g")), j = d2.bind(null, /deg|rad|grad|turn/g);
      a2.parseLength = h, a2.parseLengthOrPercent = i, a2.consumeLengthOrPercent = a2.consumeParenthesised.bind(null, i), a2.parseAngle = j, a2.mergeDimensions = f;
      var k = a2.consumeParenthesised.bind(null, h), l = a2.consumeRepeated.bind(void 0, k, /^/), m = a2.consumeRepeated.bind(void 0, l, /^,/);
      a2.consumeSizePairList = m;
      var n = function(a3) {
        var b3 = m(a3);
        if (b3 && "" == b3[1])
          return b3[0];
      }, o = a2.mergeNestedRepeated.bind(void 0, e, " "), p = a2.mergeNestedRepeated.bind(void 0, o, ",");
      a2.mergeNonNegativeSizePair = o, a2.addPropertiesHandler(n, p, ["background-size"]), a2.addPropertiesHandler(i, e, ["border-bottom-width", "border-image-width", "border-left-width", "border-right-width", "border-top-width", "flex-basis", "font-size", "height", "line-height", "max-height", "max-width", "outline-width", "width"]), a2.addPropertiesHandler(i, f, ["border-bottom-left-radius", "border-bottom-right-radius", "border-top-left-radius", "border-top-right-radius", "bottom", "left", "letter-spacing", "margin-bottom", "margin-left", "margin-right", "margin-top", "min-height", "min-width", "outline-offset", "padding-bottom", "padding-left", "padding-right", "padding-top", "perspective", "right", "shape-margin", "stroke-dashoffset", "text-indent", "top", "vertical-align", "word-spacing"]);
    }(b), function(a2, b2) {
      function c2(b3) {
        return a2.consumeLengthOrPercent(b3) || a2.consumeToken(/^auto/, b3);
      }
      function d2(b3) {
        var d3 = a2.consumeList([a2.ignore(a2.consumeToken.bind(null, /^rect/)), a2.ignore(a2.consumeToken.bind(null, /^\(/)), a2.consumeRepeated.bind(null, c2, /^,/), a2.ignore(a2.consumeToken.bind(null, /^\)/))], b3);
        if (d3 && 4 == d3[0].length)
          return d3[0];
      }
      function e(b3, c3) {
        return "auto" == b3 || "auto" == c3 ? [true, false, function(d3) {
          var e2 = d3 ? b3 : c3;
          if ("auto" == e2)
            return "auto";
          var f2 = a2.mergeDimensions(e2, e2);
          return f2[2](f2[0]);
        }] : a2.mergeDimensions(b3, c3);
      }
      function f(a3) {
        return "rect(" + a3 + ")";
      }
      var g = a2.mergeWrappedNestedRepeated.bind(null, f, e, ", ");
      a2.parseBox = d2, a2.mergeBoxes = g, a2.addPropertiesHandler(d2, g, ["clip"]);
    }(b), function(a2, b2) {
      function c2(a3) {
        return function(b3) {
          var c3 = 0;
          return a3.map(function(a4) {
            return a4 === k ? b3[c3++] : a4;
          });
        };
      }
      function d2(a3) {
        return a3;
      }
      function e(b3) {
        if ("none" == (b3 = b3.toLowerCase().trim()))
          return [];
        for (var c3, d3 = /\s*(\w+)\(([^)]*)\)/g, e2 = [], f2 = 0; c3 = d3.exec(b3); ) {
          if (c3.index != f2)
            return;
          f2 = c3.index + c3[0].length;
          var g2 = c3[1], h2 = n[g2];
          if (!h2)
            return;
          var i2 = c3[2].split(","), j2 = h2[0];
          if (j2.length < i2.length)
            return;
          for (var k2 = [], o = 0; o < j2.length; o++) {
            var p, q = i2[o], r = j2[o];
            if (void 0 === (p = q ? { A: function(b4) {
              return "0" == b4.trim() ? m : a2.parseAngle(b4);
            }, N: a2.parseNumber, T: a2.parseLengthOrPercent, L: a2.parseLength }[r.toUpperCase()](q) : { a: m, n: k2[0], t: l }[r]))
              return;
            k2.push(p);
          }
          if (e2.push({ t: g2, d: k2 }), d3.lastIndex == b3.length)
            return e2;
        }
      }
      function f(a3) {
        return a3.toFixed(6).replace(".000000", "");
      }
      function g(b3, c3) {
        if (b3.decompositionPair !== c3) {
          b3.decompositionPair = c3;
          var d3 = a2.makeMatrixDecomposition(b3);
        }
        if (c3.decompositionPair !== b3) {
          c3.decompositionPair = b3;
          var e2 = a2.makeMatrixDecomposition(c3);
        }
        return null == d3[0] || null == e2[0] ? [[false], [true], function(a3) {
          return a3 ? c3[0].d : b3[0].d;
        }] : (d3[0].push(0), e2[0].push(1), [d3, e2, function(b4) {
          var c4 = a2.quat(d3[0][3], e2[0][3], b4[5]);
          return a2.composeMatrix(b4[0], b4[1], b4[2], c4, b4[4]).map(f).join(",");
        }]);
      }
      function h(a3) {
        return a3.replace(/[xy]/, "");
      }
      function i(a3) {
        return a3.replace(/(x|y|z|3d)?$/, "3d");
      }
      function j(b3, c3) {
        var d3 = a2.makeMatrixDecomposition && true, e2 = false;
        if (!b3.length || !c3.length) {
          b3.length || (e2 = true, b3 = c3, c3 = []);
          for (var f2 = 0; f2 < b3.length; f2++) {
            var j2 = b3[f2].t, k2 = b3[f2].d, l2 = "scale" == j2.substr(0, 5) ? 1 : 0;
            c3.push({ t: j2, d: k2.map(function(a3) {
              if ("number" == typeof a3)
                return l2;
              var b4 = {};
              for (var c4 in a3)
                b4[c4] = l2;
              return b4;
            }) });
          }
        }
        var m2 = function(a3, b4) {
          return "perspective" == a3 && "perspective" == b4 || ("matrix" == a3 || "matrix3d" == a3) && ("matrix" == b4 || "matrix3d" == b4);
        }, o = [], p = [], q = [];
        if (b3.length != c3.length) {
          if (!d3)
            return;
          var r = g(b3, c3);
          o = [r[0]], p = [r[1]], q = [["matrix", [r[2]]]];
        } else
          for (var f2 = 0; f2 < b3.length; f2++) {
            var j2, s = b3[f2].t, t = c3[f2].t, u = b3[f2].d, v = c3[f2].d, w = n[s], x = n[t];
            if (m2(s, t)) {
              if (!d3)
                return;
              var r = g([b3[f2]], [c3[f2]]);
              o.push(r[0]), p.push(r[1]), q.push(["matrix", [r[2]]]);
            } else {
              if (s == t)
                j2 = s;
              else if (w[2] && x[2] && h(s) == h(t))
                j2 = h(s), u = w[2](u), v = x[2](v);
              else {
                if (!w[1] || !x[1] || i(s) != i(t)) {
                  if (!d3)
                    return;
                  var r = g(b3, c3);
                  o = [r[0]], p = [r[1]], q = [["matrix", [r[2]]]];
                  break;
                }
                j2 = i(s), u = w[1](u), v = x[1](v);
              }
              for (var y = [], z = [], A = [], B = 0; B < u.length; B++) {
                var C = "number" == typeof u[B] ? a2.mergeNumbers : a2.mergeDimensions, r = C(u[B], v[B]);
                y[B] = r[0], z[B] = r[1], A.push(r[2]);
              }
              o.push(y), p.push(z), q.push([j2, A]);
            }
          }
        if (e2) {
          var D = o;
          o = p, p = D;
        }
        return [o, p, function(a3) {
          return a3.map(function(a4, b4) {
            var c4 = a4.map(function(a5, c5) {
              return q[b4][1][c5](a5);
            }).join(",");
            return "matrix" == q[b4][0] && 16 == c4.split(",").length && (q[b4][0] = "matrix3d"), q[b4][0] + "(" + c4 + ")";
          }).join(" ");
        }];
      }
      var k = null, l = { px: 0 }, m = { deg: 0 }, n = { matrix: ["NNNNNN", [k, k, 0, 0, k, k, 0, 0, 0, 0, 1, 0, k, k, 0, 1], d2], matrix3d: ["NNNNNNNNNNNNNNNN", d2], rotate: ["A"], rotatex: ["A"], rotatey: ["A"], rotatez: ["A"], rotate3d: ["NNNA"], perspective: ["L"], scale: ["Nn", c2([k, k, 1]), d2], scalex: ["N", c2([k, 1, 1]), c2([k, 1])], scaley: ["N", c2([1, k, 1]), c2([1, k])], scalez: ["N", c2([1, 1, k])], scale3d: ["NNN", d2], skew: ["Aa", null, d2], skewx: ["A", null, c2([k, m])], skewy: ["A", null, c2([m, k])], translate: ["Tt", c2([k, k, l]), d2], translatex: ["T", c2([k, l, l]), c2([k, l])], translatey: ["T", c2([l, k, l]), c2([l, k])], translatez: ["L", c2([l, l, k])], translate3d: ["TTL", d2] };
      a2.addPropertiesHandler(e, j, ["transform"]), a2.transformToSvgMatrix = function(b3) {
        var c3 = a2.transformListToMatrix(e(b3));
        return "matrix(" + f(c3[0]) + " " + f(c3[1]) + " " + f(c3[4]) + " " + f(c3[5]) + " " + f(c3[12]) + " " + f(c3[13]) + ")";
      };
    }(b), function(a2) {
      function b2(a3) {
        var b3 = Number(a3);
        if (!(isNaN(b3) || b3 < 100 || b3 > 900 || b3 % 100 != 0))
          return b3;
      }
      function c2(b3) {
        return b3 = 100 * Math.round(b3 / 100), b3 = a2.clamp(100, 900, b3), 400 === b3 ? "normal" : 700 === b3 ? "bold" : String(b3);
      }
      function d2(a3, b3) {
        return [a3, b3, c2];
      }
      a2.addPropertiesHandler(b2, d2, ["font-weight"]);
    }(b), function(a2) {
      function b2(a3) {
        var b3 = {};
        for (var c3 in a3)
          b3[c3] = -a3[c3];
        return b3;
      }
      function c2(b3) {
        return a2.consumeToken(/^(left|center|right|top|bottom)\b/i, b3) || a2.consumeLengthOrPercent(b3);
      }
      function d2(b3, d3) {
        var e2 = a2.consumeRepeated(c2, /^/, d3);
        if (e2 && "" == e2[1]) {
          var f2 = e2[0];
          if (f2[0] = f2[0] || "center", f2[1] = f2[1] || "center", 3 == b3 && (f2[2] = f2[2] || { px: 0 }), f2.length == b3) {
            if (/top|bottom/.test(f2[0]) || /left|right/.test(f2[1])) {
              var h2 = f2[0];
              f2[0] = f2[1], f2[1] = h2;
            }
            if (/left|right|center|Object/.test(f2[0]) && /top|bottom|center|Object/.test(f2[1]))
              return f2.map(function(a3) {
                return "object" == typeof a3 ? a3 : g[a3];
              });
          }
        }
      }
      function e(d3) {
        var e2 = a2.consumeRepeated(c2, /^/, d3);
        if (e2) {
          for (var f2 = e2[0], h2 = [{ "%": 50 }, { "%": 50 }], i2 = 0, j = false, k = 0; k < f2.length; k++) {
            var l = f2[k];
            "string" == typeof l ? (j = /bottom|right/.test(l), i2 = { left: 0, right: 0, center: i2, top: 1, bottom: 1 }[l], h2[i2] = g[l], "center" == l && i2++) : (j && (l = b2(l), l["%"] = (l["%"] || 0) + 100), h2[i2] = l, i2++, j = false);
          }
          return [h2, e2[1]];
        }
      }
      function f(b3) {
        var c3 = a2.consumeRepeated(e, /^,/, b3);
        if (c3 && "" == c3[1])
          return c3[0];
      }
      var g = { left: { "%": 0 }, center: { "%": 50 }, right: { "%": 100 }, top: { "%": 0 }, bottom: { "%": 100 } }, h = a2.mergeNestedRepeated.bind(null, a2.mergeDimensions, " ");
      a2.addPropertiesHandler(d2.bind(null, 3), h, ["transform-origin"]), a2.addPropertiesHandler(d2.bind(null, 2), h, ["perspective-origin"]), a2.consumePosition = e, a2.mergeOffsetList = h;
      var i = a2.mergeNestedRepeated.bind(null, h, ", ");
      a2.addPropertiesHandler(f, i, ["background-position", "object-position"]);
    }(b), function(a2) {
      function b2(b3) {
        var c3 = a2.consumeToken(/^circle/, b3);
        if (c3 && c3[0])
          return ["circle"].concat(a2.consumeList([a2.ignore(a2.consumeToken.bind(void 0, /^\(/)), d2, a2.ignore(a2.consumeToken.bind(void 0, /^at/)), a2.consumePosition, a2.ignore(a2.consumeToken.bind(void 0, /^\)/))], c3[1]));
        var f2 = a2.consumeToken(/^ellipse/, b3);
        if (f2 && f2[0])
          return ["ellipse"].concat(a2.consumeList([a2.ignore(a2.consumeToken.bind(void 0, /^\(/)), e, a2.ignore(a2.consumeToken.bind(void 0, /^at/)), a2.consumePosition, a2.ignore(a2.consumeToken.bind(void 0, /^\)/))], f2[1]));
        var g2 = a2.consumeToken(/^polygon/, b3);
        return g2 && g2[0] ? ["polygon"].concat(a2.consumeList([a2.ignore(a2.consumeToken.bind(void 0, /^\(/)), a2.optional(a2.consumeToken.bind(void 0, /^nonzero\s*,|^evenodd\s*,/), "nonzero,"), a2.consumeSizePairList, a2.ignore(a2.consumeToken.bind(void 0, /^\)/))], g2[1])) : void 0;
      }
      function c2(b3, c3) {
        if (b3[0] === c3[0])
          return "circle" == b3[0] ? a2.mergeList(b3.slice(1), c3.slice(1), ["circle(", a2.mergeDimensions, " at ", a2.mergeOffsetList, ")"]) : "ellipse" == b3[0] ? a2.mergeList(b3.slice(1), c3.slice(1), ["ellipse(", a2.mergeNonNegativeSizePair, " at ", a2.mergeOffsetList, ")"]) : "polygon" == b3[0] && b3[1] == c3[1] ? a2.mergeList(b3.slice(2), c3.slice(2), ["polygon(", b3[1], g, ")"]) : void 0;
      }
      var d2 = a2.consumeParenthesised.bind(null, a2.parseLengthOrPercent), e = a2.consumeRepeated.bind(void 0, d2, /^/), f = a2.mergeNestedRepeated.bind(void 0, a2.mergeDimensions, " "), g = a2.mergeNestedRepeated.bind(void 0, f, ",");
      a2.addPropertiesHandler(b2, c2, ["shape-outside"]);
    }(b), function(a2, b2) {
      function c2(a3, b3) {
        b3.concat([a3]).forEach(function(b4) {
          b4 in document.documentElement.style && (d2[a3] = b4), e[b4] = a3;
        });
      }
      var d2 = {}, e = {};
      c2("transform", ["webkitTransform", "msTransform"]), c2("transformOrigin", ["webkitTransformOrigin"]), c2("perspective", ["webkitPerspective"]), c2("perspectiveOrigin", ["webkitPerspectiveOrigin"]), a2.propertyName = function(a3) {
        return d2[a3] || a3;
      }, a2.unprefixedPropertyName = function(a3) {
        return e[a3] || a3;
      };
    }(b);
  }(), function() {
    if (void 0 === document.createElement("div").animate([]).oncancel) {
      var a2;
      if (window.performance && performance.now)
        var a2 = function() {
          return performance.now();
        };
      else
        var a2 = function() {
          return Date.now();
        };
      var b2 = function(a3, b3, c2) {
        this.target = a3, this.currentTime = b3, this.timelineTime = c2, this.type = "cancel", this.bubbles = false, this.cancelable = false, this.currentTarget = a3, this.defaultPrevented = false, this.eventPhase = Event.AT_TARGET, this.timeStamp = Date.now();
      }, c = window.Element.prototype.animate;
      window.Element.prototype.animate = function(d, e) {
        var f = c.call(this, d, e);
        f._cancelHandlers = [], f.oncancel = null;
        var g = f.cancel;
        f.cancel = function() {
          g.call(this);
          var c2 = new b2(this, null, a2()), d2 = this._cancelHandlers.concat(this.oncancel ? [this.oncancel] : []);
          setTimeout(function() {
            d2.forEach(function(a3) {
              a3.call(c2.target, c2);
            });
          }, 0);
        };
        var h = f.addEventListener;
        f.addEventListener = function(a3, b3) {
          "function" == typeof b3 && "cancel" == a3 ? this._cancelHandlers.push(b3) : h.call(this, a3, b3);
        };
        var i = f.removeEventListener;
        return f.removeEventListener = function(a3, b3) {
          if ("cancel" == a3) {
            var c2 = this._cancelHandlers.indexOf(b3);
            c2 >= 0 && this._cancelHandlers.splice(c2, 1);
          } else
            i.call(this, a3, b3);
        }, f;
      };
    }
  }(), function(a2) {
    var b2 = document.documentElement, c = null, d = false;
    try {
      var e = getComputedStyle(b2).getPropertyValue("opacity"), f = "0" == e ? "1" : "0";
      c = b2.animate({ opacity: [f, f] }, { duration: 1 }), c.currentTime = 0, d = getComputedStyle(b2).getPropertyValue("opacity") == f;
    } catch (a3) {
    } finally {
      c && c.cancel();
    }
    if (!d) {
      var g = window.Element.prototype.animate;
      window.Element.prototype.animate = function(b3, c2) {
        return window.Symbol && Symbol.iterator && Array.prototype.from && b3[Symbol.iterator] && (b3 = Array.from(b3)), Array.isArray(b3) || null === b3 || (b3 = a2.convertToArrayForm(b3)), g.call(this, b3, c2);
      };
    }
  }(a);
}();
const theme = "";
const swiper = "";
const navigation = "";
const pagination = "";
const scrollbar = "";
function isObject$1(obj) {
  return obj !== null && typeof obj === "object" && "constructor" in obj && obj.constructor === Object;
}
function extend$1(target, src) {
  if (target === void 0) {
    target = {};
  }
  if (src === void 0) {
    src = {};
  }
  Object.keys(src).forEach((key) => {
    if (typeof target[key] === "undefined")
      target[key] = src[key];
    else if (isObject$1(src[key]) && isObject$1(target[key]) && Object.keys(src[key]).length > 0) {
      extend$1(target[key], src[key]);
    }
  });
}
const ssrDocument = {
  body: {},
  addEventListener() {
  },
  removeEventListener() {
  },
  activeElement: {
    blur() {
    },
    nodeName: ""
  },
  querySelector() {
    return null;
  },
  querySelectorAll() {
    return [];
  },
  getElementById() {
    return null;
  },
  createEvent() {
    return {
      initEvent() {
      }
    };
  },
  createElement() {
    return {
      children: [],
      childNodes: [],
      style: {},
      setAttribute() {
      },
      getElementsByTagName() {
        return [];
      }
    };
  },
  createElementNS() {
    return {};
  },
  importNode() {
    return null;
  },
  location: {
    hash: "",
    host: "",
    hostname: "",
    href: "",
    origin: "",
    pathname: "",
    protocol: "",
    search: ""
  }
};
function getDocument() {
  const doc = typeof document !== "undefined" ? document : {};
  extend$1(doc, ssrDocument);
  return doc;
}
const ssrWindow = {
  document: ssrDocument,
  navigator: {
    userAgent: ""
  },
  location: {
    hash: "",
    host: "",
    hostname: "",
    href: "",
    origin: "",
    pathname: "",
    protocol: "",
    search: ""
  },
  history: {
    replaceState() {
    },
    pushState() {
    },
    go() {
    },
    back() {
    }
  },
  CustomEvent: function CustomEvent() {
    return this;
  },
  addEventListener() {
  },
  removeEventListener() {
  },
  getComputedStyle() {
    return {
      getPropertyValue() {
        return "";
      }
    };
  },
  Image() {
  },
  Date() {
  },
  screen: {},
  setTimeout() {
  },
  clearTimeout() {
  },
  matchMedia() {
    return {};
  },
  requestAnimationFrame(callback) {
    if (typeof setTimeout === "undefined") {
      callback();
      return null;
    }
    return setTimeout(callback, 0);
  },
  cancelAnimationFrame(id) {
    if (typeof setTimeout === "undefined") {
      return;
    }
    clearTimeout(id);
  }
};
function getWindow() {
  const win = typeof window !== "undefined" ? window : {};
  extend$1(win, ssrWindow);
  return win;
}
function classesToTokens(classes2) {
  if (classes2 === void 0) {
    classes2 = "";
  }
  return classes2.trim().split(" ").filter((c) => !!c.trim());
}
function deleteProps(obj) {
  const object = obj;
  Object.keys(object).forEach((key) => {
    try {
      object[key] = null;
    } catch (e) {
    }
    try {
      delete object[key];
    } catch (e) {
    }
  });
}
function nextTick(callback, delay) {
  if (delay === void 0) {
    delay = 0;
  }
  return setTimeout(callback, delay);
}
function now() {
  return Date.now();
}
function getComputedStyle$1(el) {
  const window2 = getWindow();
  let style;
  if (window2.getComputedStyle) {
    style = window2.getComputedStyle(el, null);
  }
  if (!style && el.currentStyle) {
    style = el.currentStyle;
  }
  if (!style) {
    style = el.style;
  }
  return style;
}
function getTranslate(el, axis) {
  if (axis === void 0) {
    axis = "x";
  }
  const window2 = getWindow();
  let matrix;
  let curTransform;
  let transformMatrix;
  const curStyle = getComputedStyle$1(el);
  if (window2.WebKitCSSMatrix) {
    curTransform = curStyle.transform || curStyle.webkitTransform;
    if (curTransform.split(",").length > 6) {
      curTransform = curTransform.split(", ").map((a) => a.replace(",", ".")).join(", ");
    }
    transformMatrix = new window2.WebKitCSSMatrix(curTransform === "none" ? "" : curTransform);
  } else {
    transformMatrix = curStyle.MozTransform || curStyle.OTransform || curStyle.MsTransform || curStyle.msTransform || curStyle.transform || curStyle.getPropertyValue("transform").replace("translate(", "matrix(1, 0, 0, 1,");
    matrix = transformMatrix.toString().split(",");
  }
  if (axis === "x") {
    if (window2.WebKitCSSMatrix)
      curTransform = transformMatrix.m41;
    else if (matrix.length === 16)
      curTransform = parseFloat(matrix[12]);
    else
      curTransform = parseFloat(matrix[4]);
  }
  if (axis === "y") {
    if (window2.WebKitCSSMatrix)
      curTransform = transformMatrix.m42;
    else if (matrix.length === 16)
      curTransform = parseFloat(matrix[13]);
    else
      curTransform = parseFloat(matrix[5]);
  }
  return curTransform || 0;
}
function isObject(o) {
  return typeof o === "object" && o !== null && o.constructor && Object.prototype.toString.call(o).slice(8, -1) === "Object";
}
function isNode(node) {
  if (typeof window !== "undefined" && typeof window.HTMLElement !== "undefined") {
    return node instanceof HTMLElement;
  }
  return node && (node.nodeType === 1 || node.nodeType === 11);
}
function extend() {
  const to = Object(arguments.length <= 0 ? void 0 : arguments[0]);
  const noExtend = ["__proto__", "constructor", "prototype"];
  for (let i = 1; i < arguments.length; i += 1) {
    const nextSource = i < 0 || arguments.length <= i ? void 0 : arguments[i];
    if (nextSource !== void 0 && nextSource !== null && !isNode(nextSource)) {
      const keysArray = Object.keys(Object(nextSource)).filter((key) => noExtend.indexOf(key) < 0);
      for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex += 1) {
        const nextKey = keysArray[nextIndex];
        const desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
        if (desc !== void 0 && desc.enumerable) {
          if (isObject(to[nextKey]) && isObject(nextSource[nextKey])) {
            if (nextSource[nextKey].__swiper__) {
              to[nextKey] = nextSource[nextKey];
            } else {
              extend(to[nextKey], nextSource[nextKey]);
            }
          } else if (!isObject(to[nextKey]) && isObject(nextSource[nextKey])) {
            to[nextKey] = {};
            if (nextSource[nextKey].__swiper__) {
              to[nextKey] = nextSource[nextKey];
            } else {
              extend(to[nextKey], nextSource[nextKey]);
            }
          } else {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
    }
  }
  return to;
}
function setCSSProperty(el, varName, varValue) {
  el.style.setProperty(varName, varValue);
}
function animateCSSModeScroll(_ref) {
  let {
    swiper: swiper2,
    targetPosition,
    side
  } = _ref;
  const window2 = getWindow();
  const startPosition = -swiper2.translate;
  let startTime = null;
  let time;
  const duration = swiper2.params.speed;
  swiper2.wrapperEl.style.scrollSnapType = "none";
  window2.cancelAnimationFrame(swiper2.cssModeFrameID);
  const dir = targetPosition > startPosition ? "next" : "prev";
  const isOutOfBound = (current, target) => {
    return dir === "next" && current >= target || dir === "prev" && current <= target;
  };
  const animate = () => {
    time = (/* @__PURE__ */ new Date()).getTime();
    if (startTime === null) {
      startTime = time;
    }
    const progress = Math.max(Math.min((time - startTime) / duration, 1), 0);
    const easeProgress = 0.5 - Math.cos(progress * Math.PI) / 2;
    let currentPosition = startPosition + easeProgress * (targetPosition - startPosition);
    if (isOutOfBound(currentPosition, targetPosition)) {
      currentPosition = targetPosition;
    }
    swiper2.wrapperEl.scrollTo({
      [side]: currentPosition
    });
    if (isOutOfBound(currentPosition, targetPosition)) {
      swiper2.wrapperEl.style.overflow = "hidden";
      swiper2.wrapperEl.style.scrollSnapType = "";
      setTimeout(() => {
        swiper2.wrapperEl.style.overflow = "";
        swiper2.wrapperEl.scrollTo({
          [side]: currentPosition
        });
      });
      window2.cancelAnimationFrame(swiper2.cssModeFrameID);
      return;
    }
    swiper2.cssModeFrameID = window2.requestAnimationFrame(animate);
  };
  animate();
}
function elementChildren(element, selector) {
  if (selector === void 0) {
    selector = "";
  }
  return [...element.children].filter((el) => el.matches(selector));
}
function showWarning(text) {
  try {
    console.warn(text);
    return;
  } catch (err) {
  }
}
function createElement(tag, classes2) {
  if (classes2 === void 0) {
    classes2 = [];
  }
  const el = document.createElement(tag);
  el.classList.add(...Array.isArray(classes2) ? classes2 : classesToTokens(classes2));
  return el;
}
function elementOffset(el) {
  const window2 = getWindow();
  const document2 = getDocument();
  const box = el.getBoundingClientRect();
  const body = document2.body;
  const clientTop = el.clientTop || body.clientTop || 0;
  const clientLeft = el.clientLeft || body.clientLeft || 0;
  const scrollTop = el === window2 ? window2.scrollY : el.scrollTop;
  const scrollLeft = el === window2 ? window2.scrollX : el.scrollLeft;
  return {
    top: box.top + scrollTop - clientTop,
    left: box.left + scrollLeft - clientLeft
  };
}
function elementPrevAll(el, selector) {
  const prevEls = [];
  while (el.previousElementSibling) {
    const prev = el.previousElementSibling;
    if (selector) {
      if (prev.matches(selector))
        prevEls.push(prev);
    } else
      prevEls.push(prev);
    el = prev;
  }
  return prevEls;
}
function elementNextAll(el, selector) {
  const nextEls = [];
  while (el.nextElementSibling) {
    const next = el.nextElementSibling;
    if (selector) {
      if (next.matches(selector))
        nextEls.push(next);
    } else
      nextEls.push(next);
    el = next;
  }
  return nextEls;
}
function elementStyle(el, prop) {
  const window2 = getWindow();
  return window2.getComputedStyle(el, null).getPropertyValue(prop);
}
function elementIndex(el) {
  let child = el;
  let i;
  if (child) {
    i = 0;
    while ((child = child.previousSibling) !== null) {
      if (child.nodeType === 1)
        i += 1;
    }
    return i;
  }
  return void 0;
}
function elementParents(el, selector) {
  const parents = [];
  let parent = el.parentElement;
  while (parent) {
    if (selector) {
      if (parent.matches(selector))
        parents.push(parent);
    } else {
      parents.push(parent);
    }
    parent = parent.parentElement;
  }
  return parents;
}
function elementOuterSize(el, size, includeMargins) {
  const window2 = getWindow();
  if (includeMargins) {
    return el[size === "width" ? "offsetWidth" : "offsetHeight"] + parseFloat(window2.getComputedStyle(el, null).getPropertyValue(size === "width" ? "margin-right" : "margin-top")) + parseFloat(window2.getComputedStyle(el, null).getPropertyValue(size === "width" ? "margin-left" : "margin-bottom"));
  }
  return el.offsetWidth;
}
function makeElementsArray(el) {
  return (Array.isArray(el) ? el : [el]).filter((e) => !!e);
}
let support;
function calcSupport() {
  const window2 = getWindow();
  const document2 = getDocument();
  return {
    smoothScroll: document2.documentElement && document2.documentElement.style && "scrollBehavior" in document2.documentElement.style,
    touch: !!("ontouchstart" in window2 || window2.DocumentTouch && document2 instanceof window2.DocumentTouch)
  };
}
function getSupport() {
  if (!support) {
    support = calcSupport();
  }
  return support;
}
let deviceCached;
function calcDevice(_temp) {
  let {
    userAgent
  } = _temp === void 0 ? {} : _temp;
  const support2 = getSupport();
  const window2 = getWindow();
  const platform = window2.navigator.platform;
  const ua = userAgent || window2.navigator.userAgent;
  const device = {
    ios: false,
    android: false
  };
  const screenWidth = window2.screen.width;
  const screenHeight = window2.screen.height;
  const android = ua.match(/(Android);?[\s\/]+([\d.]+)?/);
  let ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
  const ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/);
  const iphone = !ipad && ua.match(/(iPhone\sOS|iOS)\s([\d_]+)/);
  const windows = platform === "Win32";
  let macos = platform === "MacIntel";
  const iPadScreens = ["1024x1366", "1366x1024", "834x1194", "1194x834", "834x1112", "1112x834", "768x1024", "1024x768", "820x1180", "1180x820", "810x1080", "1080x810"];
  if (!ipad && macos && support2.touch && iPadScreens.indexOf(`${screenWidth}x${screenHeight}`) >= 0) {
    ipad = ua.match(/(Version)\/([\d.]+)/);
    if (!ipad)
      ipad = [0, 1, "13_0_0"];
    macos = false;
  }
  if (android && !windows) {
    device.os = "android";
    device.android = true;
  }
  if (ipad || iphone || ipod) {
    device.os = "ios";
    device.ios = true;
  }
  return device;
}
function getDevice(overrides) {
  if (overrides === void 0) {
    overrides = {};
  }
  if (!deviceCached) {
    deviceCached = calcDevice(overrides);
  }
  return deviceCached;
}
let browser;
function calcBrowser() {
  const window2 = getWindow();
  const device = getDevice();
  let needPerspectiveFix = false;
  function isSafari() {
    const ua = window2.navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") >= 0 && ua.indexOf("chrome") < 0 && ua.indexOf("android") < 0;
  }
  if (isSafari()) {
    const ua = String(window2.navigator.userAgent);
    if (ua.includes("Version/")) {
      const [major, minor] = ua.split("Version/")[1].split(" ")[0].split(".").map((num) => Number(num));
      needPerspectiveFix = major < 16 || major === 16 && minor < 2;
    }
  }
  const isWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(window2.navigator.userAgent);
  const isSafariBrowser = isSafari();
  const need3dFix = isSafariBrowser || isWebView && device.ios;
  return {
    isSafari: needPerspectiveFix || isSafariBrowser,
    needPerspectiveFix,
    need3dFix,
    isWebView
  };
}
function getBrowser() {
  if (!browser) {
    browser = calcBrowser();
  }
  return browser;
}
function Resize(_ref) {
  let {
    swiper: swiper2,
    on,
    emit
  } = _ref;
  const window2 = getWindow();
  let observer = null;
  let animationFrame = null;
  const resizeHandler = () => {
    if (!swiper2 || swiper2.destroyed || !swiper2.initialized)
      return;
    emit("beforeResize");
    emit("resize");
  };
  const createObserver = () => {
    if (!swiper2 || swiper2.destroyed || !swiper2.initialized)
      return;
    observer = new ResizeObserver((entries) => {
      animationFrame = window2.requestAnimationFrame(() => {
        const {
          width,
          height
        } = swiper2;
        let newWidth = width;
        let newHeight = height;
        entries.forEach((_ref2) => {
          let {
            contentBoxSize,
            contentRect,
            target
          } = _ref2;
          if (target && target !== swiper2.el)
            return;
          newWidth = contentRect ? contentRect.width : (contentBoxSize[0] || contentBoxSize).inlineSize;
          newHeight = contentRect ? contentRect.height : (contentBoxSize[0] || contentBoxSize).blockSize;
        });
        if (newWidth !== width || newHeight !== height) {
          resizeHandler();
        }
      });
    });
    observer.observe(swiper2.el);
  };
  const removeObserver = () => {
    if (animationFrame) {
      window2.cancelAnimationFrame(animationFrame);
    }
    if (observer && observer.unobserve && swiper2.el) {
      observer.unobserve(swiper2.el);
      observer = null;
    }
  };
  const orientationChangeHandler = () => {
    if (!swiper2 || swiper2.destroyed || !swiper2.initialized)
      return;
    emit("orientationchange");
  };
  on("init", () => {
    if (swiper2.params.resizeObserver && typeof window2.ResizeObserver !== "undefined") {
      createObserver();
      return;
    }
    window2.addEventListener("resize", resizeHandler);
    window2.addEventListener("orientationchange", orientationChangeHandler);
  });
  on("destroy", () => {
    removeObserver();
    window2.removeEventListener("resize", resizeHandler);
    window2.removeEventListener("orientationchange", orientationChangeHandler);
  });
}
function Observer(_ref) {
  let {
    swiper: swiper2,
    extendParams,
    on,
    emit
  } = _ref;
  const observers = [];
  const window2 = getWindow();
  const attach = function(target, options) {
    if (options === void 0) {
      options = {};
    }
    const ObserverFunc = window2.MutationObserver || window2.WebkitMutationObserver;
    const observer = new ObserverFunc((mutations) => {
      if (swiper2.__preventObserver__)
        return;
      if (mutations.length === 1) {
        emit("observerUpdate", mutations[0]);
        return;
      }
      const observerUpdate = function observerUpdate2() {
        emit("observerUpdate", mutations[0]);
      };
      if (window2.requestAnimationFrame) {
        window2.requestAnimationFrame(observerUpdate);
      } else {
        window2.setTimeout(observerUpdate, 0);
      }
    });
    observer.observe(target, {
      attributes: typeof options.attributes === "undefined" ? true : options.attributes,
      childList: typeof options.childList === "undefined" ? true : options.childList,
      characterData: typeof options.characterData === "undefined" ? true : options.characterData
    });
    observers.push(observer);
  };
  const init2 = () => {
    if (!swiper2.params.observer)
      return;
    if (swiper2.params.observeParents) {
      const containerParents = elementParents(swiper2.hostEl);
      for (let i = 0; i < containerParents.length; i += 1) {
        attach(containerParents[i]);
      }
    }
    attach(swiper2.hostEl, {
      childList: swiper2.params.observeSlideChildren
    });
    attach(swiper2.wrapperEl, {
      attributes: false
    });
  };
  const destroy = () => {
    observers.forEach((observer) => {
      observer.disconnect();
    });
    observers.splice(0, observers.length);
  };
  extendParams({
    observer: false,
    observeParents: false,
    observeSlideChildren: false
  });
  on("init", init2);
  on("destroy", destroy);
}
var eventsEmitter = {
  on(events2, handler, priority) {
    const self2 = this;
    if (!self2.eventsListeners || self2.destroyed)
      return self2;
    if (typeof handler !== "function")
      return self2;
    const method = priority ? "unshift" : "push";
    events2.split(" ").forEach((event) => {
      if (!self2.eventsListeners[event])
        self2.eventsListeners[event] = [];
      self2.eventsListeners[event][method](handler);
    });
    return self2;
  },
  once(events2, handler, priority) {
    const self2 = this;
    if (!self2.eventsListeners || self2.destroyed)
      return self2;
    if (typeof handler !== "function")
      return self2;
    function onceHandler() {
      self2.off(events2, onceHandler);
      if (onceHandler.__emitterProxy) {
        delete onceHandler.__emitterProxy;
      }
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      handler.apply(self2, args);
    }
    onceHandler.__emitterProxy = handler;
    return self2.on(events2, onceHandler, priority);
  },
  onAny(handler, priority) {
    const self2 = this;
    if (!self2.eventsListeners || self2.destroyed)
      return self2;
    if (typeof handler !== "function")
      return self2;
    const method = priority ? "unshift" : "push";
    if (self2.eventsAnyListeners.indexOf(handler) < 0) {
      self2.eventsAnyListeners[method](handler);
    }
    return self2;
  },
  offAny(handler) {
    const self2 = this;
    if (!self2.eventsListeners || self2.destroyed)
      return self2;
    if (!self2.eventsAnyListeners)
      return self2;
    const index = self2.eventsAnyListeners.indexOf(handler);
    if (index >= 0) {
      self2.eventsAnyListeners.splice(index, 1);
    }
    return self2;
  },
  off(events2, handler) {
    const self2 = this;
    if (!self2.eventsListeners || self2.destroyed)
      return self2;
    if (!self2.eventsListeners)
      return self2;
    events2.split(" ").forEach((event) => {
      if (typeof handler === "undefined") {
        self2.eventsListeners[event] = [];
      } else if (self2.eventsListeners[event]) {
        self2.eventsListeners[event].forEach((eventHandler, index) => {
          if (eventHandler === handler || eventHandler.__emitterProxy && eventHandler.__emitterProxy === handler) {
            self2.eventsListeners[event].splice(index, 1);
          }
        });
      }
    });
    return self2;
  },
  emit() {
    const self2 = this;
    if (!self2.eventsListeners || self2.destroyed)
      return self2;
    if (!self2.eventsListeners)
      return self2;
    let events2;
    let data;
    let context;
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }
    if (typeof args[0] === "string" || Array.isArray(args[0])) {
      events2 = args[0];
      data = args.slice(1, args.length);
      context = self2;
    } else {
      events2 = args[0].events;
      data = args[0].data;
      context = args[0].context || self2;
    }
    data.unshift(context);
    const eventsArray = Array.isArray(events2) ? events2 : events2.split(" ");
    eventsArray.forEach((event) => {
      if (self2.eventsAnyListeners && self2.eventsAnyListeners.length) {
        self2.eventsAnyListeners.forEach((eventHandler) => {
          eventHandler.apply(context, [event, ...data]);
        });
      }
      if (self2.eventsListeners && self2.eventsListeners[event]) {
        self2.eventsListeners[event].forEach((eventHandler) => {
          eventHandler.apply(context, data);
        });
      }
    });
    return self2;
  }
};
function updateSize() {
  const swiper2 = this;
  let width;
  let height;
  const el = swiper2.el;
  if (typeof swiper2.params.width !== "undefined" && swiper2.params.width !== null) {
    width = swiper2.params.width;
  } else {
    width = el.clientWidth;
  }
  if (typeof swiper2.params.height !== "undefined" && swiper2.params.height !== null) {
    height = swiper2.params.height;
  } else {
    height = el.clientHeight;
  }
  if (width === 0 && swiper2.isHorizontal() || height === 0 && swiper2.isVertical()) {
    return;
  }
  width = width - parseInt(elementStyle(el, "padding-left") || 0, 10) - parseInt(elementStyle(el, "padding-right") || 0, 10);
  height = height - parseInt(elementStyle(el, "padding-top") || 0, 10) - parseInt(elementStyle(el, "padding-bottom") || 0, 10);
  if (Number.isNaN(width))
    width = 0;
  if (Number.isNaN(height))
    height = 0;
  Object.assign(swiper2, {
    width,
    height,
    size: swiper2.isHorizontal() ? width : height
  });
}
function updateSlides() {
  const swiper2 = this;
  function getDirectionPropertyValue(node, label) {
    return parseFloat(node.getPropertyValue(swiper2.getDirectionLabel(label)) || 0);
  }
  const params = swiper2.params;
  const {
    wrapperEl,
    slidesEl,
    size: swiperSize,
    rtlTranslate: rtl,
    wrongRTL
  } = swiper2;
  const isVirtual = swiper2.virtual && params.virtual.enabled;
  const previousSlidesLength = isVirtual ? swiper2.virtual.slides.length : swiper2.slides.length;
  const slides = elementChildren(slidesEl, `.${swiper2.params.slideClass}, swiper-slide`);
  const slidesLength = isVirtual ? swiper2.virtual.slides.length : slides.length;
  let snapGrid = [];
  const slidesGrid = [];
  const slidesSizesGrid = [];
  let offsetBefore = params.slidesOffsetBefore;
  if (typeof offsetBefore === "function") {
    offsetBefore = params.slidesOffsetBefore.call(swiper2);
  }
  let offsetAfter = params.slidesOffsetAfter;
  if (typeof offsetAfter === "function") {
    offsetAfter = params.slidesOffsetAfter.call(swiper2);
  }
  const previousSnapGridLength = swiper2.snapGrid.length;
  const previousSlidesGridLength = swiper2.slidesGrid.length;
  let spaceBetween = params.spaceBetween;
  let slidePosition = -offsetBefore;
  let prevSlideSize = 0;
  let index = 0;
  if (typeof swiperSize === "undefined") {
    return;
  }
  if (typeof spaceBetween === "string" && spaceBetween.indexOf("%") >= 0) {
    spaceBetween = parseFloat(spaceBetween.replace("%", "")) / 100 * swiperSize;
  } else if (typeof spaceBetween === "string") {
    spaceBetween = parseFloat(spaceBetween);
  }
  swiper2.virtualSize = -spaceBetween;
  slides.forEach((slideEl) => {
    if (rtl) {
      slideEl.style.marginLeft = "";
    } else {
      slideEl.style.marginRight = "";
    }
    slideEl.style.marginBottom = "";
    slideEl.style.marginTop = "";
  });
  if (params.centeredSlides && params.cssMode) {
    setCSSProperty(wrapperEl, "--swiper-centered-offset-before", "");
    setCSSProperty(wrapperEl, "--swiper-centered-offset-after", "");
  }
  const gridEnabled = params.grid && params.grid.rows > 1 && swiper2.grid;
  if (gridEnabled) {
    swiper2.grid.initSlides(slides);
  } else if (swiper2.grid) {
    swiper2.grid.unsetSlides();
  }
  let slideSize;
  const shouldResetSlideSize = params.slidesPerView === "auto" && params.breakpoints && Object.keys(params.breakpoints).filter((key) => {
    return typeof params.breakpoints[key].slidesPerView !== "undefined";
  }).length > 0;
  for (let i = 0; i < slidesLength; i += 1) {
    slideSize = 0;
    let slide2;
    if (slides[i])
      slide2 = slides[i];
    if (gridEnabled) {
      swiper2.grid.updateSlide(i, slide2, slides);
    }
    if (slides[i] && elementStyle(slide2, "display") === "none")
      continue;
    if (params.slidesPerView === "auto") {
      if (shouldResetSlideSize) {
        slides[i].style[swiper2.getDirectionLabel("width")] = ``;
      }
      const slideStyles = getComputedStyle(slide2);
      const currentTransform = slide2.style.transform;
      const currentWebKitTransform = slide2.style.webkitTransform;
      if (currentTransform) {
        slide2.style.transform = "none";
      }
      if (currentWebKitTransform) {
        slide2.style.webkitTransform = "none";
      }
      if (params.roundLengths) {
        slideSize = swiper2.isHorizontal() ? elementOuterSize(slide2, "width", true) : elementOuterSize(slide2, "height", true);
      } else {
        const width = getDirectionPropertyValue(slideStyles, "width");
        const paddingLeft = getDirectionPropertyValue(slideStyles, "padding-left");
        const paddingRight = getDirectionPropertyValue(slideStyles, "padding-right");
        const marginLeft = getDirectionPropertyValue(slideStyles, "margin-left");
        const marginRight = getDirectionPropertyValue(slideStyles, "margin-right");
        const boxSizing = slideStyles.getPropertyValue("box-sizing");
        if (boxSizing && boxSizing === "border-box") {
          slideSize = width + marginLeft + marginRight;
        } else {
          const {
            clientWidth,
            offsetWidth
          } = slide2;
          slideSize = width + paddingLeft + paddingRight + marginLeft + marginRight + (offsetWidth - clientWidth);
        }
      }
      if (currentTransform) {
        slide2.style.transform = currentTransform;
      }
      if (currentWebKitTransform) {
        slide2.style.webkitTransform = currentWebKitTransform;
      }
      if (params.roundLengths)
        slideSize = Math.floor(slideSize);
    } else {
      slideSize = (swiperSize - (params.slidesPerView - 1) * spaceBetween) / params.slidesPerView;
      if (params.roundLengths)
        slideSize = Math.floor(slideSize);
      if (slides[i]) {
        slides[i].style[swiper2.getDirectionLabel("width")] = `${slideSize}px`;
      }
    }
    if (slides[i]) {
      slides[i].swiperSlideSize = slideSize;
    }
    slidesSizesGrid.push(slideSize);
    if (params.centeredSlides) {
      slidePosition = slidePosition + slideSize / 2 + prevSlideSize / 2 + spaceBetween;
      if (prevSlideSize === 0 && i !== 0)
        slidePosition = slidePosition - swiperSize / 2 - spaceBetween;
      if (i === 0)
        slidePosition = slidePosition - swiperSize / 2 - spaceBetween;
      if (Math.abs(slidePosition) < 1 / 1e3)
        slidePosition = 0;
      if (params.roundLengths)
        slidePosition = Math.floor(slidePosition);
      if (index % params.slidesPerGroup === 0)
        snapGrid.push(slidePosition);
      slidesGrid.push(slidePosition);
    } else {
      if (params.roundLengths)
        slidePosition = Math.floor(slidePosition);
      if ((index - Math.min(swiper2.params.slidesPerGroupSkip, index)) % swiper2.params.slidesPerGroup === 0)
        snapGrid.push(slidePosition);
      slidesGrid.push(slidePosition);
      slidePosition = slidePosition + slideSize + spaceBetween;
    }
    swiper2.virtualSize += slideSize + spaceBetween;
    prevSlideSize = slideSize;
    index += 1;
  }
  swiper2.virtualSize = Math.max(swiper2.virtualSize, swiperSize) + offsetAfter;
  if (rtl && wrongRTL && (params.effect === "slide" || params.effect === "coverflow")) {
    wrapperEl.style.width = `${swiper2.virtualSize + spaceBetween}px`;
  }
  if (params.setWrapperSize) {
    wrapperEl.style[swiper2.getDirectionLabel("width")] = `${swiper2.virtualSize + spaceBetween}px`;
  }
  if (gridEnabled) {
    swiper2.grid.updateWrapperSize(slideSize, snapGrid);
  }
  if (!params.centeredSlides) {
    const newSlidesGrid = [];
    for (let i = 0; i < snapGrid.length; i += 1) {
      let slidesGridItem = snapGrid[i];
      if (params.roundLengths)
        slidesGridItem = Math.floor(slidesGridItem);
      if (snapGrid[i] <= swiper2.virtualSize - swiperSize) {
        newSlidesGrid.push(slidesGridItem);
      }
    }
    snapGrid = newSlidesGrid;
    if (Math.floor(swiper2.virtualSize - swiperSize) - Math.floor(snapGrid[snapGrid.length - 1]) > 1) {
      snapGrid.push(swiper2.virtualSize - swiperSize);
    }
  }
  if (isVirtual && params.loop) {
    const size = slidesSizesGrid[0] + spaceBetween;
    if (params.slidesPerGroup > 1) {
      const groups = Math.ceil((swiper2.virtual.slidesBefore + swiper2.virtual.slidesAfter) / params.slidesPerGroup);
      const groupSize = size * params.slidesPerGroup;
      for (let i = 0; i < groups; i += 1) {
        snapGrid.push(snapGrid[snapGrid.length - 1] + groupSize);
      }
    }
    for (let i = 0; i < swiper2.virtual.slidesBefore + swiper2.virtual.slidesAfter; i += 1) {
      if (params.slidesPerGroup === 1) {
        snapGrid.push(snapGrid[snapGrid.length - 1] + size);
      }
      slidesGrid.push(slidesGrid[slidesGrid.length - 1] + size);
      swiper2.virtualSize += size;
    }
  }
  if (snapGrid.length === 0)
    snapGrid = [0];
  if (spaceBetween !== 0) {
    const key = swiper2.isHorizontal() && rtl ? "marginLeft" : swiper2.getDirectionLabel("marginRight");
    slides.filter((_, slideIndex) => {
      if (!params.cssMode || params.loop)
        return true;
      if (slideIndex === slides.length - 1) {
        return false;
      }
      return true;
    }).forEach((slideEl) => {
      slideEl.style[key] = `${spaceBetween}px`;
    });
  }
  if (params.centeredSlides && params.centeredSlidesBounds) {
    let allSlidesSize = 0;
    slidesSizesGrid.forEach((slideSizeValue) => {
      allSlidesSize += slideSizeValue + (spaceBetween || 0);
    });
    allSlidesSize -= spaceBetween;
    const maxSnap = allSlidesSize - swiperSize;
    snapGrid = snapGrid.map((snap) => {
      if (snap <= 0)
        return -offsetBefore;
      if (snap > maxSnap)
        return maxSnap + offsetAfter;
      return snap;
    });
  }
  if (params.centerInsufficientSlides) {
    let allSlidesSize = 0;
    slidesSizesGrid.forEach((slideSizeValue) => {
      allSlidesSize += slideSizeValue + (spaceBetween || 0);
    });
    allSlidesSize -= spaceBetween;
    if (allSlidesSize < swiperSize) {
      const allSlidesOffset = (swiperSize - allSlidesSize) / 2;
      snapGrid.forEach((snap, snapIndex) => {
        snapGrid[snapIndex] = snap - allSlidesOffset;
      });
      slidesGrid.forEach((snap, snapIndex) => {
        slidesGrid[snapIndex] = snap + allSlidesOffset;
      });
    }
  }
  Object.assign(swiper2, {
    slides,
    snapGrid,
    slidesGrid,
    slidesSizesGrid
  });
  if (params.centeredSlides && params.cssMode && !params.centeredSlidesBounds) {
    setCSSProperty(wrapperEl, "--swiper-centered-offset-before", `${-snapGrid[0]}px`);
    setCSSProperty(wrapperEl, "--swiper-centered-offset-after", `${swiper2.size / 2 - slidesSizesGrid[slidesSizesGrid.length - 1] / 2}px`);
    const addToSnapGrid = -swiper2.snapGrid[0];
    const addToSlidesGrid = -swiper2.slidesGrid[0];
    swiper2.snapGrid = swiper2.snapGrid.map((v) => v + addToSnapGrid);
    swiper2.slidesGrid = swiper2.slidesGrid.map((v) => v + addToSlidesGrid);
  }
  if (slidesLength !== previousSlidesLength) {
    swiper2.emit("slidesLengthChange");
  }
  if (snapGrid.length !== previousSnapGridLength) {
    if (swiper2.params.watchOverflow)
      swiper2.checkOverflow();
    swiper2.emit("snapGridLengthChange");
  }
  if (slidesGrid.length !== previousSlidesGridLength) {
    swiper2.emit("slidesGridLengthChange");
  }
  if (params.watchSlidesProgress) {
    swiper2.updateSlidesOffset();
  }
  swiper2.emit("slidesUpdated");
  if (!isVirtual && !params.cssMode && (params.effect === "slide" || params.effect === "fade")) {
    const backFaceHiddenClass = `${params.containerModifierClass}backface-hidden`;
    const hasClassBackfaceClassAdded = swiper2.el.classList.contains(backFaceHiddenClass);
    if (slidesLength <= params.maxBackfaceHiddenSlides) {
      if (!hasClassBackfaceClassAdded)
        swiper2.el.classList.add(backFaceHiddenClass);
    } else if (hasClassBackfaceClassAdded) {
      swiper2.el.classList.remove(backFaceHiddenClass);
    }
  }
}
function updateAutoHeight(speed) {
  const swiper2 = this;
  const activeSlides = [];
  const isVirtual = swiper2.virtual && swiper2.params.virtual.enabled;
  let newHeight = 0;
  let i;
  if (typeof speed === "number") {
    swiper2.setTransition(speed);
  } else if (speed === true) {
    swiper2.setTransition(swiper2.params.speed);
  }
  const getSlideByIndex = (index) => {
    if (isVirtual) {
      return swiper2.slides[swiper2.getSlideIndexByData(index)];
    }
    return swiper2.slides[index];
  };
  if (swiper2.params.slidesPerView !== "auto" && swiper2.params.slidesPerView > 1) {
    if (swiper2.params.centeredSlides) {
      (swiper2.visibleSlides || []).forEach((slide2) => {
        activeSlides.push(slide2);
      });
    } else {
      for (i = 0; i < Math.ceil(swiper2.params.slidesPerView); i += 1) {
        const index = swiper2.activeIndex + i;
        if (index > swiper2.slides.length && !isVirtual)
          break;
        activeSlides.push(getSlideByIndex(index));
      }
    }
  } else {
    activeSlides.push(getSlideByIndex(swiper2.activeIndex));
  }
  for (i = 0; i < activeSlides.length; i += 1) {
    if (typeof activeSlides[i] !== "undefined") {
      const height = activeSlides[i].offsetHeight;
      newHeight = height > newHeight ? height : newHeight;
    }
  }
  if (newHeight || newHeight === 0)
    swiper2.wrapperEl.style.height = `${newHeight}px`;
}
function updateSlidesOffset() {
  const swiper2 = this;
  const slides = swiper2.slides;
  const minusOffset = swiper2.isElement ? swiper2.isHorizontal() ? swiper2.wrapperEl.offsetLeft : swiper2.wrapperEl.offsetTop : 0;
  for (let i = 0; i < slides.length; i += 1) {
    slides[i].swiperSlideOffset = (swiper2.isHorizontal() ? slides[i].offsetLeft : slides[i].offsetTop) - minusOffset - swiper2.cssOverflowAdjustment();
  }
}
function updateSlidesProgress(translate2) {
  if (translate2 === void 0) {
    translate2 = this && this.translate || 0;
  }
  const swiper2 = this;
  const params = swiper2.params;
  const {
    slides,
    rtlTranslate: rtl,
    snapGrid
  } = swiper2;
  if (slides.length === 0)
    return;
  if (typeof slides[0].swiperSlideOffset === "undefined")
    swiper2.updateSlidesOffset();
  let offsetCenter = -translate2;
  if (rtl)
    offsetCenter = translate2;
  slides.forEach((slideEl) => {
    slideEl.classList.remove(params.slideVisibleClass, params.slideFullyVisibleClass);
  });
  swiper2.visibleSlidesIndexes = [];
  swiper2.visibleSlides = [];
  let spaceBetween = params.spaceBetween;
  if (typeof spaceBetween === "string" && spaceBetween.indexOf("%") >= 0) {
    spaceBetween = parseFloat(spaceBetween.replace("%", "")) / 100 * swiper2.size;
  } else if (typeof spaceBetween === "string") {
    spaceBetween = parseFloat(spaceBetween);
  }
  for (let i = 0; i < slides.length; i += 1) {
    const slide2 = slides[i];
    let slideOffset = slide2.swiperSlideOffset;
    if (params.cssMode && params.centeredSlides) {
      slideOffset -= slides[0].swiperSlideOffset;
    }
    const slideProgress = (offsetCenter + (params.centeredSlides ? swiper2.minTranslate() : 0) - slideOffset) / (slide2.swiperSlideSize + spaceBetween);
    const originalSlideProgress = (offsetCenter - snapGrid[0] + (params.centeredSlides ? swiper2.minTranslate() : 0) - slideOffset) / (slide2.swiperSlideSize + spaceBetween);
    const slideBefore = -(offsetCenter - slideOffset);
    const slideAfter = slideBefore + swiper2.slidesSizesGrid[i];
    const isFullyVisible = slideBefore >= 0 && slideBefore <= swiper2.size - swiper2.slidesSizesGrid[i];
    const isVisible = slideBefore >= 0 && slideBefore < swiper2.size - 1 || slideAfter > 1 && slideAfter <= swiper2.size || slideBefore <= 0 && slideAfter >= swiper2.size;
    if (isVisible) {
      swiper2.visibleSlides.push(slide2);
      swiper2.visibleSlidesIndexes.push(i);
      slides[i].classList.add(params.slideVisibleClass);
    }
    if (isFullyVisible) {
      slides[i].classList.add(params.slideFullyVisibleClass);
    }
    slide2.progress = rtl ? -slideProgress : slideProgress;
    slide2.originalProgress = rtl ? -originalSlideProgress : originalSlideProgress;
  }
}
function updateProgress(translate2) {
  const swiper2 = this;
  if (typeof translate2 === "undefined") {
    const multiplier = swiper2.rtlTranslate ? -1 : 1;
    translate2 = swiper2 && swiper2.translate && swiper2.translate * multiplier || 0;
  }
  const params = swiper2.params;
  const translatesDiff = swiper2.maxTranslate() - swiper2.minTranslate();
  let {
    progress,
    isBeginning,
    isEnd,
    progressLoop
  } = swiper2;
  const wasBeginning = isBeginning;
  const wasEnd = isEnd;
  if (translatesDiff === 0) {
    progress = 0;
    isBeginning = true;
    isEnd = true;
  } else {
    progress = (translate2 - swiper2.minTranslate()) / translatesDiff;
    const isBeginningRounded = Math.abs(translate2 - swiper2.minTranslate()) < 1;
    const isEndRounded = Math.abs(translate2 - swiper2.maxTranslate()) < 1;
    isBeginning = isBeginningRounded || progress <= 0;
    isEnd = isEndRounded || progress >= 1;
    if (isBeginningRounded)
      progress = 0;
    if (isEndRounded)
      progress = 1;
  }
  if (params.loop) {
    const firstSlideIndex = swiper2.getSlideIndexByData(0);
    const lastSlideIndex = swiper2.getSlideIndexByData(swiper2.slides.length - 1);
    const firstSlideTranslate = swiper2.slidesGrid[firstSlideIndex];
    const lastSlideTranslate = swiper2.slidesGrid[lastSlideIndex];
    const translateMax = swiper2.slidesGrid[swiper2.slidesGrid.length - 1];
    const translateAbs = Math.abs(translate2);
    if (translateAbs >= firstSlideTranslate) {
      progressLoop = (translateAbs - firstSlideTranslate) / translateMax;
    } else {
      progressLoop = (translateAbs + translateMax - lastSlideTranslate) / translateMax;
    }
    if (progressLoop > 1)
      progressLoop -= 1;
  }
  Object.assign(swiper2, {
    progress,
    progressLoop,
    isBeginning,
    isEnd
  });
  if (params.watchSlidesProgress || params.centeredSlides && params.autoHeight)
    swiper2.updateSlidesProgress(translate2);
  if (isBeginning && !wasBeginning) {
    swiper2.emit("reachBeginning toEdge");
  }
  if (isEnd && !wasEnd) {
    swiper2.emit("reachEnd toEdge");
  }
  if (wasBeginning && !isBeginning || wasEnd && !isEnd) {
    swiper2.emit("fromEdge");
  }
  swiper2.emit("progress", progress);
}
const toggleSlideClasses = (slideEl, condition, className) => {
  if (condition && !slideEl.classList.contains(className)) {
    slideEl.classList.add(className);
  } else if (!condition && slideEl.classList.contains(className)) {
    slideEl.classList.remove(className);
  }
};
function updateSlidesClasses() {
  const swiper2 = this;
  const {
    slides,
    params,
    slidesEl,
    activeIndex
  } = swiper2;
  const isVirtual = swiper2.virtual && params.virtual.enabled;
  const gridEnabled = swiper2.grid && params.grid && params.grid.rows > 1;
  const getFilteredSlide = (selector) => {
    return elementChildren(slidesEl, `.${params.slideClass}${selector}, swiper-slide${selector}`)[0];
  };
  let activeSlide;
  let prevSlide;
  let nextSlide;
  if (isVirtual) {
    if (params.loop) {
      let slideIndex = activeIndex - swiper2.virtual.slidesBefore;
      if (slideIndex < 0)
        slideIndex = swiper2.virtual.slides.length + slideIndex;
      if (slideIndex >= swiper2.virtual.slides.length)
        slideIndex -= swiper2.virtual.slides.length;
      activeSlide = getFilteredSlide(`[data-swiper-slide-index="${slideIndex}"]`);
    } else {
      activeSlide = getFilteredSlide(`[data-swiper-slide-index="${activeIndex}"]`);
    }
  } else {
    if (gridEnabled) {
      activeSlide = slides.filter((slideEl) => slideEl.column === activeIndex)[0];
      nextSlide = slides.filter((slideEl) => slideEl.column === activeIndex + 1)[0];
      prevSlide = slides.filter((slideEl) => slideEl.column === activeIndex - 1)[0];
    } else {
      activeSlide = slides[activeIndex];
    }
  }
  if (activeSlide) {
    if (!gridEnabled) {
      nextSlide = elementNextAll(activeSlide, `.${params.slideClass}, swiper-slide`)[0];
      if (params.loop && !nextSlide) {
        nextSlide = slides[0];
      }
      prevSlide = elementPrevAll(activeSlide, `.${params.slideClass}, swiper-slide`)[0];
      if (params.loop && !prevSlide === 0) {
        prevSlide = slides[slides.length - 1];
      }
    }
  }
  slides.forEach((slideEl) => {
    toggleSlideClasses(slideEl, slideEl === activeSlide, params.slideActiveClass);
    toggleSlideClasses(slideEl, slideEl === nextSlide, params.slideNextClass);
    toggleSlideClasses(slideEl, slideEl === prevSlide, params.slidePrevClass);
  });
  swiper2.emitSlidesClasses();
}
const processLazyPreloader = (swiper2, imageEl) => {
  if (!swiper2 || swiper2.destroyed || !swiper2.params)
    return;
  const slideSelector = () => swiper2.isElement ? `swiper-slide` : `.${swiper2.params.slideClass}`;
  const slideEl = imageEl.closest(slideSelector());
  if (slideEl) {
    let lazyEl = slideEl.querySelector(`.${swiper2.params.lazyPreloaderClass}`);
    if (!lazyEl && swiper2.isElement) {
      if (slideEl.shadowRoot) {
        lazyEl = slideEl.shadowRoot.querySelector(`.${swiper2.params.lazyPreloaderClass}`);
      } else {
        requestAnimationFrame(() => {
          if (slideEl.shadowRoot) {
            lazyEl = slideEl.shadowRoot.querySelector(`.${swiper2.params.lazyPreloaderClass}`);
            if (lazyEl)
              lazyEl.remove();
          }
        });
      }
    }
    if (lazyEl)
      lazyEl.remove();
  }
};
const unlazy = (swiper2, index) => {
  if (!swiper2.slides[index])
    return;
  const imageEl = swiper2.slides[index].querySelector('[loading="lazy"]');
  if (imageEl)
    imageEl.removeAttribute("loading");
};
const preload2 = (swiper2) => {
  if (!swiper2 || swiper2.destroyed || !swiper2.params)
    return;
  let amount = swiper2.params.lazyPreloadPrevNext;
  const len = swiper2.slides.length;
  if (!len || !amount || amount < 0)
    return;
  amount = Math.min(amount, len);
  const slidesPerView = swiper2.params.slidesPerView === "auto" ? swiper2.slidesPerViewDynamic() : Math.ceil(swiper2.params.slidesPerView);
  const activeIndex = swiper2.activeIndex;
  if (swiper2.params.grid && swiper2.params.grid.rows > 1) {
    const activeColumn = activeIndex;
    const preloadColumns = [activeColumn - amount];
    preloadColumns.push(...Array.from({
      length: amount
    }).map((_, i) => {
      return activeColumn + slidesPerView + i;
    }));
    swiper2.slides.forEach((slideEl, i) => {
      if (preloadColumns.includes(slideEl.column))
        unlazy(swiper2, i);
    });
    return;
  }
  const slideIndexLastInView = activeIndex + slidesPerView - 1;
  if (swiper2.params.rewind || swiper2.params.loop) {
    for (let i = activeIndex - amount; i <= slideIndexLastInView + amount; i += 1) {
      const realIndex = (i % len + len) % len;
      if (realIndex < activeIndex || realIndex > slideIndexLastInView)
        unlazy(swiper2, realIndex);
    }
  } else {
    for (let i = Math.max(activeIndex - amount, 0); i <= Math.min(slideIndexLastInView + amount, len - 1); i += 1) {
      if (i !== activeIndex && (i > slideIndexLastInView || i < activeIndex)) {
        unlazy(swiper2, i);
      }
    }
  }
};
function getActiveIndexByTranslate(swiper2) {
  const {
    slidesGrid,
    params
  } = swiper2;
  const translate2 = swiper2.rtlTranslate ? swiper2.translate : -swiper2.translate;
  let activeIndex;
  for (let i = 0; i < slidesGrid.length; i += 1) {
    if (typeof slidesGrid[i + 1] !== "undefined") {
      if (translate2 >= slidesGrid[i] && translate2 < slidesGrid[i + 1] - (slidesGrid[i + 1] - slidesGrid[i]) / 2) {
        activeIndex = i;
      } else if (translate2 >= slidesGrid[i] && translate2 < slidesGrid[i + 1]) {
        activeIndex = i + 1;
      }
    } else if (translate2 >= slidesGrid[i]) {
      activeIndex = i;
    }
  }
  if (params.normalizeSlideIndex) {
    if (activeIndex < 0 || typeof activeIndex === "undefined")
      activeIndex = 0;
  }
  return activeIndex;
}
function updateActiveIndex(newActiveIndex) {
  const swiper2 = this;
  const translate2 = swiper2.rtlTranslate ? swiper2.translate : -swiper2.translate;
  const {
    snapGrid,
    params,
    activeIndex: previousIndex,
    realIndex: previousRealIndex,
    snapIndex: previousSnapIndex
  } = swiper2;
  let activeIndex = newActiveIndex;
  let snapIndex;
  const getVirtualRealIndex = (aIndex) => {
    let realIndex2 = aIndex - swiper2.virtual.slidesBefore;
    if (realIndex2 < 0) {
      realIndex2 = swiper2.virtual.slides.length + realIndex2;
    }
    if (realIndex2 >= swiper2.virtual.slides.length) {
      realIndex2 -= swiper2.virtual.slides.length;
    }
    return realIndex2;
  };
  if (typeof activeIndex === "undefined") {
    activeIndex = getActiveIndexByTranslate(swiper2);
  }
  if (snapGrid.indexOf(translate2) >= 0) {
    snapIndex = snapGrid.indexOf(translate2);
  } else {
    const skip = Math.min(params.slidesPerGroupSkip, activeIndex);
    snapIndex = skip + Math.floor((activeIndex - skip) / params.slidesPerGroup);
  }
  if (snapIndex >= snapGrid.length)
    snapIndex = snapGrid.length - 1;
  if (activeIndex === previousIndex && !swiper2.params.loop) {
    if (snapIndex !== previousSnapIndex) {
      swiper2.snapIndex = snapIndex;
      swiper2.emit("snapIndexChange");
    }
    return;
  }
  if (activeIndex === previousIndex && swiper2.params.loop && swiper2.virtual && swiper2.params.virtual.enabled) {
    swiper2.realIndex = getVirtualRealIndex(activeIndex);
    return;
  }
  const gridEnabled = swiper2.grid && params.grid && params.grid.rows > 1;
  let realIndex;
  if (swiper2.virtual && params.virtual.enabled && params.loop) {
    realIndex = getVirtualRealIndex(activeIndex);
  } else if (gridEnabled) {
    const firstSlideInColumn = swiper2.slides.filter((slideEl) => slideEl.column === activeIndex)[0];
    let activeSlideIndex = parseInt(firstSlideInColumn.getAttribute("data-swiper-slide-index"), 10);
    if (Number.isNaN(activeSlideIndex)) {
      activeSlideIndex = Math.max(swiper2.slides.indexOf(firstSlideInColumn), 0);
    }
    realIndex = Math.floor(activeSlideIndex / params.grid.rows);
  } else if (swiper2.slides[activeIndex]) {
    const slideIndex = swiper2.slides[activeIndex].getAttribute("data-swiper-slide-index");
    if (slideIndex) {
      realIndex = parseInt(slideIndex, 10);
    } else {
      realIndex = activeIndex;
    }
  } else {
    realIndex = activeIndex;
  }
  Object.assign(swiper2, {
    previousSnapIndex,
    snapIndex,
    previousRealIndex,
    realIndex,
    previousIndex,
    activeIndex
  });
  if (swiper2.initialized) {
    preload2(swiper2);
  }
  swiper2.emit("activeIndexChange");
  swiper2.emit("snapIndexChange");
  if (swiper2.initialized || swiper2.params.runCallbacksOnInit) {
    if (previousRealIndex !== realIndex) {
      swiper2.emit("realIndexChange");
    }
    swiper2.emit("slideChange");
  }
}
function updateClickedSlide(el, path) {
  const swiper2 = this;
  const params = swiper2.params;
  let slide2 = el.closest(`.${params.slideClass}, swiper-slide`);
  if (!slide2 && swiper2.isElement && path && path.length > 1 && path.includes(el)) {
    [...path.slice(path.indexOf(el) + 1, path.length)].forEach((pathEl) => {
      if (!slide2 && pathEl.matches && pathEl.matches(`.${params.slideClass}, swiper-slide`)) {
        slide2 = pathEl;
      }
    });
  }
  let slideFound = false;
  let slideIndex;
  if (slide2) {
    for (let i = 0; i < swiper2.slides.length; i += 1) {
      if (swiper2.slides[i] === slide2) {
        slideFound = true;
        slideIndex = i;
        break;
      }
    }
  }
  if (slide2 && slideFound) {
    swiper2.clickedSlide = slide2;
    if (swiper2.virtual && swiper2.params.virtual.enabled) {
      swiper2.clickedIndex = parseInt(slide2.getAttribute("data-swiper-slide-index"), 10);
    } else {
      swiper2.clickedIndex = slideIndex;
    }
  } else {
    swiper2.clickedSlide = void 0;
    swiper2.clickedIndex = void 0;
    return;
  }
  if (params.slideToClickedSlide && swiper2.clickedIndex !== void 0 && swiper2.clickedIndex !== swiper2.activeIndex) {
    swiper2.slideToClickedSlide();
  }
}
var update = {
  updateSize,
  updateSlides,
  updateAutoHeight,
  updateSlidesOffset,
  updateSlidesProgress,
  updateProgress,
  updateSlidesClasses,
  updateActiveIndex,
  updateClickedSlide
};
function getSwiperTranslate(axis) {
  if (axis === void 0) {
    axis = this.isHorizontal() ? "x" : "y";
  }
  const swiper2 = this;
  const {
    params,
    rtlTranslate: rtl,
    translate: translate2,
    wrapperEl
  } = swiper2;
  if (params.virtualTranslate) {
    return rtl ? -translate2 : translate2;
  }
  if (params.cssMode) {
    return translate2;
  }
  let currentTranslate = getTranslate(wrapperEl, axis);
  currentTranslate += swiper2.cssOverflowAdjustment();
  if (rtl)
    currentTranslate = -currentTranslate;
  return currentTranslate || 0;
}
function setTranslate(translate2, byController) {
  const swiper2 = this;
  const {
    rtlTranslate: rtl,
    params,
    wrapperEl,
    progress
  } = swiper2;
  let x = 0;
  let y = 0;
  const z = 0;
  if (swiper2.isHorizontal()) {
    x = rtl ? -translate2 : translate2;
  } else {
    y = translate2;
  }
  if (params.roundLengths) {
    x = Math.floor(x);
    y = Math.floor(y);
  }
  swiper2.previousTranslate = swiper2.translate;
  swiper2.translate = swiper2.isHorizontal() ? x : y;
  if (params.cssMode) {
    wrapperEl[swiper2.isHorizontal() ? "scrollLeft" : "scrollTop"] = swiper2.isHorizontal() ? -x : -y;
  } else if (!params.virtualTranslate) {
    if (swiper2.isHorizontal()) {
      x -= swiper2.cssOverflowAdjustment();
    } else {
      y -= swiper2.cssOverflowAdjustment();
    }
    wrapperEl.style.transform = `translate3d(${x}px, ${y}px, ${z}px)`;
  }
  let newProgress;
  const translatesDiff = swiper2.maxTranslate() - swiper2.minTranslate();
  if (translatesDiff === 0) {
    newProgress = 0;
  } else {
    newProgress = (translate2 - swiper2.minTranslate()) / translatesDiff;
  }
  if (newProgress !== progress) {
    swiper2.updateProgress(translate2);
  }
  swiper2.emit("setTranslate", swiper2.translate, byController);
}
function minTranslate() {
  return -this.snapGrid[0];
}
function maxTranslate() {
  return -this.snapGrid[this.snapGrid.length - 1];
}
function translateTo(translate2, speed, runCallbacks, translateBounds, internal) {
  if (translate2 === void 0) {
    translate2 = 0;
  }
  if (speed === void 0) {
    speed = this.params.speed;
  }
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  if (translateBounds === void 0) {
    translateBounds = true;
  }
  const swiper2 = this;
  const {
    params,
    wrapperEl
  } = swiper2;
  if (swiper2.animating && params.preventInteractionOnTransition) {
    return false;
  }
  const minTranslate2 = swiper2.minTranslate();
  const maxTranslate2 = swiper2.maxTranslate();
  let newTranslate;
  if (translateBounds && translate2 > minTranslate2)
    newTranslate = minTranslate2;
  else if (translateBounds && translate2 < maxTranslate2)
    newTranslate = maxTranslate2;
  else
    newTranslate = translate2;
  swiper2.updateProgress(newTranslate);
  if (params.cssMode) {
    const isH = swiper2.isHorizontal();
    if (speed === 0) {
      wrapperEl[isH ? "scrollLeft" : "scrollTop"] = -newTranslate;
    } else {
      if (!swiper2.support.smoothScroll) {
        animateCSSModeScroll({
          swiper: swiper2,
          targetPosition: -newTranslate,
          side: isH ? "left" : "top"
        });
        return true;
      }
      wrapperEl.scrollTo({
        [isH ? "left" : "top"]: -newTranslate,
        behavior: "smooth"
      });
    }
    return true;
  }
  if (speed === 0) {
    swiper2.setTransition(0);
    swiper2.setTranslate(newTranslate);
    if (runCallbacks) {
      swiper2.emit("beforeTransitionStart", speed, internal);
      swiper2.emit("transitionEnd");
    }
  } else {
    swiper2.setTransition(speed);
    swiper2.setTranslate(newTranslate);
    if (runCallbacks) {
      swiper2.emit("beforeTransitionStart", speed, internal);
      swiper2.emit("transitionStart");
    }
    if (!swiper2.animating) {
      swiper2.animating = true;
      if (!swiper2.onTranslateToWrapperTransitionEnd) {
        swiper2.onTranslateToWrapperTransitionEnd = function transitionEnd2(e) {
          if (!swiper2 || swiper2.destroyed)
            return;
          if (e.target !== this)
            return;
          swiper2.wrapperEl.removeEventListener("transitionend", swiper2.onTranslateToWrapperTransitionEnd);
          swiper2.onTranslateToWrapperTransitionEnd = null;
          delete swiper2.onTranslateToWrapperTransitionEnd;
          swiper2.animating = false;
          if (runCallbacks) {
            swiper2.emit("transitionEnd");
          }
        };
      }
      swiper2.wrapperEl.addEventListener("transitionend", swiper2.onTranslateToWrapperTransitionEnd);
    }
  }
  return true;
}
var translate = {
  getTranslate: getSwiperTranslate,
  setTranslate,
  minTranslate,
  maxTranslate,
  translateTo
};
function setTransition(duration, byController) {
  const swiper2 = this;
  if (!swiper2.params.cssMode) {
    swiper2.wrapperEl.style.transitionDuration = `${duration}ms`;
    swiper2.wrapperEl.style.transitionDelay = duration === 0 ? `0ms` : "";
  }
  swiper2.emit("setTransition", duration, byController);
}
function transitionEmit(_ref) {
  let {
    swiper: swiper2,
    runCallbacks,
    direction,
    step
  } = _ref;
  const {
    activeIndex,
    previousIndex
  } = swiper2;
  let dir = direction;
  if (!dir) {
    if (activeIndex > previousIndex)
      dir = "next";
    else if (activeIndex < previousIndex)
      dir = "prev";
    else
      dir = "reset";
  }
  swiper2.emit(`transition${step}`);
  if (runCallbacks && activeIndex !== previousIndex) {
    if (dir === "reset") {
      swiper2.emit(`slideResetTransition${step}`);
      return;
    }
    swiper2.emit(`slideChangeTransition${step}`);
    if (dir === "next") {
      swiper2.emit(`slideNextTransition${step}`);
    } else {
      swiper2.emit(`slidePrevTransition${step}`);
    }
  }
}
function transitionStart(runCallbacks, direction) {
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  const swiper2 = this;
  const {
    params
  } = swiper2;
  if (params.cssMode)
    return;
  if (params.autoHeight) {
    swiper2.updateAutoHeight();
  }
  transitionEmit({
    swiper: swiper2,
    runCallbacks,
    direction,
    step: "Start"
  });
}
function transitionEnd(runCallbacks, direction) {
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  const swiper2 = this;
  const {
    params
  } = swiper2;
  swiper2.animating = false;
  if (params.cssMode)
    return;
  swiper2.setTransition(0);
  transitionEmit({
    swiper: swiper2,
    runCallbacks,
    direction,
    step: "End"
  });
}
var transition = {
  setTransition,
  transitionStart,
  transitionEnd
};
function slideTo(index, speed, runCallbacks, internal, initial) {
  if (index === void 0) {
    index = 0;
  }
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  if (typeof index === "string") {
    index = parseInt(index, 10);
  }
  const swiper2 = this;
  let slideIndex = index;
  if (slideIndex < 0)
    slideIndex = 0;
  const {
    params,
    snapGrid,
    slidesGrid,
    previousIndex,
    activeIndex,
    rtlTranslate: rtl,
    wrapperEl,
    enabled
  } = swiper2;
  if (!enabled && !internal && !initial || swiper2.destroyed || swiper2.animating && params.preventInteractionOnTransition) {
    return false;
  }
  if (typeof speed === "undefined") {
    speed = swiper2.params.speed;
  }
  const skip = Math.min(swiper2.params.slidesPerGroupSkip, slideIndex);
  let snapIndex = skip + Math.floor((slideIndex - skip) / swiper2.params.slidesPerGroup);
  if (snapIndex >= snapGrid.length)
    snapIndex = snapGrid.length - 1;
  const translate2 = -snapGrid[snapIndex];
  if (params.normalizeSlideIndex) {
    for (let i = 0; i < slidesGrid.length; i += 1) {
      const normalizedTranslate = -Math.floor(translate2 * 100);
      const normalizedGrid = Math.floor(slidesGrid[i] * 100);
      const normalizedGridNext = Math.floor(slidesGrid[i + 1] * 100);
      if (typeof slidesGrid[i + 1] !== "undefined") {
        if (normalizedTranslate >= normalizedGrid && normalizedTranslate < normalizedGridNext - (normalizedGridNext - normalizedGrid) / 2) {
          slideIndex = i;
        } else if (normalizedTranslate >= normalizedGrid && normalizedTranslate < normalizedGridNext) {
          slideIndex = i + 1;
        }
      } else if (normalizedTranslate >= normalizedGrid) {
        slideIndex = i;
      }
    }
  }
  if (swiper2.initialized && slideIndex !== activeIndex) {
    if (!swiper2.allowSlideNext && (rtl ? translate2 > swiper2.translate && translate2 > swiper2.minTranslate() : translate2 < swiper2.translate && translate2 < swiper2.minTranslate())) {
      return false;
    }
    if (!swiper2.allowSlidePrev && translate2 > swiper2.translate && translate2 > swiper2.maxTranslate()) {
      if ((activeIndex || 0) !== slideIndex) {
        return false;
      }
    }
  }
  if (slideIndex !== (previousIndex || 0) && runCallbacks) {
    swiper2.emit("beforeSlideChangeStart");
  }
  swiper2.updateProgress(translate2);
  let direction;
  if (slideIndex > activeIndex)
    direction = "next";
  else if (slideIndex < activeIndex)
    direction = "prev";
  else
    direction = "reset";
  if (rtl && -translate2 === swiper2.translate || !rtl && translate2 === swiper2.translate) {
    swiper2.updateActiveIndex(slideIndex);
    if (params.autoHeight) {
      swiper2.updateAutoHeight();
    }
    swiper2.updateSlidesClasses();
    if (params.effect !== "slide") {
      swiper2.setTranslate(translate2);
    }
    if (direction !== "reset") {
      swiper2.transitionStart(runCallbacks, direction);
      swiper2.transitionEnd(runCallbacks, direction);
    }
    return false;
  }
  if (params.cssMode) {
    const isH = swiper2.isHorizontal();
    const t = rtl ? translate2 : -translate2;
    if (speed === 0) {
      const isVirtual = swiper2.virtual && swiper2.params.virtual.enabled;
      if (isVirtual) {
        swiper2.wrapperEl.style.scrollSnapType = "none";
        swiper2._immediateVirtual = true;
      }
      if (isVirtual && !swiper2._cssModeVirtualInitialSet && swiper2.params.initialSlide > 0) {
        swiper2._cssModeVirtualInitialSet = true;
        requestAnimationFrame(() => {
          wrapperEl[isH ? "scrollLeft" : "scrollTop"] = t;
        });
      } else {
        wrapperEl[isH ? "scrollLeft" : "scrollTop"] = t;
      }
      if (isVirtual) {
        requestAnimationFrame(() => {
          swiper2.wrapperEl.style.scrollSnapType = "";
          swiper2._immediateVirtual = false;
        });
      }
    } else {
      if (!swiper2.support.smoothScroll) {
        animateCSSModeScroll({
          swiper: swiper2,
          targetPosition: t,
          side: isH ? "left" : "top"
        });
        return true;
      }
      wrapperEl.scrollTo({
        [isH ? "left" : "top"]: t,
        behavior: "smooth"
      });
    }
    return true;
  }
  swiper2.setTransition(speed);
  swiper2.setTranslate(translate2);
  swiper2.updateActiveIndex(slideIndex);
  swiper2.updateSlidesClasses();
  swiper2.emit("beforeTransitionStart", speed, internal);
  swiper2.transitionStart(runCallbacks, direction);
  if (speed === 0) {
    swiper2.transitionEnd(runCallbacks, direction);
  } else if (!swiper2.animating) {
    swiper2.animating = true;
    if (!swiper2.onSlideToWrapperTransitionEnd) {
      swiper2.onSlideToWrapperTransitionEnd = function transitionEnd2(e) {
        if (!swiper2 || swiper2.destroyed)
          return;
        if (e.target !== this)
          return;
        swiper2.wrapperEl.removeEventListener("transitionend", swiper2.onSlideToWrapperTransitionEnd);
        swiper2.onSlideToWrapperTransitionEnd = null;
        delete swiper2.onSlideToWrapperTransitionEnd;
        swiper2.transitionEnd(runCallbacks, direction);
      };
    }
    swiper2.wrapperEl.addEventListener("transitionend", swiper2.onSlideToWrapperTransitionEnd);
  }
  return true;
}
function slideToLoop(index, speed, runCallbacks, internal) {
  if (index === void 0) {
    index = 0;
  }
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  if (typeof index === "string") {
    const indexAsNumber = parseInt(index, 10);
    index = indexAsNumber;
  }
  const swiper2 = this;
  if (swiper2.destroyed)
    return;
  if (typeof speed === "undefined") {
    speed = swiper2.params.speed;
  }
  const gridEnabled = swiper2.grid && swiper2.params.grid && swiper2.params.grid.rows > 1;
  let newIndex = index;
  if (swiper2.params.loop) {
    if (swiper2.virtual && swiper2.params.virtual.enabled) {
      newIndex = newIndex + swiper2.virtual.slidesBefore;
    } else {
      let targetSlideIndex;
      if (gridEnabled) {
        const slideIndex = newIndex * swiper2.params.grid.rows;
        targetSlideIndex = swiper2.slides.filter((slideEl) => slideEl.getAttribute("data-swiper-slide-index") * 1 === slideIndex)[0].column;
      } else {
        targetSlideIndex = swiper2.getSlideIndexByData(newIndex);
      }
      const cols = gridEnabled ? Math.ceil(swiper2.slides.length / swiper2.params.grid.rows) : swiper2.slides.length;
      const {
        centeredSlides
      } = swiper2.params;
      let slidesPerView = swiper2.params.slidesPerView;
      if (slidesPerView === "auto") {
        slidesPerView = swiper2.slidesPerViewDynamic();
      } else {
        slidesPerView = Math.ceil(parseFloat(swiper2.params.slidesPerView, 10));
        if (centeredSlides && slidesPerView % 2 === 0) {
          slidesPerView = slidesPerView + 1;
        }
      }
      let needLoopFix = cols - targetSlideIndex < slidesPerView;
      if (centeredSlides) {
        needLoopFix = needLoopFix || targetSlideIndex < Math.ceil(slidesPerView / 2);
      }
      if (internal && centeredSlides && swiper2.params.slidesPerView !== "auto" && !gridEnabled) {
        needLoopFix = false;
      }
      if (needLoopFix) {
        const direction = centeredSlides ? targetSlideIndex < swiper2.activeIndex ? "prev" : "next" : targetSlideIndex - swiper2.activeIndex - 1 < swiper2.params.slidesPerView ? "next" : "prev";
        swiper2.loopFix({
          direction,
          slideTo: true,
          activeSlideIndex: direction === "next" ? targetSlideIndex + 1 : targetSlideIndex - cols + 1,
          slideRealIndex: direction === "next" ? swiper2.realIndex : void 0
        });
      }
      if (gridEnabled) {
        const slideIndex = newIndex * swiper2.params.grid.rows;
        newIndex = swiper2.slides.filter((slideEl) => slideEl.getAttribute("data-swiper-slide-index") * 1 === slideIndex)[0].column;
      } else {
        newIndex = swiper2.getSlideIndexByData(newIndex);
      }
    }
  }
  requestAnimationFrame(() => {
    swiper2.slideTo(newIndex, speed, runCallbacks, internal);
  });
  return swiper2;
}
function slideNext(speed, runCallbacks, internal) {
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  const swiper2 = this;
  const {
    enabled,
    params,
    animating
  } = swiper2;
  if (!enabled || swiper2.destroyed)
    return swiper2;
  if (typeof speed === "undefined") {
    speed = swiper2.params.speed;
  }
  let perGroup = params.slidesPerGroup;
  if (params.slidesPerView === "auto" && params.slidesPerGroup === 1 && params.slidesPerGroupAuto) {
    perGroup = Math.max(swiper2.slidesPerViewDynamic("current", true), 1);
  }
  const increment = swiper2.activeIndex < params.slidesPerGroupSkip ? 1 : perGroup;
  const isVirtual = swiper2.virtual && params.virtual.enabled;
  if (params.loop) {
    if (animating && !isVirtual && params.loopPreventsSliding)
      return false;
    swiper2.loopFix({
      direction: "next"
    });
    swiper2._clientLeft = swiper2.wrapperEl.clientLeft;
    if (swiper2.activeIndex === swiper2.slides.length - 1 && params.cssMode) {
      requestAnimationFrame(() => {
        swiper2.slideTo(swiper2.activeIndex + increment, speed, runCallbacks, internal);
      });
      return true;
    }
  }
  if (params.rewind && swiper2.isEnd) {
    return swiper2.slideTo(0, speed, runCallbacks, internal);
  }
  return swiper2.slideTo(swiper2.activeIndex + increment, speed, runCallbacks, internal);
}
function slidePrev(speed, runCallbacks, internal) {
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  const swiper2 = this;
  const {
    params,
    snapGrid,
    slidesGrid,
    rtlTranslate,
    enabled,
    animating
  } = swiper2;
  if (!enabled || swiper2.destroyed)
    return swiper2;
  if (typeof speed === "undefined") {
    speed = swiper2.params.speed;
  }
  const isVirtual = swiper2.virtual && params.virtual.enabled;
  if (params.loop) {
    if (animating && !isVirtual && params.loopPreventsSliding)
      return false;
    swiper2.loopFix({
      direction: "prev"
    });
    swiper2._clientLeft = swiper2.wrapperEl.clientLeft;
  }
  const translate2 = rtlTranslate ? swiper2.translate : -swiper2.translate;
  function normalize(val) {
    if (val < 0)
      return -Math.floor(Math.abs(val));
    return Math.floor(val);
  }
  const normalizedTranslate = normalize(translate2);
  const normalizedSnapGrid = snapGrid.map((val) => normalize(val));
  let prevSnap = snapGrid[normalizedSnapGrid.indexOf(normalizedTranslate) - 1];
  if (typeof prevSnap === "undefined" && params.cssMode) {
    let prevSnapIndex;
    snapGrid.forEach((snap, snapIndex) => {
      if (normalizedTranslate >= snap) {
        prevSnapIndex = snapIndex;
      }
    });
    if (typeof prevSnapIndex !== "undefined") {
      prevSnap = snapGrid[prevSnapIndex > 0 ? prevSnapIndex - 1 : prevSnapIndex];
    }
  }
  let prevIndex = 0;
  if (typeof prevSnap !== "undefined") {
    prevIndex = slidesGrid.indexOf(prevSnap);
    if (prevIndex < 0)
      prevIndex = swiper2.activeIndex - 1;
    if (params.slidesPerView === "auto" && params.slidesPerGroup === 1 && params.slidesPerGroupAuto) {
      prevIndex = prevIndex - swiper2.slidesPerViewDynamic("previous", true) + 1;
      prevIndex = Math.max(prevIndex, 0);
    }
  }
  if (params.rewind && swiper2.isBeginning) {
    const lastIndex = swiper2.params.virtual && swiper2.params.virtual.enabled && swiper2.virtual ? swiper2.virtual.slides.length - 1 : swiper2.slides.length - 1;
    return swiper2.slideTo(lastIndex, speed, runCallbacks, internal);
  } else if (params.loop && swiper2.activeIndex === 0 && params.cssMode) {
    requestAnimationFrame(() => {
      swiper2.slideTo(prevIndex, speed, runCallbacks, internal);
    });
    return true;
  }
  return swiper2.slideTo(prevIndex, speed, runCallbacks, internal);
}
function slideReset(speed, runCallbacks, internal) {
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  const swiper2 = this;
  if (swiper2.destroyed)
    return;
  if (typeof speed === "undefined") {
    speed = swiper2.params.speed;
  }
  return swiper2.slideTo(swiper2.activeIndex, speed, runCallbacks, internal);
}
function slideToClosest(speed, runCallbacks, internal, threshold) {
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  if (threshold === void 0) {
    threshold = 0.5;
  }
  const swiper2 = this;
  if (swiper2.destroyed)
    return;
  if (typeof speed === "undefined") {
    speed = swiper2.params.speed;
  }
  let index = swiper2.activeIndex;
  const skip = Math.min(swiper2.params.slidesPerGroupSkip, index);
  const snapIndex = skip + Math.floor((index - skip) / swiper2.params.slidesPerGroup);
  const translate2 = swiper2.rtlTranslate ? swiper2.translate : -swiper2.translate;
  if (translate2 >= swiper2.snapGrid[snapIndex]) {
    const currentSnap = swiper2.snapGrid[snapIndex];
    const nextSnap = swiper2.snapGrid[snapIndex + 1];
    if (translate2 - currentSnap > (nextSnap - currentSnap) * threshold) {
      index += swiper2.params.slidesPerGroup;
    }
  } else {
    const prevSnap = swiper2.snapGrid[snapIndex - 1];
    const currentSnap = swiper2.snapGrid[snapIndex];
    if (translate2 - prevSnap <= (currentSnap - prevSnap) * threshold) {
      index -= swiper2.params.slidesPerGroup;
    }
  }
  index = Math.max(index, 0);
  index = Math.min(index, swiper2.slidesGrid.length - 1);
  return swiper2.slideTo(index, speed, runCallbacks, internal);
}
function slideToClickedSlide() {
  const swiper2 = this;
  if (swiper2.destroyed)
    return;
  const {
    params,
    slidesEl
  } = swiper2;
  const slidesPerView = params.slidesPerView === "auto" ? swiper2.slidesPerViewDynamic() : params.slidesPerView;
  let slideToIndex = swiper2.clickedIndex;
  let realIndex;
  const slideSelector = swiper2.isElement ? `swiper-slide` : `.${params.slideClass}`;
  if (params.loop) {
    if (swiper2.animating)
      return;
    realIndex = parseInt(swiper2.clickedSlide.getAttribute("data-swiper-slide-index"), 10);
    if (params.centeredSlides) {
      if (slideToIndex < swiper2.loopedSlides - slidesPerView / 2 || slideToIndex > swiper2.slides.length - swiper2.loopedSlides + slidesPerView / 2) {
        swiper2.loopFix();
        slideToIndex = swiper2.getSlideIndex(elementChildren(slidesEl, `${slideSelector}[data-swiper-slide-index="${realIndex}"]`)[0]);
        nextTick(() => {
          swiper2.slideTo(slideToIndex);
        });
      } else {
        swiper2.slideTo(slideToIndex);
      }
    } else if (slideToIndex > swiper2.slides.length - slidesPerView) {
      swiper2.loopFix();
      slideToIndex = swiper2.getSlideIndex(elementChildren(slidesEl, `${slideSelector}[data-swiper-slide-index="${realIndex}"]`)[0]);
      nextTick(() => {
        swiper2.slideTo(slideToIndex);
      });
    } else {
      swiper2.slideTo(slideToIndex);
    }
  } else {
    swiper2.slideTo(slideToIndex);
  }
}
var slide = {
  slideTo,
  slideToLoop,
  slideNext,
  slidePrev,
  slideReset,
  slideToClosest,
  slideToClickedSlide
};
function loopCreate(slideRealIndex) {
  const swiper2 = this;
  const {
    params,
    slidesEl
  } = swiper2;
  if (!params.loop || swiper2.virtual && swiper2.params.virtual.enabled)
    return;
  const initSlides = () => {
    const slides = elementChildren(slidesEl, `.${params.slideClass}, swiper-slide`);
    slides.forEach((el, index) => {
      el.setAttribute("data-swiper-slide-index", index);
    });
  };
  const gridEnabled = swiper2.grid && params.grid && params.grid.rows > 1;
  const slidesPerGroup = params.slidesPerGroup * (gridEnabled ? params.grid.rows : 1);
  const shouldFillGroup = swiper2.slides.length % slidesPerGroup !== 0;
  const shouldFillGrid = gridEnabled && swiper2.slides.length % params.grid.rows !== 0;
  const addBlankSlides = (amountOfSlides) => {
    for (let i = 0; i < amountOfSlides; i += 1) {
      const slideEl = swiper2.isElement ? createElement("swiper-slide", [params.slideBlankClass]) : createElement("div", [params.slideClass, params.slideBlankClass]);
      swiper2.slidesEl.append(slideEl);
    }
  };
  if (shouldFillGroup) {
    if (params.loopAddBlankSlides) {
      const slidesToAdd = slidesPerGroup - swiper2.slides.length % slidesPerGroup;
      addBlankSlides(slidesToAdd);
      swiper2.recalcSlides();
      swiper2.updateSlides();
    } else {
      showWarning("Swiper Loop Warning: The number of slides is not even to slidesPerGroup, loop mode may not function properly. You need to add more slides (or make duplicates, or empty slides)");
    }
    initSlides();
  } else if (shouldFillGrid) {
    if (params.loopAddBlankSlides) {
      const slidesToAdd = params.grid.rows - swiper2.slides.length % params.grid.rows;
      addBlankSlides(slidesToAdd);
      swiper2.recalcSlides();
      swiper2.updateSlides();
    } else {
      showWarning("Swiper Loop Warning: The number of slides is not even to grid.rows, loop mode may not function properly. You need to add more slides (or make duplicates, or empty slides)");
    }
    initSlides();
  } else {
    initSlides();
  }
  swiper2.loopFix({
    slideRealIndex,
    direction: params.centeredSlides ? void 0 : "next"
  });
}
function loopFix(_temp) {
  let {
    slideRealIndex,
    slideTo: slideTo2 = true,
    direction,
    setTranslate: setTranslate2,
    activeSlideIndex,
    byController,
    byMousewheel
  } = _temp === void 0 ? {} : _temp;
  const swiper2 = this;
  if (!swiper2.params.loop)
    return;
  swiper2.emit("beforeLoopFix");
  const {
    slides,
    allowSlidePrev,
    allowSlideNext,
    slidesEl,
    params
  } = swiper2;
  const {
    centeredSlides
  } = params;
  swiper2.allowSlidePrev = true;
  swiper2.allowSlideNext = true;
  if (swiper2.virtual && params.virtual.enabled) {
    if (slideTo2) {
      if (!params.centeredSlides && swiper2.snapIndex === 0) {
        swiper2.slideTo(swiper2.virtual.slides.length, 0, false, true);
      } else if (params.centeredSlides && swiper2.snapIndex < params.slidesPerView) {
        swiper2.slideTo(swiper2.virtual.slides.length + swiper2.snapIndex, 0, false, true);
      } else if (swiper2.snapIndex === swiper2.snapGrid.length - 1) {
        swiper2.slideTo(swiper2.virtual.slidesBefore, 0, false, true);
      }
    }
    swiper2.allowSlidePrev = allowSlidePrev;
    swiper2.allowSlideNext = allowSlideNext;
    swiper2.emit("loopFix");
    return;
  }
  let slidesPerView = params.slidesPerView;
  if (slidesPerView === "auto") {
    slidesPerView = swiper2.slidesPerViewDynamic();
  } else {
    slidesPerView = Math.ceil(parseFloat(params.slidesPerView, 10));
    if (centeredSlides && slidesPerView % 2 === 0) {
      slidesPerView = slidesPerView + 1;
    }
  }
  const slidesPerGroup = params.slidesPerGroupAuto ? slidesPerView : params.slidesPerGroup;
  let loopedSlides = slidesPerGroup;
  if (loopedSlides % slidesPerGroup !== 0) {
    loopedSlides += slidesPerGroup - loopedSlides % slidesPerGroup;
  }
  loopedSlides += params.loopAdditionalSlides;
  swiper2.loopedSlides = loopedSlides;
  const gridEnabled = swiper2.grid && params.grid && params.grid.rows > 1;
  if (slides.length < slidesPerView + loopedSlides) {
    showWarning("Swiper Loop Warning: The number of slides is not enough for loop mode, it will be disabled and not function properly. You need to add more slides (or make duplicates) or lower the values of slidesPerView and slidesPerGroup parameters");
  } else if (gridEnabled && params.grid.fill === "row") {
    showWarning("Swiper Loop Warning: Loop mode is not compatible with grid.fill = `row`");
  }
  const prependSlidesIndexes = [];
  const appendSlidesIndexes = [];
  let activeIndex = swiper2.activeIndex;
  if (typeof activeSlideIndex === "undefined") {
    activeSlideIndex = swiper2.getSlideIndex(slides.filter((el) => el.classList.contains(params.slideActiveClass))[0]);
  } else {
    activeIndex = activeSlideIndex;
  }
  const isNext = direction === "next" || !direction;
  const isPrev = direction === "prev" || !direction;
  let slidesPrepended = 0;
  let slidesAppended = 0;
  const cols = gridEnabled ? Math.ceil(slides.length / params.grid.rows) : slides.length;
  const activeColIndex = gridEnabled ? slides[activeSlideIndex].column : activeSlideIndex;
  const activeColIndexWithShift = activeColIndex + (centeredSlides && typeof setTranslate2 === "undefined" ? -slidesPerView / 2 + 0.5 : 0);
  if (activeColIndexWithShift < loopedSlides) {
    slidesPrepended = Math.max(loopedSlides - activeColIndexWithShift, slidesPerGroup);
    for (let i = 0; i < loopedSlides - activeColIndexWithShift; i += 1) {
      const index = i - Math.floor(i / cols) * cols;
      if (gridEnabled) {
        const colIndexToPrepend = cols - index - 1;
        for (let i2 = slides.length - 1; i2 >= 0; i2 -= 1) {
          if (slides[i2].column === colIndexToPrepend)
            prependSlidesIndexes.push(i2);
        }
      } else {
        prependSlidesIndexes.push(cols - index - 1);
      }
    }
  } else if (activeColIndexWithShift + slidesPerView > cols - loopedSlides) {
    slidesAppended = Math.max(activeColIndexWithShift - (cols - loopedSlides * 2), slidesPerGroup);
    for (let i = 0; i < slidesAppended; i += 1) {
      const index = i - Math.floor(i / cols) * cols;
      if (gridEnabled) {
        slides.forEach((slide2, slideIndex) => {
          if (slide2.column === index)
            appendSlidesIndexes.push(slideIndex);
        });
      } else {
        appendSlidesIndexes.push(index);
      }
    }
  }
  swiper2.__preventObserver__ = true;
  requestAnimationFrame(() => {
    swiper2.__preventObserver__ = false;
  });
  if (isPrev) {
    prependSlidesIndexes.forEach((index) => {
      slides[index].swiperLoopMoveDOM = true;
      slidesEl.prepend(slides[index]);
      slides[index].swiperLoopMoveDOM = false;
    });
  }
  if (isNext) {
    appendSlidesIndexes.forEach((index) => {
      slides[index].swiperLoopMoveDOM = true;
      slidesEl.append(slides[index]);
      slides[index].swiperLoopMoveDOM = false;
    });
  }
  swiper2.recalcSlides();
  if (params.slidesPerView === "auto") {
    swiper2.updateSlides();
  } else if (gridEnabled && (prependSlidesIndexes.length > 0 && isPrev || appendSlidesIndexes.length > 0 && isNext)) {
    swiper2.slides.forEach((slide2, slideIndex) => {
      swiper2.grid.updateSlide(slideIndex, slide2, swiper2.slides);
    });
  }
  if (params.watchSlidesProgress) {
    swiper2.updateSlidesOffset();
  }
  if (slideTo2) {
    if (prependSlidesIndexes.length > 0 && isPrev) {
      if (typeof slideRealIndex === "undefined") {
        const currentSlideTranslate = swiper2.slidesGrid[activeIndex];
        const newSlideTranslate = swiper2.slidesGrid[activeIndex + slidesPrepended];
        const diff = newSlideTranslate - currentSlideTranslate;
        if (byMousewheel) {
          swiper2.setTranslate(swiper2.translate - diff);
        } else {
          swiper2.slideTo(activeIndex + Math.ceil(slidesPrepended), 0, false, true);
          if (setTranslate2) {
            swiper2.touchEventsData.startTranslate = swiper2.touchEventsData.startTranslate - diff;
            swiper2.touchEventsData.currentTranslate = swiper2.touchEventsData.currentTranslate - diff;
          }
        }
      } else {
        if (setTranslate2) {
          const shift = gridEnabled ? prependSlidesIndexes.length / params.grid.rows : prependSlidesIndexes.length;
          swiper2.slideTo(swiper2.activeIndex + shift, 0, false, true);
          swiper2.touchEventsData.currentTranslate = swiper2.translate;
        }
      }
    } else if (appendSlidesIndexes.length > 0 && isNext) {
      if (typeof slideRealIndex === "undefined") {
        const currentSlideTranslate = swiper2.slidesGrid[activeIndex];
        const newSlideTranslate = swiper2.slidesGrid[activeIndex - slidesAppended];
        const diff = newSlideTranslate - currentSlideTranslate;
        if (byMousewheel) {
          swiper2.setTranslate(swiper2.translate - diff);
        } else {
          swiper2.slideTo(activeIndex - slidesAppended, 0, false, true);
          if (setTranslate2) {
            swiper2.touchEventsData.startTranslate = swiper2.touchEventsData.startTranslate - diff;
            swiper2.touchEventsData.currentTranslate = swiper2.touchEventsData.currentTranslate - diff;
          }
        }
      } else {
        const shift = gridEnabled ? appendSlidesIndexes.length / params.grid.rows : appendSlidesIndexes.length;
        swiper2.slideTo(swiper2.activeIndex - shift, 0, false, true);
      }
    }
  }
  swiper2.allowSlidePrev = allowSlidePrev;
  swiper2.allowSlideNext = allowSlideNext;
  if (swiper2.controller && swiper2.controller.control && !byController) {
    const loopParams = {
      slideRealIndex,
      direction,
      setTranslate: setTranslate2,
      activeSlideIndex,
      byController: true
    };
    if (Array.isArray(swiper2.controller.control)) {
      swiper2.controller.control.forEach((c) => {
        if (!c.destroyed && c.params.loop)
          c.loopFix({
            ...loopParams,
            slideTo: c.params.slidesPerView === params.slidesPerView ? slideTo2 : false
          });
      });
    } else if (swiper2.controller.control instanceof swiper2.constructor && swiper2.controller.control.params.loop) {
      swiper2.controller.control.loopFix({
        ...loopParams,
        slideTo: swiper2.controller.control.params.slidesPerView === params.slidesPerView ? slideTo2 : false
      });
    }
  }
  swiper2.emit("loopFix");
}
function loopDestroy() {
  const swiper2 = this;
  const {
    params,
    slidesEl
  } = swiper2;
  if (!params.loop || swiper2.virtual && swiper2.params.virtual.enabled)
    return;
  swiper2.recalcSlides();
  const newSlidesOrder = [];
  swiper2.slides.forEach((slideEl) => {
    const index = typeof slideEl.swiperSlideIndex === "undefined" ? slideEl.getAttribute("data-swiper-slide-index") * 1 : slideEl.swiperSlideIndex;
    newSlidesOrder[index] = slideEl;
  });
  swiper2.slides.forEach((slideEl) => {
    slideEl.removeAttribute("data-swiper-slide-index");
  });
  newSlidesOrder.forEach((slideEl) => {
    slidesEl.append(slideEl);
  });
  swiper2.recalcSlides();
  swiper2.slideTo(swiper2.realIndex, 0);
}
var loop = {
  loopCreate,
  loopFix,
  loopDestroy
};
function setGrabCursor(moving) {
  const swiper2 = this;
  if (!swiper2.params.simulateTouch || swiper2.params.watchOverflow && swiper2.isLocked || swiper2.params.cssMode)
    return;
  const el = swiper2.params.touchEventsTarget === "container" ? swiper2.el : swiper2.wrapperEl;
  if (swiper2.isElement) {
    swiper2.__preventObserver__ = true;
  }
  el.style.cursor = "move";
  el.style.cursor = moving ? "grabbing" : "grab";
  if (swiper2.isElement) {
    requestAnimationFrame(() => {
      swiper2.__preventObserver__ = false;
    });
  }
}
function unsetGrabCursor() {
  const swiper2 = this;
  if (swiper2.params.watchOverflow && swiper2.isLocked || swiper2.params.cssMode) {
    return;
  }
  if (swiper2.isElement) {
    swiper2.__preventObserver__ = true;
  }
  swiper2[swiper2.params.touchEventsTarget === "container" ? "el" : "wrapperEl"].style.cursor = "";
  if (swiper2.isElement) {
    requestAnimationFrame(() => {
      swiper2.__preventObserver__ = false;
    });
  }
}
var grabCursor = {
  setGrabCursor,
  unsetGrabCursor
};
function closestElement(selector, base) {
  if (base === void 0) {
    base = this;
  }
  function __closestFrom(el) {
    if (!el || el === getDocument() || el === getWindow())
      return null;
    if (el.assignedSlot)
      el = el.assignedSlot;
    const found = el.closest(selector);
    if (!found && !el.getRootNode) {
      return null;
    }
    return found || __closestFrom(el.getRootNode().host);
  }
  return __closestFrom(base);
}
function preventEdgeSwipe(swiper2, event, startX) {
  const window2 = getWindow();
  const {
    params
  } = swiper2;
  const edgeSwipeDetection = params.edgeSwipeDetection;
  const edgeSwipeThreshold = params.edgeSwipeThreshold;
  if (edgeSwipeDetection && (startX <= edgeSwipeThreshold || startX >= window2.innerWidth - edgeSwipeThreshold)) {
    if (edgeSwipeDetection === "prevent") {
      event.preventDefault();
      return true;
    }
    return false;
  }
  return true;
}
function onTouchStart(event) {
  const swiper2 = this;
  const document2 = getDocument();
  let e = event;
  if (e.originalEvent)
    e = e.originalEvent;
  const data = swiper2.touchEventsData;
  if (e.type === "pointerdown") {
    if (data.pointerId !== null && data.pointerId !== e.pointerId) {
      return;
    }
    data.pointerId = e.pointerId;
  } else if (e.type === "touchstart" && e.targetTouches.length === 1) {
    data.touchId = e.targetTouches[0].identifier;
  }
  if (e.type === "touchstart") {
    preventEdgeSwipe(swiper2, e, e.targetTouches[0].pageX);
    return;
  }
  const {
    params,
    touches,
    enabled
  } = swiper2;
  if (!enabled)
    return;
  if (!params.simulateTouch && e.pointerType === "mouse")
    return;
  if (swiper2.animating && params.preventInteractionOnTransition) {
    return;
  }
  if (!swiper2.animating && params.cssMode && params.loop) {
    swiper2.loopFix();
  }
  let targetEl = e.target;
  if (params.touchEventsTarget === "wrapper") {
    if (!swiper2.wrapperEl.contains(targetEl))
      return;
  }
  if ("which" in e && e.which === 3)
    return;
  if ("button" in e && e.button > 0)
    return;
  if (data.isTouched && data.isMoved)
    return;
  const swipingClassHasValue = !!params.noSwipingClass && params.noSwipingClass !== "";
  const eventPath = e.composedPath ? e.composedPath() : e.path;
  if (swipingClassHasValue && e.target && e.target.shadowRoot && eventPath) {
    targetEl = eventPath[0];
  }
  const noSwipingSelector = params.noSwipingSelector ? params.noSwipingSelector : `.${params.noSwipingClass}`;
  const isTargetShadow = !!(e.target && e.target.shadowRoot);
  if (params.noSwiping && (isTargetShadow ? closestElement(noSwipingSelector, targetEl) : targetEl.closest(noSwipingSelector))) {
    swiper2.allowClick = true;
    return;
  }
  if (params.swipeHandler) {
    if (!targetEl.closest(params.swipeHandler))
      return;
  }
  touches.currentX = e.pageX;
  touches.currentY = e.pageY;
  const startX = touches.currentX;
  const startY = touches.currentY;
  if (!preventEdgeSwipe(swiper2, e, startX)) {
    return;
  }
  Object.assign(data, {
    isTouched: true,
    isMoved: false,
    allowTouchCallbacks: true,
    isScrolling: void 0,
    startMoving: void 0
  });
  touches.startX = startX;
  touches.startY = startY;
  data.touchStartTime = now();
  swiper2.allowClick = true;
  swiper2.updateSize();
  swiper2.swipeDirection = void 0;
  if (params.threshold > 0)
    data.allowThresholdMove = false;
  let preventDefault = true;
  if (targetEl.matches(data.focusableElements)) {
    preventDefault = false;
    if (targetEl.nodeName === "SELECT") {
      data.isTouched = false;
    }
  }
  if (document2.activeElement && document2.activeElement.matches(data.focusableElements) && document2.activeElement !== targetEl) {
    document2.activeElement.blur();
  }
  const shouldPreventDefault = preventDefault && swiper2.allowTouchMove && params.touchStartPreventDefault;
  if ((params.touchStartForcePreventDefault || shouldPreventDefault) && !targetEl.isContentEditable) {
    e.preventDefault();
  }
  if (params.freeMode && params.freeMode.enabled && swiper2.freeMode && swiper2.animating && !params.cssMode) {
    swiper2.freeMode.onTouchStart();
  }
  swiper2.emit("touchStart", e);
}
function onTouchMove(event) {
  const document2 = getDocument();
  const swiper2 = this;
  const data = swiper2.touchEventsData;
  const {
    params,
    touches,
    rtlTranslate: rtl,
    enabled
  } = swiper2;
  if (!enabled)
    return;
  if (!params.simulateTouch && event.pointerType === "mouse")
    return;
  let e = event;
  if (e.originalEvent)
    e = e.originalEvent;
  if (e.type === "pointermove") {
    if (data.touchId !== null)
      return;
    const id = e.pointerId;
    if (id !== data.pointerId)
      return;
  }
  let targetTouch;
  if (e.type === "touchmove") {
    targetTouch = [...e.changedTouches].filter((t) => t.identifier === data.touchId)[0];
    if (!targetTouch || targetTouch.identifier !== data.touchId)
      return;
  } else {
    targetTouch = e;
  }
  if (!data.isTouched) {
    if (data.startMoving && data.isScrolling) {
      swiper2.emit("touchMoveOpposite", e);
    }
    return;
  }
  const pageX = targetTouch.pageX;
  const pageY = targetTouch.pageY;
  if (e.preventedByNestedSwiper) {
    touches.startX = pageX;
    touches.startY = pageY;
    return;
  }
  if (!swiper2.allowTouchMove) {
    if (!e.target.matches(data.focusableElements)) {
      swiper2.allowClick = false;
    }
    if (data.isTouched) {
      Object.assign(touches, {
        startX: pageX,
        startY: pageY,
        currentX: pageX,
        currentY: pageY
      });
      data.touchStartTime = now();
    }
    return;
  }
  if (params.touchReleaseOnEdges && !params.loop) {
    if (swiper2.isVertical()) {
      if (pageY < touches.startY && swiper2.translate <= swiper2.maxTranslate() || pageY > touches.startY && swiper2.translate >= swiper2.minTranslate()) {
        data.isTouched = false;
        data.isMoved = false;
        return;
      }
    } else if (pageX < touches.startX && swiper2.translate <= swiper2.maxTranslate() || pageX > touches.startX && swiper2.translate >= swiper2.minTranslate()) {
      return;
    }
  }
  if (document2.activeElement) {
    if (e.target === document2.activeElement && e.target.matches(data.focusableElements)) {
      data.isMoved = true;
      swiper2.allowClick = false;
      return;
    }
  }
  if (data.allowTouchCallbacks) {
    swiper2.emit("touchMove", e);
  }
  touches.previousX = touches.currentX;
  touches.previousY = touches.currentY;
  touches.currentX = pageX;
  touches.currentY = pageY;
  const diffX = touches.currentX - touches.startX;
  const diffY = touches.currentY - touches.startY;
  if (swiper2.params.threshold && Math.sqrt(diffX ** 2 + diffY ** 2) < swiper2.params.threshold)
    return;
  if (typeof data.isScrolling === "undefined") {
    let touchAngle;
    if (swiper2.isHorizontal() && touches.currentY === touches.startY || swiper2.isVertical() && touches.currentX === touches.startX) {
      data.isScrolling = false;
    } else {
      if (diffX * diffX + diffY * diffY >= 25) {
        touchAngle = Math.atan2(Math.abs(diffY), Math.abs(diffX)) * 180 / Math.PI;
        data.isScrolling = swiper2.isHorizontal() ? touchAngle > params.touchAngle : 90 - touchAngle > params.touchAngle;
      }
    }
  }
  if (data.isScrolling) {
    swiper2.emit("touchMoveOpposite", e);
  }
  if (typeof data.startMoving === "undefined") {
    if (touches.currentX !== touches.startX || touches.currentY !== touches.startY) {
      data.startMoving = true;
    }
  }
  if (data.isScrolling || e.type === "touchmove" && data.preventTouchMoveFromPointerMove) {
    data.isTouched = false;
    return;
  }
  if (!data.startMoving) {
    return;
  }
  swiper2.allowClick = false;
  if (!params.cssMode && e.cancelable) {
    e.preventDefault();
  }
  if (params.touchMoveStopPropagation && !params.nested) {
    e.stopPropagation();
  }
  let diff = swiper2.isHorizontal() ? diffX : diffY;
  let touchesDiff = swiper2.isHorizontal() ? touches.currentX - touches.previousX : touches.currentY - touches.previousY;
  if (params.oneWayMovement) {
    diff = Math.abs(diff) * (rtl ? 1 : -1);
    touchesDiff = Math.abs(touchesDiff) * (rtl ? 1 : -1);
  }
  touches.diff = diff;
  diff *= params.touchRatio;
  if (rtl) {
    diff = -diff;
    touchesDiff = -touchesDiff;
  }
  const prevTouchesDirection = swiper2.touchesDirection;
  swiper2.swipeDirection = diff > 0 ? "prev" : "next";
  swiper2.touchesDirection = touchesDiff > 0 ? "prev" : "next";
  const isLoop = swiper2.params.loop && !params.cssMode;
  const allowLoopFix = swiper2.touchesDirection === "next" && swiper2.allowSlideNext || swiper2.touchesDirection === "prev" && swiper2.allowSlidePrev;
  if (!data.isMoved) {
    if (isLoop && allowLoopFix) {
      swiper2.loopFix({
        direction: swiper2.swipeDirection
      });
    }
    data.startTranslate = swiper2.getTranslate();
    swiper2.setTransition(0);
    if (swiper2.animating) {
      const evt = new window.CustomEvent("transitionend", {
        bubbles: true,
        cancelable: true
      });
      swiper2.wrapperEl.dispatchEvent(evt);
    }
    data.allowMomentumBounce = false;
    if (params.grabCursor && (swiper2.allowSlideNext === true || swiper2.allowSlidePrev === true)) {
      swiper2.setGrabCursor(true);
    }
    swiper2.emit("sliderFirstMove", e);
  }
  let loopFixed;
  (/* @__PURE__ */ new Date()).getTime();
  if (data.isMoved && data.allowThresholdMove && prevTouchesDirection !== swiper2.touchesDirection && isLoop && allowLoopFix && Math.abs(diff) >= 1) {
    Object.assign(touches, {
      startX: pageX,
      startY: pageY,
      currentX: pageX,
      currentY: pageY,
      startTranslate: data.currentTranslate
    });
    data.loopSwapReset = true;
    data.startTranslate = data.currentTranslate;
    return;
  }
  swiper2.emit("sliderMove", e);
  data.isMoved = true;
  data.currentTranslate = diff + data.startTranslate;
  let disableParentSwiper = true;
  let resistanceRatio = params.resistanceRatio;
  if (params.touchReleaseOnEdges) {
    resistanceRatio = 0;
  }
  if (diff > 0) {
    if (isLoop && allowLoopFix && !loopFixed && data.allowThresholdMove && data.currentTranslate > (params.centeredSlides ? swiper2.minTranslate() - swiper2.slidesSizesGrid[swiper2.activeIndex + 1] : swiper2.minTranslate())) {
      swiper2.loopFix({
        direction: "prev",
        setTranslate: true,
        activeSlideIndex: 0
      });
    }
    if (data.currentTranslate > swiper2.minTranslate()) {
      disableParentSwiper = false;
      if (params.resistance) {
        data.currentTranslate = swiper2.minTranslate() - 1 + (-swiper2.minTranslate() + data.startTranslate + diff) ** resistanceRatio;
      }
    }
  } else if (diff < 0) {
    if (isLoop && allowLoopFix && !loopFixed && data.allowThresholdMove && data.currentTranslate < (params.centeredSlides ? swiper2.maxTranslate() + swiper2.slidesSizesGrid[swiper2.slidesSizesGrid.length - 1] : swiper2.maxTranslate())) {
      swiper2.loopFix({
        direction: "next",
        setTranslate: true,
        activeSlideIndex: swiper2.slides.length - (params.slidesPerView === "auto" ? swiper2.slidesPerViewDynamic() : Math.ceil(parseFloat(params.slidesPerView, 10)))
      });
    }
    if (data.currentTranslate < swiper2.maxTranslate()) {
      disableParentSwiper = false;
      if (params.resistance) {
        data.currentTranslate = swiper2.maxTranslate() + 1 - (swiper2.maxTranslate() - data.startTranslate - diff) ** resistanceRatio;
      }
    }
  }
  if (disableParentSwiper) {
    e.preventedByNestedSwiper = true;
  }
  if (!swiper2.allowSlideNext && swiper2.swipeDirection === "next" && data.currentTranslate < data.startTranslate) {
    data.currentTranslate = data.startTranslate;
  }
  if (!swiper2.allowSlidePrev && swiper2.swipeDirection === "prev" && data.currentTranslate > data.startTranslate) {
    data.currentTranslate = data.startTranslate;
  }
  if (!swiper2.allowSlidePrev && !swiper2.allowSlideNext) {
    data.currentTranslate = data.startTranslate;
  }
  if (params.threshold > 0) {
    if (Math.abs(diff) > params.threshold || data.allowThresholdMove) {
      if (!data.allowThresholdMove) {
        data.allowThresholdMove = true;
        touches.startX = touches.currentX;
        touches.startY = touches.currentY;
        data.currentTranslate = data.startTranslate;
        touches.diff = swiper2.isHorizontal() ? touches.currentX - touches.startX : touches.currentY - touches.startY;
        return;
      }
    } else {
      data.currentTranslate = data.startTranslate;
      return;
    }
  }
  if (!params.followFinger || params.cssMode)
    return;
  if (params.freeMode && params.freeMode.enabled && swiper2.freeMode || params.watchSlidesProgress) {
    swiper2.updateActiveIndex();
    swiper2.updateSlidesClasses();
  }
  if (params.freeMode && params.freeMode.enabled && swiper2.freeMode) {
    swiper2.freeMode.onTouchMove();
  }
  swiper2.updateProgress(data.currentTranslate);
  swiper2.setTranslate(data.currentTranslate);
}
function onTouchEnd(event) {
  const swiper2 = this;
  const data = swiper2.touchEventsData;
  let e = event;
  if (e.originalEvent)
    e = e.originalEvent;
  let targetTouch;
  const isTouchEvent = e.type === "touchend" || e.type === "touchcancel";
  if (!isTouchEvent) {
    if (data.touchId !== null)
      return;
    if (e.pointerId !== data.pointerId)
      return;
    targetTouch = e;
  } else {
    targetTouch = [...e.changedTouches].filter((t) => t.identifier === data.touchId)[0];
    if (!targetTouch || targetTouch.identifier !== data.touchId)
      return;
  }
  if (["pointercancel", "pointerout", "pointerleave", "contextmenu"].includes(e.type)) {
    const proceed = ["pointercancel", "contextmenu"].includes(e.type) && (swiper2.browser.isSafari || swiper2.browser.isWebView);
    if (!proceed) {
      return;
    }
  }
  data.pointerId = null;
  data.touchId = null;
  const {
    params,
    touches,
    rtlTranslate: rtl,
    slidesGrid,
    enabled
  } = swiper2;
  if (!enabled)
    return;
  if (!params.simulateTouch && e.pointerType === "mouse")
    return;
  if (data.allowTouchCallbacks) {
    swiper2.emit("touchEnd", e);
  }
  data.allowTouchCallbacks = false;
  if (!data.isTouched) {
    if (data.isMoved && params.grabCursor) {
      swiper2.setGrabCursor(false);
    }
    data.isMoved = false;
    data.startMoving = false;
    return;
  }
  if (params.grabCursor && data.isMoved && data.isTouched && (swiper2.allowSlideNext === true || swiper2.allowSlidePrev === true)) {
    swiper2.setGrabCursor(false);
  }
  const touchEndTime = now();
  const timeDiff = touchEndTime - data.touchStartTime;
  if (swiper2.allowClick) {
    const pathTree = e.path || e.composedPath && e.composedPath();
    swiper2.updateClickedSlide(pathTree && pathTree[0] || e.target, pathTree);
    swiper2.emit("tap click", e);
    if (timeDiff < 300 && touchEndTime - data.lastClickTime < 300) {
      swiper2.emit("doubleTap doubleClick", e);
    }
  }
  data.lastClickTime = now();
  nextTick(() => {
    if (!swiper2.destroyed)
      swiper2.allowClick = true;
  });
  if (!data.isTouched || !data.isMoved || !swiper2.swipeDirection || touches.diff === 0 && !data.loopSwapReset || data.currentTranslate === data.startTranslate && !data.loopSwapReset) {
    data.isTouched = false;
    data.isMoved = false;
    data.startMoving = false;
    return;
  }
  data.isTouched = false;
  data.isMoved = false;
  data.startMoving = false;
  let currentPos;
  if (params.followFinger) {
    currentPos = rtl ? swiper2.translate : -swiper2.translate;
  } else {
    currentPos = -data.currentTranslate;
  }
  if (params.cssMode) {
    return;
  }
  if (params.freeMode && params.freeMode.enabled) {
    swiper2.freeMode.onTouchEnd({
      currentPos
    });
    return;
  }
  const swipeToLast = currentPos >= -swiper2.maxTranslate() && !swiper2.params.loop;
  let stopIndex = 0;
  let groupSize = swiper2.slidesSizesGrid[0];
  for (let i = 0; i < slidesGrid.length; i += i < params.slidesPerGroupSkip ? 1 : params.slidesPerGroup) {
    const increment2 = i < params.slidesPerGroupSkip - 1 ? 1 : params.slidesPerGroup;
    if (typeof slidesGrid[i + increment2] !== "undefined") {
      if (swipeToLast || currentPos >= slidesGrid[i] && currentPos < slidesGrid[i + increment2]) {
        stopIndex = i;
        groupSize = slidesGrid[i + increment2] - slidesGrid[i];
      }
    } else if (swipeToLast || currentPos >= slidesGrid[i]) {
      stopIndex = i;
      groupSize = slidesGrid[slidesGrid.length - 1] - slidesGrid[slidesGrid.length - 2];
    }
  }
  let rewindFirstIndex = null;
  let rewindLastIndex = null;
  if (params.rewind) {
    if (swiper2.isBeginning) {
      rewindLastIndex = params.virtual && params.virtual.enabled && swiper2.virtual ? swiper2.virtual.slides.length - 1 : swiper2.slides.length - 1;
    } else if (swiper2.isEnd) {
      rewindFirstIndex = 0;
    }
  }
  const ratio = (currentPos - slidesGrid[stopIndex]) / groupSize;
  const increment = stopIndex < params.slidesPerGroupSkip - 1 ? 1 : params.slidesPerGroup;
  if (timeDiff > params.longSwipesMs) {
    if (!params.longSwipes) {
      swiper2.slideTo(swiper2.activeIndex);
      return;
    }
    if (swiper2.swipeDirection === "next") {
      if (ratio >= params.longSwipesRatio)
        swiper2.slideTo(params.rewind && swiper2.isEnd ? rewindFirstIndex : stopIndex + increment);
      else
        swiper2.slideTo(stopIndex);
    }
    if (swiper2.swipeDirection === "prev") {
      if (ratio > 1 - params.longSwipesRatio) {
        swiper2.slideTo(stopIndex + increment);
      } else if (rewindLastIndex !== null && ratio < 0 && Math.abs(ratio) > params.longSwipesRatio) {
        swiper2.slideTo(rewindLastIndex);
      } else {
        swiper2.slideTo(stopIndex);
      }
    }
  } else {
    if (!params.shortSwipes) {
      swiper2.slideTo(swiper2.activeIndex);
      return;
    }
    const isNavButtonTarget = swiper2.navigation && (e.target === swiper2.navigation.nextEl || e.target === swiper2.navigation.prevEl);
    if (!isNavButtonTarget) {
      if (swiper2.swipeDirection === "next") {
        swiper2.slideTo(rewindFirstIndex !== null ? rewindFirstIndex : stopIndex + increment);
      }
      if (swiper2.swipeDirection === "prev") {
        swiper2.slideTo(rewindLastIndex !== null ? rewindLastIndex : stopIndex);
      }
    } else if (e.target === swiper2.navigation.nextEl) {
      swiper2.slideTo(stopIndex + increment);
    } else {
      swiper2.slideTo(stopIndex);
    }
  }
}
function onResize() {
  const swiper2 = this;
  const {
    params,
    el
  } = swiper2;
  if (el && el.offsetWidth === 0)
    return;
  if (params.breakpoints) {
    swiper2.setBreakpoint();
  }
  const {
    allowSlideNext,
    allowSlidePrev,
    snapGrid
  } = swiper2;
  const isVirtual = swiper2.virtual && swiper2.params.virtual.enabled;
  swiper2.allowSlideNext = true;
  swiper2.allowSlidePrev = true;
  swiper2.updateSize();
  swiper2.updateSlides();
  swiper2.updateSlidesClasses();
  const isVirtualLoop = isVirtual && params.loop;
  if ((params.slidesPerView === "auto" || params.slidesPerView > 1) && swiper2.isEnd && !swiper2.isBeginning && !swiper2.params.centeredSlides && !isVirtualLoop) {
    swiper2.slideTo(swiper2.slides.length - 1, 0, false, true);
  } else {
    if (swiper2.params.loop && !isVirtual) {
      swiper2.slideToLoop(swiper2.realIndex, 0, false, true);
    } else {
      swiper2.slideTo(swiper2.activeIndex, 0, false, true);
    }
  }
  if (swiper2.autoplay && swiper2.autoplay.running && swiper2.autoplay.paused) {
    clearTimeout(swiper2.autoplay.resizeTimeout);
    swiper2.autoplay.resizeTimeout = setTimeout(() => {
      if (swiper2.autoplay && swiper2.autoplay.running && swiper2.autoplay.paused) {
        swiper2.autoplay.resume();
      }
    }, 500);
  }
  swiper2.allowSlidePrev = allowSlidePrev;
  swiper2.allowSlideNext = allowSlideNext;
  if (swiper2.params.watchOverflow && snapGrid !== swiper2.snapGrid) {
    swiper2.checkOverflow();
  }
}
function onClick(e) {
  const swiper2 = this;
  if (!swiper2.enabled)
    return;
  if (!swiper2.allowClick) {
    if (swiper2.params.preventClicks)
      e.preventDefault();
    if (swiper2.params.preventClicksPropagation && swiper2.animating) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
  }
}
function onScroll() {
  const swiper2 = this;
  const {
    wrapperEl,
    rtlTranslate,
    enabled
  } = swiper2;
  if (!enabled)
    return;
  swiper2.previousTranslate = swiper2.translate;
  if (swiper2.isHorizontal()) {
    swiper2.translate = -wrapperEl.scrollLeft;
  } else {
    swiper2.translate = -wrapperEl.scrollTop;
  }
  if (swiper2.translate === 0)
    swiper2.translate = 0;
  swiper2.updateActiveIndex();
  swiper2.updateSlidesClasses();
  let newProgress;
  const translatesDiff = swiper2.maxTranslate() - swiper2.minTranslate();
  if (translatesDiff === 0) {
    newProgress = 0;
  } else {
    newProgress = (swiper2.translate - swiper2.minTranslate()) / translatesDiff;
  }
  if (newProgress !== swiper2.progress) {
    swiper2.updateProgress(rtlTranslate ? -swiper2.translate : swiper2.translate);
  }
  swiper2.emit("setTranslate", swiper2.translate, false);
}
function onLoad(e) {
  const swiper2 = this;
  processLazyPreloader(swiper2, e.target);
  if (swiper2.params.cssMode || swiper2.params.slidesPerView !== "auto" && !swiper2.params.autoHeight) {
    return;
  }
  swiper2.update();
}
function onDocumentTouchStart() {
  const swiper2 = this;
  if (swiper2.documentTouchHandlerProceeded)
    return;
  swiper2.documentTouchHandlerProceeded = true;
  if (swiper2.params.touchReleaseOnEdges) {
    swiper2.el.style.touchAction = "auto";
  }
}
const events = (swiper2, method) => {
  const document2 = getDocument();
  const {
    params,
    el,
    wrapperEl,
    device
  } = swiper2;
  const capture = !!params.nested;
  const domMethod = method === "on" ? "addEventListener" : "removeEventListener";
  const swiperMethod = method;
  document2[domMethod]("touchstart", swiper2.onDocumentTouchStart, {
    passive: false,
    capture
  });
  el[domMethod]("touchstart", swiper2.onTouchStart, {
    passive: false
  });
  el[domMethod]("pointerdown", swiper2.onTouchStart, {
    passive: false
  });
  document2[domMethod]("touchmove", swiper2.onTouchMove, {
    passive: false,
    capture
  });
  document2[domMethod]("pointermove", swiper2.onTouchMove, {
    passive: false,
    capture
  });
  document2[domMethod]("touchend", swiper2.onTouchEnd, {
    passive: true
  });
  document2[domMethod]("pointerup", swiper2.onTouchEnd, {
    passive: true
  });
  document2[domMethod]("pointercancel", swiper2.onTouchEnd, {
    passive: true
  });
  document2[domMethod]("touchcancel", swiper2.onTouchEnd, {
    passive: true
  });
  document2[domMethod]("pointerout", swiper2.onTouchEnd, {
    passive: true
  });
  document2[domMethod]("pointerleave", swiper2.onTouchEnd, {
    passive: true
  });
  document2[domMethod]("contextmenu", swiper2.onTouchEnd, {
    passive: true
  });
  if (params.preventClicks || params.preventClicksPropagation) {
    el[domMethod]("click", swiper2.onClick, true);
  }
  if (params.cssMode) {
    wrapperEl[domMethod]("scroll", swiper2.onScroll);
  }
  if (params.updateOnWindowResize) {
    swiper2[swiperMethod](device.ios || device.android ? "resize orientationchange observerUpdate" : "resize observerUpdate", onResize, true);
  } else {
    swiper2[swiperMethod]("observerUpdate", onResize, true);
  }
  el[domMethod]("load", swiper2.onLoad, {
    capture: true
  });
};
function attachEvents() {
  const swiper2 = this;
  const {
    params
  } = swiper2;
  swiper2.onTouchStart = onTouchStart.bind(swiper2);
  swiper2.onTouchMove = onTouchMove.bind(swiper2);
  swiper2.onTouchEnd = onTouchEnd.bind(swiper2);
  swiper2.onDocumentTouchStart = onDocumentTouchStart.bind(swiper2);
  if (params.cssMode) {
    swiper2.onScroll = onScroll.bind(swiper2);
  }
  swiper2.onClick = onClick.bind(swiper2);
  swiper2.onLoad = onLoad.bind(swiper2);
  events(swiper2, "on");
}
function detachEvents() {
  const swiper2 = this;
  events(swiper2, "off");
}
var events$1 = {
  attachEvents,
  detachEvents
};
const isGridEnabled = (swiper2, params) => {
  return swiper2.grid && params.grid && params.grid.rows > 1;
};
function setBreakpoint() {
  const swiper2 = this;
  const {
    realIndex,
    initialized,
    params,
    el
  } = swiper2;
  const breakpoints2 = params.breakpoints;
  if (!breakpoints2 || breakpoints2 && Object.keys(breakpoints2).length === 0)
    return;
  const breakpoint = swiper2.getBreakpoint(breakpoints2, swiper2.params.breakpointsBase, swiper2.el);
  if (!breakpoint || swiper2.currentBreakpoint === breakpoint)
    return;
  const breakpointOnlyParams = breakpoint in breakpoints2 ? breakpoints2[breakpoint] : void 0;
  const breakpointParams = breakpointOnlyParams || swiper2.originalParams;
  const wasMultiRow = isGridEnabled(swiper2, params);
  const isMultiRow = isGridEnabled(swiper2, breakpointParams);
  const wasGrabCursor = swiper2.params.grabCursor;
  const isGrabCursor = breakpointParams.grabCursor;
  const wasEnabled = params.enabled;
  if (wasMultiRow && !isMultiRow) {
    el.classList.remove(`${params.containerModifierClass}grid`, `${params.containerModifierClass}grid-column`);
    swiper2.emitContainerClasses();
  } else if (!wasMultiRow && isMultiRow) {
    el.classList.add(`${params.containerModifierClass}grid`);
    if (breakpointParams.grid.fill && breakpointParams.grid.fill === "column" || !breakpointParams.grid.fill && params.grid.fill === "column") {
      el.classList.add(`${params.containerModifierClass}grid-column`);
    }
    swiper2.emitContainerClasses();
  }
  if (wasGrabCursor && !isGrabCursor) {
    swiper2.unsetGrabCursor();
  } else if (!wasGrabCursor && isGrabCursor) {
    swiper2.setGrabCursor();
  }
  ["navigation", "pagination", "scrollbar"].forEach((prop) => {
    if (typeof breakpointParams[prop] === "undefined")
      return;
    const wasModuleEnabled = params[prop] && params[prop].enabled;
    const isModuleEnabled = breakpointParams[prop] && breakpointParams[prop].enabled;
    if (wasModuleEnabled && !isModuleEnabled) {
      swiper2[prop].disable();
    }
    if (!wasModuleEnabled && isModuleEnabled) {
      swiper2[prop].enable();
    }
  });
  const directionChanged = breakpointParams.direction && breakpointParams.direction !== params.direction;
  const needsReLoop = params.loop && (breakpointParams.slidesPerView !== params.slidesPerView || directionChanged);
  const wasLoop = params.loop;
  if (directionChanged && initialized) {
    swiper2.changeDirection();
  }
  extend(swiper2.params, breakpointParams);
  const isEnabled = swiper2.params.enabled;
  const hasLoop = swiper2.params.loop;
  Object.assign(swiper2, {
    allowTouchMove: swiper2.params.allowTouchMove,
    allowSlideNext: swiper2.params.allowSlideNext,
    allowSlidePrev: swiper2.params.allowSlidePrev
  });
  if (wasEnabled && !isEnabled) {
    swiper2.disable();
  } else if (!wasEnabled && isEnabled) {
    swiper2.enable();
  }
  swiper2.currentBreakpoint = breakpoint;
  swiper2.emit("_beforeBreakpoint", breakpointParams);
  if (initialized) {
    if (needsReLoop) {
      swiper2.loopDestroy();
      swiper2.loopCreate(realIndex);
      swiper2.updateSlides();
    } else if (!wasLoop && hasLoop) {
      swiper2.loopCreate(realIndex);
      swiper2.updateSlides();
    } else if (wasLoop && !hasLoop) {
      swiper2.loopDestroy();
    }
  }
  swiper2.emit("breakpoint", breakpointParams);
}
function getBreakpoint(breakpoints2, base, containerEl) {
  if (base === void 0) {
    base = "window";
  }
  if (!breakpoints2 || base === "container" && !containerEl)
    return void 0;
  let breakpoint = false;
  const window2 = getWindow();
  const currentHeight = base === "window" ? window2.innerHeight : containerEl.clientHeight;
  const points = Object.keys(breakpoints2).map((point) => {
    if (typeof point === "string" && point.indexOf("@") === 0) {
      const minRatio = parseFloat(point.substr(1));
      const value = currentHeight * minRatio;
      return {
        value,
        point
      };
    }
    return {
      value: point,
      point
    };
  });
  points.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
  for (let i = 0; i < points.length; i += 1) {
    const {
      point,
      value
    } = points[i];
    if (base === "window") {
      if (window2.matchMedia(`(min-width: ${value}px)`).matches) {
        breakpoint = point;
      }
    } else if (value <= containerEl.clientWidth) {
      breakpoint = point;
    }
  }
  return breakpoint || "max";
}
var breakpoints = {
  setBreakpoint,
  getBreakpoint
};
function prepareClasses(entries, prefix) {
  const resultClasses = [];
  entries.forEach((item) => {
    if (typeof item === "object") {
      Object.keys(item).forEach((classNames) => {
        if (item[classNames]) {
          resultClasses.push(prefix + classNames);
        }
      });
    } else if (typeof item === "string") {
      resultClasses.push(prefix + item);
    }
  });
  return resultClasses;
}
function addClasses() {
  const swiper2 = this;
  const {
    classNames,
    params,
    rtl,
    el,
    device
  } = swiper2;
  const suffixes = prepareClasses(["initialized", params.direction, {
    "free-mode": swiper2.params.freeMode && params.freeMode.enabled
  }, {
    "autoheight": params.autoHeight
  }, {
    "rtl": rtl
  }, {
    "grid": params.grid && params.grid.rows > 1
  }, {
    "grid-column": params.grid && params.grid.rows > 1 && params.grid.fill === "column"
  }, {
    "android": device.android
  }, {
    "ios": device.ios
  }, {
    "css-mode": params.cssMode
  }, {
    "centered": params.cssMode && params.centeredSlides
  }, {
    "watch-progress": params.watchSlidesProgress
  }], params.containerModifierClass);
  classNames.push(...suffixes);
  el.classList.add(...classNames);
  swiper2.emitContainerClasses();
}
function removeClasses() {
  const swiper2 = this;
  const {
    el,
    classNames
  } = swiper2;
  el.classList.remove(...classNames);
  swiper2.emitContainerClasses();
}
var classes = {
  addClasses,
  removeClasses
};
function checkOverflow() {
  const swiper2 = this;
  const {
    isLocked: wasLocked,
    params
  } = swiper2;
  const {
    slidesOffsetBefore
  } = params;
  if (slidesOffsetBefore) {
    const lastSlideIndex = swiper2.slides.length - 1;
    const lastSlideRightEdge = swiper2.slidesGrid[lastSlideIndex] + swiper2.slidesSizesGrid[lastSlideIndex] + slidesOffsetBefore * 2;
    swiper2.isLocked = swiper2.size > lastSlideRightEdge;
  } else {
    swiper2.isLocked = swiper2.snapGrid.length === 1;
  }
  if (params.allowSlideNext === true) {
    swiper2.allowSlideNext = !swiper2.isLocked;
  }
  if (params.allowSlidePrev === true) {
    swiper2.allowSlidePrev = !swiper2.isLocked;
  }
  if (wasLocked && wasLocked !== swiper2.isLocked) {
    swiper2.isEnd = false;
  }
  if (wasLocked !== swiper2.isLocked) {
    swiper2.emit(swiper2.isLocked ? "lock" : "unlock");
  }
}
var checkOverflow$1 = {
  checkOverflow
};
var defaults$1 = {
  init: true,
  direction: "horizontal",
  oneWayMovement: false,
  swiperElementNodeName: "SWIPER-CONTAINER",
  touchEventsTarget: "wrapper",
  initialSlide: 0,
  speed: 300,
  cssMode: false,
  updateOnWindowResize: true,
  resizeObserver: true,
  nested: false,
  createElements: false,
  eventsPrefix: "swiper",
  enabled: true,
  focusableElements: "input, select, option, textarea, button, video, label",
  // Overrides
  width: null,
  height: null,
  //
  preventInteractionOnTransition: false,
  // ssr
  userAgent: null,
  url: null,
  // To support iOS's swipe-to-go-back gesture (when being used in-app).
  edgeSwipeDetection: false,
  edgeSwipeThreshold: 20,
  // Autoheight
  autoHeight: false,
  // Set wrapper width
  setWrapperSize: false,
  // Virtual Translate
  virtualTranslate: false,
  // Effects
  effect: "slide",
  // 'slide' or 'fade' or 'cube' or 'coverflow' or 'flip'
  // Breakpoints
  breakpoints: void 0,
  breakpointsBase: "window",
  // Slides grid
  spaceBetween: 0,
  slidesPerView: 1,
  slidesPerGroup: 1,
  slidesPerGroupSkip: 0,
  slidesPerGroupAuto: false,
  centeredSlides: false,
  centeredSlidesBounds: false,
  slidesOffsetBefore: 0,
  // in px
  slidesOffsetAfter: 0,
  // in px
  normalizeSlideIndex: true,
  centerInsufficientSlides: false,
  // Disable swiper and hide navigation when container not overflow
  watchOverflow: true,
  // Round length
  roundLengths: false,
  // Touches
  touchRatio: 1,
  touchAngle: 45,
  simulateTouch: true,
  shortSwipes: true,
  longSwipes: true,
  longSwipesRatio: 0.5,
  longSwipesMs: 300,
  followFinger: true,
  allowTouchMove: true,
  threshold: 5,
  touchMoveStopPropagation: false,
  touchStartPreventDefault: true,
  touchStartForcePreventDefault: false,
  touchReleaseOnEdges: false,
  // Unique Navigation Elements
  uniqueNavElements: true,
  // Resistance
  resistance: true,
  resistanceRatio: 0.85,
  // Progress
  watchSlidesProgress: false,
  // Cursor
  grabCursor: false,
  // Clicks
  preventClicks: true,
  preventClicksPropagation: true,
  slideToClickedSlide: false,
  // loop
  loop: false,
  loopAddBlankSlides: true,
  loopAdditionalSlides: 0,
  loopPreventsSliding: true,
  // rewind
  rewind: false,
  // Swiping/no swiping
  allowSlidePrev: true,
  allowSlideNext: true,
  swipeHandler: null,
  // '.swipe-handler',
  noSwiping: true,
  noSwipingClass: "swiper-no-swiping",
  noSwipingSelector: null,
  // Passive Listeners
  passiveListeners: true,
  maxBackfaceHiddenSlides: 10,
  // NS
  containerModifierClass: "swiper-",
  // NEW
  slideClass: "swiper-slide",
  slideBlankClass: "swiper-slide-blank",
  slideActiveClass: "swiper-slide-active",
  slideVisibleClass: "swiper-slide-visible",
  slideFullyVisibleClass: "swiper-slide-fully-visible",
  slideNextClass: "swiper-slide-next",
  slidePrevClass: "swiper-slide-prev",
  wrapperClass: "swiper-wrapper",
  lazyPreloaderClass: "swiper-lazy-preloader",
  lazyPreloadPrevNext: 0,
  // Callbacks
  runCallbacksOnInit: true,
  // Internals
  _emitClasses: false
};
function moduleExtendParams(params, allModulesParams) {
  return function extendParams(obj) {
    if (obj === void 0) {
      obj = {};
    }
    const moduleParamName = Object.keys(obj)[0];
    const moduleParams = obj[moduleParamName];
    if (typeof moduleParams !== "object" || moduleParams === null) {
      extend(allModulesParams, obj);
      return;
    }
    if (params[moduleParamName] === true) {
      params[moduleParamName] = {
        enabled: true
      };
    }
    if (moduleParamName === "navigation" && params[moduleParamName] && params[moduleParamName].enabled && !params[moduleParamName].prevEl && !params[moduleParamName].nextEl) {
      params[moduleParamName].auto = true;
    }
    if (["pagination", "scrollbar"].indexOf(moduleParamName) >= 0 && params[moduleParamName] && params[moduleParamName].enabled && !params[moduleParamName].el) {
      params[moduleParamName].auto = true;
    }
    if (!(moduleParamName in params && "enabled" in moduleParams)) {
      extend(allModulesParams, obj);
      return;
    }
    if (typeof params[moduleParamName] === "object" && !("enabled" in params[moduleParamName])) {
      params[moduleParamName].enabled = true;
    }
    if (!params[moduleParamName])
      params[moduleParamName] = {
        enabled: false
      };
    extend(allModulesParams, obj);
  };
}
const prototypes = {
  eventsEmitter,
  update,
  translate,
  transition,
  slide,
  loop,
  grabCursor,
  events: events$1,
  breakpoints,
  checkOverflow: checkOverflow$1,
  classes
};
const extendedDefaults = {};
class Swiper {
  constructor() {
    let el;
    let params;
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    if (args.length === 1 && args[0].constructor && Object.prototype.toString.call(args[0]).slice(8, -1) === "Object") {
      params = args[0];
    } else {
      [el, params] = args;
    }
    if (!params)
      params = {};
    params = extend({}, params);
    if (el && !params.el)
      params.el = el;
    const document2 = getDocument();
    if (params.el && typeof params.el === "string" && document2.querySelectorAll(params.el).length > 1) {
      const swipers = [];
      document2.querySelectorAll(params.el).forEach((containerEl) => {
        const newParams = extend({}, params, {
          el: containerEl
        });
        swipers.push(new Swiper(newParams));
      });
      return swipers;
    }
    const swiper2 = this;
    swiper2.__swiper__ = true;
    swiper2.support = getSupport();
    swiper2.device = getDevice({
      userAgent: params.userAgent
    });
    swiper2.browser = getBrowser();
    swiper2.eventsListeners = {};
    swiper2.eventsAnyListeners = [];
    swiper2.modules = [...swiper2.__modules__];
    if (params.modules && Array.isArray(params.modules)) {
      swiper2.modules.push(...params.modules);
    }
    const allModulesParams = {};
    swiper2.modules.forEach((mod) => {
      mod({
        params,
        swiper: swiper2,
        extendParams: moduleExtendParams(params, allModulesParams),
        on: swiper2.on.bind(swiper2),
        once: swiper2.once.bind(swiper2),
        off: swiper2.off.bind(swiper2),
        emit: swiper2.emit.bind(swiper2)
      });
    });
    const swiperParams = extend({}, defaults$1, allModulesParams);
    swiper2.params = extend({}, swiperParams, extendedDefaults, params);
    swiper2.originalParams = extend({}, swiper2.params);
    swiper2.passedParams = extend({}, params);
    if (swiper2.params && swiper2.params.on) {
      Object.keys(swiper2.params.on).forEach((eventName) => {
        swiper2.on(eventName, swiper2.params.on[eventName]);
      });
    }
    if (swiper2.params && swiper2.params.onAny) {
      swiper2.onAny(swiper2.params.onAny);
    }
    Object.assign(swiper2, {
      enabled: swiper2.params.enabled,
      el,
      // Classes
      classNames: [],
      // Slides
      slides: [],
      slidesGrid: [],
      snapGrid: [],
      slidesSizesGrid: [],
      // isDirection
      isHorizontal() {
        return swiper2.params.direction === "horizontal";
      },
      isVertical() {
        return swiper2.params.direction === "vertical";
      },
      // Indexes
      activeIndex: 0,
      realIndex: 0,
      //
      isBeginning: true,
      isEnd: false,
      // Props
      translate: 0,
      previousTranslate: 0,
      progress: 0,
      velocity: 0,
      animating: false,
      cssOverflowAdjustment() {
        return Math.trunc(this.translate / 2 ** 23) * 2 ** 23;
      },
      // Locks
      allowSlideNext: swiper2.params.allowSlideNext,
      allowSlidePrev: swiper2.params.allowSlidePrev,
      // Touch Events
      touchEventsData: {
        isTouched: void 0,
        isMoved: void 0,
        allowTouchCallbacks: void 0,
        touchStartTime: void 0,
        isScrolling: void 0,
        currentTranslate: void 0,
        startTranslate: void 0,
        allowThresholdMove: void 0,
        // Form elements to match
        focusableElements: swiper2.params.focusableElements,
        // Last click time
        lastClickTime: 0,
        clickTimeout: void 0,
        // Velocities
        velocities: [],
        allowMomentumBounce: void 0,
        startMoving: void 0,
        pointerId: null,
        touchId: null
      },
      // Clicks
      allowClick: true,
      // Touches
      allowTouchMove: swiper2.params.allowTouchMove,
      touches: {
        startX: 0,
        startY: 0,
        currentX: 0,
        currentY: 0,
        diff: 0
      },
      // Images
      imagesToLoad: [],
      imagesLoaded: 0
    });
    swiper2.emit("_swiper");
    if (swiper2.params.init) {
      swiper2.init();
    }
    return swiper2;
  }
  getDirectionLabel(property) {
    if (this.isHorizontal()) {
      return property;
    }
    return {
      "width": "height",
      "margin-top": "margin-left",
      "margin-bottom ": "margin-right",
      "margin-left": "margin-top",
      "margin-right": "margin-bottom",
      "padding-left": "padding-top",
      "padding-right": "padding-bottom",
      "marginRight": "marginBottom"
    }[property];
  }
  getSlideIndex(slideEl) {
    const {
      slidesEl,
      params
    } = this;
    const slides = elementChildren(slidesEl, `.${params.slideClass}, swiper-slide`);
    const firstSlideIndex = elementIndex(slides[0]);
    return elementIndex(slideEl) - firstSlideIndex;
  }
  getSlideIndexByData(index) {
    return this.getSlideIndex(this.slides.filter((slideEl) => slideEl.getAttribute("data-swiper-slide-index") * 1 === index)[0]);
  }
  recalcSlides() {
    const swiper2 = this;
    const {
      slidesEl,
      params
    } = swiper2;
    swiper2.slides = elementChildren(slidesEl, `.${params.slideClass}, swiper-slide`);
  }
  enable() {
    const swiper2 = this;
    if (swiper2.enabled)
      return;
    swiper2.enabled = true;
    if (swiper2.params.grabCursor) {
      swiper2.setGrabCursor();
    }
    swiper2.emit("enable");
  }
  disable() {
    const swiper2 = this;
    if (!swiper2.enabled)
      return;
    swiper2.enabled = false;
    if (swiper2.params.grabCursor) {
      swiper2.unsetGrabCursor();
    }
    swiper2.emit("disable");
  }
  setProgress(progress, speed) {
    const swiper2 = this;
    progress = Math.min(Math.max(progress, 0), 1);
    const min = swiper2.minTranslate();
    const max = swiper2.maxTranslate();
    const current = (max - min) * progress + min;
    swiper2.translateTo(current, typeof speed === "undefined" ? 0 : speed);
    swiper2.updateActiveIndex();
    swiper2.updateSlidesClasses();
  }
  emitContainerClasses() {
    const swiper2 = this;
    if (!swiper2.params._emitClasses || !swiper2.el)
      return;
    const cls = swiper2.el.className.split(" ").filter((className) => {
      return className.indexOf("swiper") === 0 || className.indexOf(swiper2.params.containerModifierClass) === 0;
    });
    swiper2.emit("_containerClasses", cls.join(" "));
  }
  getSlideClasses(slideEl) {
    const swiper2 = this;
    if (swiper2.destroyed)
      return "";
    return slideEl.className.split(" ").filter((className) => {
      return className.indexOf("swiper-slide") === 0 || className.indexOf(swiper2.params.slideClass) === 0;
    }).join(" ");
  }
  emitSlidesClasses() {
    const swiper2 = this;
    if (!swiper2.params._emitClasses || !swiper2.el)
      return;
    const updates = [];
    swiper2.slides.forEach((slideEl) => {
      const classNames = swiper2.getSlideClasses(slideEl);
      updates.push({
        slideEl,
        classNames
      });
      swiper2.emit("_slideClass", slideEl, classNames);
    });
    swiper2.emit("_slideClasses", updates);
  }
  slidesPerViewDynamic(view, exact) {
    if (view === void 0) {
      view = "current";
    }
    if (exact === void 0) {
      exact = false;
    }
    const swiper2 = this;
    const {
      params,
      slides,
      slidesGrid,
      slidesSizesGrid,
      size: swiperSize,
      activeIndex
    } = swiper2;
    let spv = 1;
    if (typeof params.slidesPerView === "number")
      return params.slidesPerView;
    if (params.centeredSlides) {
      let slideSize = slides[activeIndex] ? Math.ceil(slides[activeIndex].swiperSlideSize) : 0;
      let breakLoop;
      for (let i = activeIndex + 1; i < slides.length; i += 1) {
        if (slides[i] && !breakLoop) {
          slideSize += Math.ceil(slides[i].swiperSlideSize);
          spv += 1;
          if (slideSize > swiperSize)
            breakLoop = true;
        }
      }
      for (let i = activeIndex - 1; i >= 0; i -= 1) {
        if (slides[i] && !breakLoop) {
          slideSize += slides[i].swiperSlideSize;
          spv += 1;
          if (slideSize > swiperSize)
            breakLoop = true;
        }
      }
    } else {
      if (view === "current") {
        for (let i = activeIndex + 1; i < slides.length; i += 1) {
          const slideInView = exact ? slidesGrid[i] + slidesSizesGrid[i] - slidesGrid[activeIndex] < swiperSize : slidesGrid[i] - slidesGrid[activeIndex] < swiperSize;
          if (slideInView) {
            spv += 1;
          }
        }
      } else {
        for (let i = activeIndex - 1; i >= 0; i -= 1) {
          const slideInView = slidesGrid[activeIndex] - slidesGrid[i] < swiperSize;
          if (slideInView) {
            spv += 1;
          }
        }
      }
    }
    return spv;
  }
  update() {
    const swiper2 = this;
    if (!swiper2 || swiper2.destroyed)
      return;
    const {
      snapGrid,
      params
    } = swiper2;
    if (params.breakpoints) {
      swiper2.setBreakpoint();
    }
    [...swiper2.el.querySelectorAll('[loading="lazy"]')].forEach((imageEl) => {
      if (imageEl.complete) {
        processLazyPreloader(swiper2, imageEl);
      }
    });
    swiper2.updateSize();
    swiper2.updateSlides();
    swiper2.updateProgress();
    swiper2.updateSlidesClasses();
    function setTranslate2() {
      const translateValue = swiper2.rtlTranslate ? swiper2.translate * -1 : swiper2.translate;
      const newTranslate = Math.min(Math.max(translateValue, swiper2.maxTranslate()), swiper2.minTranslate());
      swiper2.setTranslate(newTranslate);
      swiper2.updateActiveIndex();
      swiper2.updateSlidesClasses();
    }
    let translated;
    if (params.freeMode && params.freeMode.enabled && !params.cssMode) {
      setTranslate2();
      if (params.autoHeight) {
        swiper2.updateAutoHeight();
      }
    } else {
      if ((params.slidesPerView === "auto" || params.slidesPerView > 1) && swiper2.isEnd && !params.centeredSlides) {
        const slides = swiper2.virtual && params.virtual.enabled ? swiper2.virtual.slides : swiper2.slides;
        translated = swiper2.slideTo(slides.length - 1, 0, false, true);
      } else {
        translated = swiper2.slideTo(swiper2.activeIndex, 0, false, true);
      }
      if (!translated) {
        setTranslate2();
      }
    }
    if (params.watchOverflow && snapGrid !== swiper2.snapGrid) {
      swiper2.checkOverflow();
    }
    swiper2.emit("update");
  }
  changeDirection(newDirection, needUpdate) {
    if (needUpdate === void 0) {
      needUpdate = true;
    }
    const swiper2 = this;
    const currentDirection = swiper2.params.direction;
    if (!newDirection) {
      newDirection = currentDirection === "horizontal" ? "vertical" : "horizontal";
    }
    if (newDirection === currentDirection || newDirection !== "horizontal" && newDirection !== "vertical") {
      return swiper2;
    }
    swiper2.el.classList.remove(`${swiper2.params.containerModifierClass}${currentDirection}`);
    swiper2.el.classList.add(`${swiper2.params.containerModifierClass}${newDirection}`);
    swiper2.emitContainerClasses();
    swiper2.params.direction = newDirection;
    swiper2.slides.forEach((slideEl) => {
      if (newDirection === "vertical") {
        slideEl.style.width = "";
      } else {
        slideEl.style.height = "";
      }
    });
    swiper2.emit("changeDirection");
    if (needUpdate)
      swiper2.update();
    return swiper2;
  }
  changeLanguageDirection(direction) {
    const swiper2 = this;
    if (swiper2.rtl && direction === "rtl" || !swiper2.rtl && direction === "ltr")
      return;
    swiper2.rtl = direction === "rtl";
    swiper2.rtlTranslate = swiper2.params.direction === "horizontal" && swiper2.rtl;
    if (swiper2.rtl) {
      swiper2.el.classList.add(`${swiper2.params.containerModifierClass}rtl`);
      swiper2.el.dir = "rtl";
    } else {
      swiper2.el.classList.remove(`${swiper2.params.containerModifierClass}rtl`);
      swiper2.el.dir = "ltr";
    }
    swiper2.update();
  }
  mount(element) {
    const swiper2 = this;
    if (swiper2.mounted)
      return true;
    let el = element || swiper2.params.el;
    if (typeof el === "string") {
      el = document.querySelector(el);
    }
    if (!el) {
      return false;
    }
    el.swiper = swiper2;
    if (el.parentNode && el.parentNode.host && el.parentNode.host.nodeName === swiper2.params.swiperElementNodeName.toUpperCase()) {
      swiper2.isElement = true;
    }
    const getWrapperSelector = () => {
      return `.${(swiper2.params.wrapperClass || "").trim().split(" ").join(".")}`;
    };
    const getWrapper = () => {
      if (el && el.shadowRoot && el.shadowRoot.querySelector) {
        const res = el.shadowRoot.querySelector(getWrapperSelector());
        return res;
      }
      return elementChildren(el, getWrapperSelector())[0];
    };
    let wrapperEl = getWrapper();
    if (!wrapperEl && swiper2.params.createElements) {
      wrapperEl = createElement("div", swiper2.params.wrapperClass);
      el.append(wrapperEl);
      elementChildren(el, `.${swiper2.params.slideClass}`).forEach((slideEl) => {
        wrapperEl.append(slideEl);
      });
    }
    Object.assign(swiper2, {
      el,
      wrapperEl,
      slidesEl: swiper2.isElement && !el.parentNode.host.slideSlots ? el.parentNode.host : wrapperEl,
      hostEl: swiper2.isElement ? el.parentNode.host : el,
      mounted: true,
      // RTL
      rtl: el.dir.toLowerCase() === "rtl" || elementStyle(el, "direction") === "rtl",
      rtlTranslate: swiper2.params.direction === "horizontal" && (el.dir.toLowerCase() === "rtl" || elementStyle(el, "direction") === "rtl"),
      wrongRTL: elementStyle(wrapperEl, "display") === "-webkit-box"
    });
    return true;
  }
  init(el) {
    const swiper2 = this;
    if (swiper2.initialized)
      return swiper2;
    const mounted = swiper2.mount(el);
    if (mounted === false)
      return swiper2;
    swiper2.emit("beforeInit");
    if (swiper2.params.breakpoints) {
      swiper2.setBreakpoint();
    }
    swiper2.addClasses();
    swiper2.updateSize();
    swiper2.updateSlides();
    if (swiper2.params.watchOverflow) {
      swiper2.checkOverflow();
    }
    if (swiper2.params.grabCursor && swiper2.enabled) {
      swiper2.setGrabCursor();
    }
    if (swiper2.params.loop && swiper2.virtual && swiper2.params.virtual.enabled) {
      swiper2.slideTo(swiper2.params.initialSlide + swiper2.virtual.slidesBefore, 0, swiper2.params.runCallbacksOnInit, false, true);
    } else {
      swiper2.slideTo(swiper2.params.initialSlide, 0, swiper2.params.runCallbacksOnInit, false, true);
    }
    if (swiper2.params.loop) {
      swiper2.loopCreate();
    }
    swiper2.attachEvents();
    const lazyElements = [...swiper2.el.querySelectorAll('[loading="lazy"]')];
    if (swiper2.isElement) {
      lazyElements.push(...swiper2.hostEl.querySelectorAll('[loading="lazy"]'));
    }
    lazyElements.forEach((imageEl) => {
      if (imageEl.complete) {
        processLazyPreloader(swiper2, imageEl);
      } else {
        imageEl.addEventListener("load", (e) => {
          processLazyPreloader(swiper2, e.target);
        });
      }
    });
    preload2(swiper2);
    swiper2.initialized = true;
    preload2(swiper2);
    swiper2.emit("init");
    swiper2.emit("afterInit");
    return swiper2;
  }
  destroy(deleteInstance, cleanStyles) {
    if (deleteInstance === void 0) {
      deleteInstance = true;
    }
    if (cleanStyles === void 0) {
      cleanStyles = true;
    }
    const swiper2 = this;
    const {
      params,
      el,
      wrapperEl,
      slides
    } = swiper2;
    if (typeof swiper2.params === "undefined" || swiper2.destroyed) {
      return null;
    }
    swiper2.emit("beforeDestroy");
    swiper2.initialized = false;
    swiper2.detachEvents();
    if (params.loop) {
      swiper2.loopDestroy();
    }
    if (cleanStyles) {
      swiper2.removeClasses();
      el.removeAttribute("style");
      wrapperEl.removeAttribute("style");
      if (slides && slides.length) {
        slides.forEach((slideEl) => {
          slideEl.classList.remove(params.slideVisibleClass, params.slideFullyVisibleClass, params.slideActiveClass, params.slideNextClass, params.slidePrevClass);
          slideEl.removeAttribute("style");
          slideEl.removeAttribute("data-swiper-slide-index");
        });
      }
    }
    swiper2.emit("destroy");
    Object.keys(swiper2.eventsListeners).forEach((eventName) => {
      swiper2.off(eventName);
    });
    if (deleteInstance !== false) {
      swiper2.el.swiper = null;
      deleteProps(swiper2);
    }
    swiper2.destroyed = true;
    return null;
  }
  static extendDefaults(newDefaults) {
    extend(extendedDefaults, newDefaults);
  }
  static get extendedDefaults() {
    return extendedDefaults;
  }
  static get defaults() {
    return defaults$1;
  }
  static installModule(mod) {
    if (!Swiper.prototype.__modules__)
      Swiper.prototype.__modules__ = [];
    const modules = Swiper.prototype.__modules__;
    if (typeof mod === "function" && modules.indexOf(mod) < 0) {
      modules.push(mod);
    }
  }
  static use(module) {
    if (Array.isArray(module)) {
      module.forEach((m) => Swiper.installModule(m));
      return Swiper;
    }
    Swiper.installModule(module);
    return Swiper;
  }
}
Object.keys(prototypes).forEach((prototypeGroup) => {
  Object.keys(prototypes[prototypeGroup]).forEach((protoMethod) => {
    Swiper.prototype[protoMethod] = prototypes[prototypeGroup][protoMethod];
  });
});
Swiper.use([Resize, Observer]);
function createElementIfNotDefined(swiper2, originalParams, params, checkProps) {
  if (swiper2.params.createElements) {
    Object.keys(checkProps).forEach((key) => {
      if (!params[key] && params.auto === true) {
        let element = elementChildren(swiper2.el, `.${checkProps[key]}`)[0];
        if (!element) {
          element = createElement("div", checkProps[key]);
          element.className = checkProps[key];
          swiper2.el.append(element);
        }
        params[key] = element;
        originalParams[key] = element;
      }
    });
  }
  return params;
}
function Navigation(_ref) {
  let {
    swiper: swiper2,
    extendParams,
    on,
    emit
  } = _ref;
  extendParams({
    navigation: {
      nextEl: null,
      prevEl: null,
      hideOnClick: false,
      disabledClass: "swiper-button-disabled",
      hiddenClass: "swiper-button-hidden",
      lockClass: "swiper-button-lock",
      navigationDisabledClass: "swiper-navigation-disabled"
    }
  });
  swiper2.navigation = {
    nextEl: null,
    prevEl: null
  };
  function getEl(el) {
    let res;
    if (el && typeof el === "string" && swiper2.isElement) {
      res = swiper2.el.querySelector(el);
      if (res)
        return res;
    }
    if (el) {
      if (typeof el === "string")
        res = [...document.querySelectorAll(el)];
      if (swiper2.params.uniqueNavElements && typeof el === "string" && res && res.length > 1 && swiper2.el.querySelectorAll(el).length === 1) {
        res = swiper2.el.querySelector(el);
      } else if (res && res.length === 1) {
        res = res[0];
      }
    }
    if (el && !res)
      return el;
    return res;
  }
  function toggleEl(el, disabled) {
    const params = swiper2.params.navigation;
    el = makeElementsArray(el);
    el.forEach((subEl) => {
      if (subEl) {
        subEl.classList[disabled ? "add" : "remove"](...params.disabledClass.split(" "));
        if (subEl.tagName === "BUTTON")
          subEl.disabled = disabled;
        if (swiper2.params.watchOverflow && swiper2.enabled) {
          subEl.classList[swiper2.isLocked ? "add" : "remove"](params.lockClass);
        }
      }
    });
  }
  function update2() {
    const {
      nextEl,
      prevEl
    } = swiper2.navigation;
    if (swiper2.params.loop) {
      toggleEl(prevEl, false);
      toggleEl(nextEl, false);
      return;
    }
    toggleEl(prevEl, swiper2.isBeginning && !swiper2.params.rewind);
    toggleEl(nextEl, swiper2.isEnd && !swiper2.params.rewind);
  }
  function onPrevClick(e) {
    e.preventDefault();
    if (swiper2.isBeginning && !swiper2.params.loop && !swiper2.params.rewind)
      return;
    swiper2.slidePrev();
    emit("navigationPrev");
  }
  function onNextClick(e) {
    e.preventDefault();
    if (swiper2.isEnd && !swiper2.params.loop && !swiper2.params.rewind)
      return;
    swiper2.slideNext();
    emit("navigationNext");
  }
  function init2() {
    const params = swiper2.params.navigation;
    swiper2.params.navigation = createElementIfNotDefined(swiper2, swiper2.originalParams.navigation, swiper2.params.navigation, {
      nextEl: "swiper-button-next",
      prevEl: "swiper-button-prev"
    });
    if (!(params.nextEl || params.prevEl))
      return;
    let nextEl = getEl(params.nextEl);
    let prevEl = getEl(params.prevEl);
    Object.assign(swiper2.navigation, {
      nextEl,
      prevEl
    });
    nextEl = makeElementsArray(nextEl);
    prevEl = makeElementsArray(prevEl);
    const initButton = (el, dir) => {
      if (el) {
        el.addEventListener("click", dir === "next" ? onNextClick : onPrevClick);
      }
      if (!swiper2.enabled && el) {
        el.classList.add(...params.lockClass.split(" "));
      }
    };
    nextEl.forEach((el) => initButton(el, "next"));
    prevEl.forEach((el) => initButton(el, "prev"));
  }
  function destroy() {
    let {
      nextEl,
      prevEl
    } = swiper2.navigation;
    nextEl = makeElementsArray(nextEl);
    prevEl = makeElementsArray(prevEl);
    const destroyButton = (el, dir) => {
      el.removeEventListener("click", dir === "next" ? onNextClick : onPrevClick);
      el.classList.remove(...swiper2.params.navigation.disabledClass.split(" "));
    };
    nextEl.forEach((el) => destroyButton(el, "next"));
    prevEl.forEach((el) => destroyButton(el, "prev"));
  }
  on("init", () => {
    if (swiper2.params.navigation.enabled === false) {
      disable();
    } else {
      init2();
      update2();
    }
  });
  on("toEdge fromEdge lock unlock", () => {
    update2();
  });
  on("destroy", () => {
    destroy();
  });
  on("enable disable", () => {
    let {
      nextEl,
      prevEl
    } = swiper2.navigation;
    nextEl = makeElementsArray(nextEl);
    prevEl = makeElementsArray(prevEl);
    if (swiper2.enabled) {
      update2();
      return;
    }
    [...nextEl, ...prevEl].filter((el) => !!el).forEach((el) => el.classList.add(swiper2.params.navigation.lockClass));
  });
  on("click", (_s, e) => {
    let {
      nextEl,
      prevEl
    } = swiper2.navigation;
    nextEl = makeElementsArray(nextEl);
    prevEl = makeElementsArray(prevEl);
    const targetEl = e.target;
    if (swiper2.params.navigation.hideOnClick && !prevEl.includes(targetEl) && !nextEl.includes(targetEl)) {
      if (swiper2.pagination && swiper2.params.pagination && swiper2.params.pagination.clickable && (swiper2.pagination.el === targetEl || swiper2.pagination.el.contains(targetEl)))
        return;
      let isHidden;
      if (nextEl.length) {
        isHidden = nextEl[0].classList.contains(swiper2.params.navigation.hiddenClass);
      } else if (prevEl.length) {
        isHidden = prevEl[0].classList.contains(swiper2.params.navigation.hiddenClass);
      }
      if (isHidden === true) {
        emit("navigationShow");
      } else {
        emit("navigationHide");
      }
      [...nextEl, ...prevEl].filter((el) => !!el).forEach((el) => el.classList.toggle(swiper2.params.navigation.hiddenClass));
    }
  });
  const enable = () => {
    swiper2.el.classList.remove(...swiper2.params.navigation.navigationDisabledClass.split(" "));
    init2();
    update2();
  };
  const disable = () => {
    swiper2.el.classList.add(...swiper2.params.navigation.navigationDisabledClass.split(" "));
    destroy();
  };
  Object.assign(swiper2.navigation, {
    enable,
    disable,
    update: update2,
    init: init2,
    destroy
  });
}
function classesToSelector(classes2) {
  if (classes2 === void 0) {
    classes2 = "";
  }
  return `.${classes2.trim().replace(/([\.:!+\/])/g, "\\$1").replace(/ /g, ".")}`;
}
function Pagination(_ref) {
  let {
    swiper: swiper2,
    extendParams,
    on,
    emit
  } = _ref;
  const pfx = "swiper-pagination";
  extendParams({
    pagination: {
      el: null,
      bulletElement: "span",
      clickable: false,
      hideOnClick: false,
      renderBullet: null,
      renderProgressbar: null,
      renderFraction: null,
      renderCustom: null,
      progressbarOpposite: false,
      type: "bullets",
      // 'bullets' or 'progressbar' or 'fraction' or 'custom'
      dynamicBullets: false,
      dynamicMainBullets: 1,
      formatFractionCurrent: (number) => number,
      formatFractionTotal: (number) => number,
      bulletClass: `${pfx}-bullet`,
      bulletActiveClass: `${pfx}-bullet-active`,
      modifierClass: `${pfx}-`,
      currentClass: `${pfx}-current`,
      totalClass: `${pfx}-total`,
      hiddenClass: `${pfx}-hidden`,
      progressbarFillClass: `${pfx}-progressbar-fill`,
      progressbarOppositeClass: `${pfx}-progressbar-opposite`,
      clickableClass: `${pfx}-clickable`,
      lockClass: `${pfx}-lock`,
      horizontalClass: `${pfx}-horizontal`,
      verticalClass: `${pfx}-vertical`,
      paginationDisabledClass: `${pfx}-disabled`
    }
  });
  swiper2.pagination = {
    el: null,
    bullets: []
  };
  let bulletSize;
  let dynamicBulletIndex = 0;
  function isPaginationDisabled() {
    return !swiper2.params.pagination.el || !swiper2.pagination.el || Array.isArray(swiper2.pagination.el) && swiper2.pagination.el.length === 0;
  }
  function setSideBullets(bulletEl, position) {
    const {
      bulletActiveClass
    } = swiper2.params.pagination;
    if (!bulletEl)
      return;
    bulletEl = bulletEl[`${position === "prev" ? "previous" : "next"}ElementSibling`];
    if (bulletEl) {
      bulletEl.classList.add(`${bulletActiveClass}-${position}`);
      bulletEl = bulletEl[`${position === "prev" ? "previous" : "next"}ElementSibling`];
      if (bulletEl) {
        bulletEl.classList.add(`${bulletActiveClass}-${position}-${position}`);
      }
    }
  }
  function onBulletClick(e) {
    const bulletEl = e.target.closest(classesToSelector(swiper2.params.pagination.bulletClass));
    if (!bulletEl) {
      return;
    }
    e.preventDefault();
    const index = elementIndex(bulletEl) * swiper2.params.slidesPerGroup;
    if (swiper2.params.loop) {
      if (swiper2.realIndex === index)
        return;
      swiper2.slideToLoop(index);
    } else {
      swiper2.slideTo(index);
    }
  }
  function update2() {
    const rtl = swiper2.rtl;
    const params = swiper2.params.pagination;
    if (isPaginationDisabled())
      return;
    let el = swiper2.pagination.el;
    el = makeElementsArray(el);
    let current;
    let previousIndex;
    const slidesLength = swiper2.virtual && swiper2.params.virtual.enabled ? swiper2.virtual.slides.length : swiper2.slides.length;
    const total = swiper2.params.loop ? Math.ceil(slidesLength / swiper2.params.slidesPerGroup) : swiper2.snapGrid.length;
    if (swiper2.params.loop) {
      previousIndex = swiper2.previousRealIndex || 0;
      current = swiper2.params.slidesPerGroup > 1 ? Math.floor(swiper2.realIndex / swiper2.params.slidesPerGroup) : swiper2.realIndex;
    } else if (typeof swiper2.snapIndex !== "undefined") {
      current = swiper2.snapIndex;
      previousIndex = swiper2.previousSnapIndex;
    } else {
      previousIndex = swiper2.previousIndex || 0;
      current = swiper2.activeIndex || 0;
    }
    if (params.type === "bullets" && swiper2.pagination.bullets && swiper2.pagination.bullets.length > 0) {
      const bullets = swiper2.pagination.bullets;
      let firstIndex;
      let lastIndex;
      let midIndex;
      if (params.dynamicBullets) {
        bulletSize = elementOuterSize(bullets[0], swiper2.isHorizontal() ? "width" : "height", true);
        el.forEach((subEl) => {
          subEl.style[swiper2.isHorizontal() ? "width" : "height"] = `${bulletSize * (params.dynamicMainBullets + 4)}px`;
        });
        if (params.dynamicMainBullets > 1 && previousIndex !== void 0) {
          dynamicBulletIndex += current - (previousIndex || 0);
          if (dynamicBulletIndex > params.dynamicMainBullets - 1) {
            dynamicBulletIndex = params.dynamicMainBullets - 1;
          } else if (dynamicBulletIndex < 0) {
            dynamicBulletIndex = 0;
          }
        }
        firstIndex = Math.max(current - dynamicBulletIndex, 0);
        lastIndex = firstIndex + (Math.min(bullets.length, params.dynamicMainBullets) - 1);
        midIndex = (lastIndex + firstIndex) / 2;
      }
      bullets.forEach((bulletEl) => {
        const classesToRemove = [...["", "-next", "-next-next", "-prev", "-prev-prev", "-main"].map((suffix) => `${params.bulletActiveClass}${suffix}`)].map((s) => typeof s === "string" && s.includes(" ") ? s.split(" ") : s).flat();
        bulletEl.classList.remove(...classesToRemove);
      });
      if (el.length > 1) {
        bullets.forEach((bullet) => {
          const bulletIndex = elementIndex(bullet);
          if (bulletIndex === current) {
            bullet.classList.add(...params.bulletActiveClass.split(" "));
          } else if (swiper2.isElement) {
            bullet.setAttribute("part", "bullet");
          }
          if (params.dynamicBullets) {
            if (bulletIndex >= firstIndex && bulletIndex <= lastIndex) {
              bullet.classList.add(...`${params.bulletActiveClass}-main`.split(" "));
            }
            if (bulletIndex === firstIndex) {
              setSideBullets(bullet, "prev");
            }
            if (bulletIndex === lastIndex) {
              setSideBullets(bullet, "next");
            }
          }
        });
      } else {
        const bullet = bullets[current];
        if (bullet) {
          bullet.classList.add(...params.bulletActiveClass.split(" "));
        }
        if (swiper2.isElement) {
          bullets.forEach((bulletEl, bulletIndex) => {
            bulletEl.setAttribute("part", bulletIndex === current ? "bullet-active" : "bullet");
          });
        }
        if (params.dynamicBullets) {
          const firstDisplayedBullet = bullets[firstIndex];
          const lastDisplayedBullet = bullets[lastIndex];
          for (let i = firstIndex; i <= lastIndex; i += 1) {
            if (bullets[i]) {
              bullets[i].classList.add(...`${params.bulletActiveClass}-main`.split(" "));
            }
          }
          setSideBullets(firstDisplayedBullet, "prev");
          setSideBullets(lastDisplayedBullet, "next");
        }
      }
      if (params.dynamicBullets) {
        const dynamicBulletsLength = Math.min(bullets.length, params.dynamicMainBullets + 4);
        const bulletsOffset = (bulletSize * dynamicBulletsLength - bulletSize) / 2 - midIndex * bulletSize;
        const offsetProp = rtl ? "right" : "left";
        bullets.forEach((bullet) => {
          bullet.style[swiper2.isHorizontal() ? offsetProp : "top"] = `${bulletsOffset}px`;
        });
      }
    }
    el.forEach((subEl, subElIndex) => {
      if (params.type === "fraction") {
        subEl.querySelectorAll(classesToSelector(params.currentClass)).forEach((fractionEl) => {
          fractionEl.textContent = params.formatFractionCurrent(current + 1);
        });
        subEl.querySelectorAll(classesToSelector(params.totalClass)).forEach((totalEl) => {
          totalEl.textContent = params.formatFractionTotal(total);
        });
      }
      if (params.type === "progressbar") {
        let progressbarDirection;
        if (params.progressbarOpposite) {
          progressbarDirection = swiper2.isHorizontal() ? "vertical" : "horizontal";
        } else {
          progressbarDirection = swiper2.isHorizontal() ? "horizontal" : "vertical";
        }
        const scale = (current + 1) / total;
        let scaleX = 1;
        let scaleY = 1;
        if (progressbarDirection === "horizontal") {
          scaleX = scale;
        } else {
          scaleY = scale;
        }
        subEl.querySelectorAll(classesToSelector(params.progressbarFillClass)).forEach((progressEl) => {
          progressEl.style.transform = `translate3d(0,0,0) scaleX(${scaleX}) scaleY(${scaleY})`;
          progressEl.style.transitionDuration = `${swiper2.params.speed}ms`;
        });
      }
      if (params.type === "custom" && params.renderCustom) {
        subEl.innerHTML = params.renderCustom(swiper2, current + 1, total);
        if (subElIndex === 0)
          emit("paginationRender", subEl);
      } else {
        if (subElIndex === 0)
          emit("paginationRender", subEl);
        emit("paginationUpdate", subEl);
      }
      if (swiper2.params.watchOverflow && swiper2.enabled) {
        subEl.classList[swiper2.isLocked ? "add" : "remove"](params.lockClass);
      }
    });
  }
  function render() {
    const params = swiper2.params.pagination;
    if (isPaginationDisabled())
      return;
    const slidesLength = swiper2.virtual && swiper2.params.virtual.enabled ? swiper2.virtual.slides.length : swiper2.grid && swiper2.params.grid.rows > 1 ? swiper2.slides.length / Math.ceil(swiper2.params.grid.rows) : swiper2.slides.length;
    let el = swiper2.pagination.el;
    el = makeElementsArray(el);
    let paginationHTML = "";
    if (params.type === "bullets") {
      let numberOfBullets = swiper2.params.loop ? Math.ceil(slidesLength / swiper2.params.slidesPerGroup) : swiper2.snapGrid.length;
      if (swiper2.params.freeMode && swiper2.params.freeMode.enabled && numberOfBullets > slidesLength) {
        numberOfBullets = slidesLength;
      }
      for (let i = 0; i < numberOfBullets; i += 1) {
        if (params.renderBullet) {
          paginationHTML += params.renderBullet.call(swiper2, i, params.bulletClass);
        } else {
          paginationHTML += `<${params.bulletElement} ${swiper2.isElement ? 'part="bullet"' : ""} class="${params.bulletClass}"></${params.bulletElement}>`;
        }
      }
    }
    if (params.type === "fraction") {
      if (params.renderFraction) {
        paginationHTML = params.renderFraction.call(swiper2, params.currentClass, params.totalClass);
      } else {
        paginationHTML = `<span class="${params.currentClass}"></span> / <span class="${params.totalClass}"></span>`;
      }
    }
    if (params.type === "progressbar") {
      if (params.renderProgressbar) {
        paginationHTML = params.renderProgressbar.call(swiper2, params.progressbarFillClass);
      } else {
        paginationHTML = `<span class="${params.progressbarFillClass}"></span>`;
      }
    }
    swiper2.pagination.bullets = [];
    el.forEach((subEl) => {
      if (params.type !== "custom") {
        subEl.innerHTML = paginationHTML || "";
      }
      if (params.type === "bullets") {
        swiper2.pagination.bullets.push(...subEl.querySelectorAll(classesToSelector(params.bulletClass)));
      }
    });
    if (params.type !== "custom") {
      emit("paginationRender", el[0]);
    }
  }
  function init2() {
    swiper2.params.pagination = createElementIfNotDefined(swiper2, swiper2.originalParams.pagination, swiper2.params.pagination, {
      el: "swiper-pagination"
    });
    const params = swiper2.params.pagination;
    if (!params.el)
      return;
    let el;
    if (typeof params.el === "string" && swiper2.isElement) {
      el = swiper2.el.querySelector(params.el);
    }
    if (!el && typeof params.el === "string") {
      el = [...document.querySelectorAll(params.el)];
    }
    if (!el) {
      el = params.el;
    }
    if (!el || el.length === 0)
      return;
    if (swiper2.params.uniqueNavElements && typeof params.el === "string" && Array.isArray(el) && el.length > 1) {
      el = [...swiper2.el.querySelectorAll(params.el)];
      if (el.length > 1) {
        el = el.filter((subEl) => {
          if (elementParents(subEl, ".swiper")[0] !== swiper2.el)
            return false;
          return true;
        })[0];
      }
    }
    if (Array.isArray(el) && el.length === 1)
      el = el[0];
    Object.assign(swiper2.pagination, {
      el
    });
    el = makeElementsArray(el);
    el.forEach((subEl) => {
      if (params.type === "bullets" && params.clickable) {
        subEl.classList.add(...(params.clickableClass || "").split(" "));
      }
      subEl.classList.add(params.modifierClass + params.type);
      subEl.classList.add(swiper2.isHorizontal() ? params.horizontalClass : params.verticalClass);
      if (params.type === "bullets" && params.dynamicBullets) {
        subEl.classList.add(`${params.modifierClass}${params.type}-dynamic`);
        dynamicBulletIndex = 0;
        if (params.dynamicMainBullets < 1) {
          params.dynamicMainBullets = 1;
        }
      }
      if (params.type === "progressbar" && params.progressbarOpposite) {
        subEl.classList.add(params.progressbarOppositeClass);
      }
      if (params.clickable) {
        subEl.addEventListener("click", onBulletClick);
      }
      if (!swiper2.enabled) {
        subEl.classList.add(params.lockClass);
      }
    });
  }
  function destroy() {
    const params = swiper2.params.pagination;
    if (isPaginationDisabled())
      return;
    let el = swiper2.pagination.el;
    if (el) {
      el = makeElementsArray(el);
      el.forEach((subEl) => {
        subEl.classList.remove(params.hiddenClass);
        subEl.classList.remove(params.modifierClass + params.type);
        subEl.classList.remove(swiper2.isHorizontal() ? params.horizontalClass : params.verticalClass);
        if (params.clickable) {
          subEl.classList.remove(...(params.clickableClass || "").split(" "));
          subEl.removeEventListener("click", onBulletClick);
        }
      });
    }
    if (swiper2.pagination.bullets)
      swiper2.pagination.bullets.forEach((subEl) => subEl.classList.remove(...params.bulletActiveClass.split(" ")));
  }
  on("changeDirection", () => {
    if (!swiper2.pagination || !swiper2.pagination.el)
      return;
    const params = swiper2.params.pagination;
    let {
      el
    } = swiper2.pagination;
    el = makeElementsArray(el);
    el.forEach((subEl) => {
      subEl.classList.remove(params.horizontalClass, params.verticalClass);
      subEl.classList.add(swiper2.isHorizontal() ? params.horizontalClass : params.verticalClass);
    });
  });
  on("init", () => {
    if (swiper2.params.pagination.enabled === false) {
      disable();
    } else {
      init2();
      render();
      update2();
    }
  });
  on("activeIndexChange", () => {
    if (typeof swiper2.snapIndex === "undefined") {
      update2();
    }
  });
  on("snapIndexChange", () => {
    update2();
  });
  on("snapGridLengthChange", () => {
    render();
    update2();
  });
  on("destroy", () => {
    destroy();
  });
  on("enable disable", () => {
    let {
      el
    } = swiper2.pagination;
    if (el) {
      el = makeElementsArray(el);
      el.forEach((subEl) => subEl.classList[swiper2.enabled ? "remove" : "add"](swiper2.params.pagination.lockClass));
    }
  });
  on("lock unlock", () => {
    update2();
  });
  on("click", (_s, e) => {
    const targetEl = e.target;
    const el = makeElementsArray(swiper2.pagination.el);
    if (swiper2.params.pagination.el && swiper2.params.pagination.hideOnClick && el && el.length > 0 && !targetEl.classList.contains(swiper2.params.pagination.bulletClass)) {
      if (swiper2.navigation && (swiper2.navigation.nextEl && targetEl === swiper2.navigation.nextEl || swiper2.navigation.prevEl && targetEl === swiper2.navigation.prevEl))
        return;
      const isHidden = el[0].classList.contains(swiper2.params.pagination.hiddenClass);
      if (isHidden === true) {
        emit("paginationShow");
      } else {
        emit("paginationHide");
      }
      el.forEach((subEl) => subEl.classList.toggle(swiper2.params.pagination.hiddenClass));
    }
  });
  const enable = () => {
    swiper2.el.classList.remove(swiper2.params.pagination.paginationDisabledClass);
    let {
      el
    } = swiper2.pagination;
    if (el) {
      el = makeElementsArray(el);
      el.forEach((subEl) => subEl.classList.remove(swiper2.params.pagination.paginationDisabledClass));
    }
    init2();
    render();
    update2();
  };
  const disable = () => {
    swiper2.el.classList.add(swiper2.params.pagination.paginationDisabledClass);
    let {
      el
    } = swiper2.pagination;
    if (el) {
      el = makeElementsArray(el);
      el.forEach((subEl) => subEl.classList.add(swiper2.params.pagination.paginationDisabledClass));
    }
    destroy();
  };
  Object.assign(swiper2.pagination, {
    enable,
    disable,
    render,
    update: update2,
    init: init2,
    destroy
  });
}
function Scrollbar(_ref) {
  let {
    swiper: swiper2,
    extendParams,
    on,
    emit
  } = _ref;
  const document2 = getDocument();
  let isTouched = false;
  let timeout = null;
  let dragTimeout = null;
  let dragStartPos;
  let dragSize;
  let trackSize;
  let divider;
  extendParams({
    scrollbar: {
      el: null,
      dragSize: "auto",
      hide: false,
      draggable: false,
      snapOnRelease: true,
      lockClass: "swiper-scrollbar-lock",
      dragClass: "swiper-scrollbar-drag",
      scrollbarDisabledClass: "swiper-scrollbar-disabled",
      horizontalClass: `swiper-scrollbar-horizontal`,
      verticalClass: `swiper-scrollbar-vertical`
    }
  });
  swiper2.scrollbar = {
    el: null,
    dragEl: null
  };
  function setTranslate2() {
    if (!swiper2.params.scrollbar.el || !swiper2.scrollbar.el)
      return;
    const {
      scrollbar: scrollbar2,
      rtlTranslate: rtl
    } = swiper2;
    const {
      dragEl,
      el
    } = scrollbar2;
    const params = swiper2.params.scrollbar;
    const progress = swiper2.params.loop ? swiper2.progressLoop : swiper2.progress;
    let newSize = dragSize;
    let newPos = (trackSize - dragSize) * progress;
    if (rtl) {
      newPos = -newPos;
      if (newPos > 0) {
        newSize = dragSize - newPos;
        newPos = 0;
      } else if (-newPos + dragSize > trackSize) {
        newSize = trackSize + newPos;
      }
    } else if (newPos < 0) {
      newSize = dragSize + newPos;
      newPos = 0;
    } else if (newPos + dragSize > trackSize) {
      newSize = trackSize - newPos;
    }
    if (swiper2.isHorizontal()) {
      dragEl.style.transform = `translate3d(${newPos}px, 0, 0)`;
      dragEl.style.width = `${newSize}px`;
    } else {
      dragEl.style.transform = `translate3d(0px, ${newPos}px, 0)`;
      dragEl.style.height = `${newSize}px`;
    }
    if (params.hide) {
      clearTimeout(timeout);
      el.style.opacity = 1;
      timeout = setTimeout(() => {
        el.style.opacity = 0;
        el.style.transitionDuration = "400ms";
      }, 1e3);
    }
  }
  function setTransition2(duration) {
    if (!swiper2.params.scrollbar.el || !swiper2.scrollbar.el)
      return;
    swiper2.scrollbar.dragEl.style.transitionDuration = `${duration}ms`;
  }
  function updateSize2() {
    if (!swiper2.params.scrollbar.el || !swiper2.scrollbar.el)
      return;
    const {
      scrollbar: scrollbar2
    } = swiper2;
    const {
      dragEl,
      el
    } = scrollbar2;
    dragEl.style.width = "";
    dragEl.style.height = "";
    trackSize = swiper2.isHorizontal() ? el.offsetWidth : el.offsetHeight;
    divider = swiper2.size / (swiper2.virtualSize + swiper2.params.slidesOffsetBefore - (swiper2.params.centeredSlides ? swiper2.snapGrid[0] : 0));
    if (swiper2.params.scrollbar.dragSize === "auto") {
      dragSize = trackSize * divider;
    } else {
      dragSize = parseInt(swiper2.params.scrollbar.dragSize, 10);
    }
    if (swiper2.isHorizontal()) {
      dragEl.style.width = `${dragSize}px`;
    } else {
      dragEl.style.height = `${dragSize}px`;
    }
    if (divider >= 1) {
      el.style.display = "none";
    } else {
      el.style.display = "";
    }
    if (swiper2.params.scrollbar.hide) {
      el.style.opacity = 0;
    }
    if (swiper2.params.watchOverflow && swiper2.enabled) {
      scrollbar2.el.classList[swiper2.isLocked ? "add" : "remove"](swiper2.params.scrollbar.lockClass);
    }
  }
  function getPointerPosition(e) {
    return swiper2.isHorizontal() ? e.clientX : e.clientY;
  }
  function setDragPosition(e) {
    const {
      scrollbar: scrollbar2,
      rtlTranslate: rtl
    } = swiper2;
    const {
      el
    } = scrollbar2;
    let positionRatio;
    positionRatio = (getPointerPosition(e) - elementOffset(el)[swiper2.isHorizontal() ? "left" : "top"] - (dragStartPos !== null ? dragStartPos : dragSize / 2)) / (trackSize - dragSize);
    positionRatio = Math.max(Math.min(positionRatio, 1), 0);
    if (rtl) {
      positionRatio = 1 - positionRatio;
    }
    const position = swiper2.minTranslate() + (swiper2.maxTranslate() - swiper2.minTranslate()) * positionRatio;
    swiper2.updateProgress(position);
    swiper2.setTranslate(position);
    swiper2.updateActiveIndex();
    swiper2.updateSlidesClasses();
  }
  function onDragStart(e) {
    const params = swiper2.params.scrollbar;
    const {
      scrollbar: scrollbar2,
      wrapperEl
    } = swiper2;
    const {
      el,
      dragEl
    } = scrollbar2;
    isTouched = true;
    dragStartPos = e.target === dragEl ? getPointerPosition(e) - e.target.getBoundingClientRect()[swiper2.isHorizontal() ? "left" : "top"] : null;
    e.preventDefault();
    e.stopPropagation();
    wrapperEl.style.transitionDuration = "100ms";
    dragEl.style.transitionDuration = "100ms";
    setDragPosition(e);
    clearTimeout(dragTimeout);
    el.style.transitionDuration = "0ms";
    if (params.hide) {
      el.style.opacity = 1;
    }
    if (swiper2.params.cssMode) {
      swiper2.wrapperEl.style["scroll-snap-type"] = "none";
    }
    emit("scrollbarDragStart", e);
  }
  function onDragMove(e) {
    const {
      scrollbar: scrollbar2,
      wrapperEl
    } = swiper2;
    const {
      el,
      dragEl
    } = scrollbar2;
    if (!isTouched)
      return;
    if (e.preventDefault && e.cancelable)
      e.preventDefault();
    else
      e.returnValue = false;
    setDragPosition(e);
    wrapperEl.style.transitionDuration = "0ms";
    el.style.transitionDuration = "0ms";
    dragEl.style.transitionDuration = "0ms";
    emit("scrollbarDragMove", e);
  }
  function onDragEnd(e) {
    const params = swiper2.params.scrollbar;
    const {
      scrollbar: scrollbar2,
      wrapperEl
    } = swiper2;
    const {
      el
    } = scrollbar2;
    if (!isTouched)
      return;
    isTouched = false;
    if (swiper2.params.cssMode) {
      swiper2.wrapperEl.style["scroll-snap-type"] = "";
      wrapperEl.style.transitionDuration = "";
    }
    if (params.hide) {
      clearTimeout(dragTimeout);
      dragTimeout = nextTick(() => {
        el.style.opacity = 0;
        el.style.transitionDuration = "400ms";
      }, 1e3);
    }
    emit("scrollbarDragEnd", e);
    if (params.snapOnRelease) {
      swiper2.slideToClosest();
    }
  }
  function events2(method) {
    const {
      scrollbar: scrollbar2,
      params
    } = swiper2;
    const el = scrollbar2.el;
    if (!el)
      return;
    const target = el;
    const activeListener = params.passiveListeners ? {
      passive: false,
      capture: false
    } : false;
    const passiveListener = params.passiveListeners ? {
      passive: true,
      capture: false
    } : false;
    if (!target)
      return;
    const eventMethod = method === "on" ? "addEventListener" : "removeEventListener";
    target[eventMethod]("pointerdown", onDragStart, activeListener);
    document2[eventMethod]("pointermove", onDragMove, activeListener);
    document2[eventMethod]("pointerup", onDragEnd, passiveListener);
  }
  function enableDraggable() {
    if (!swiper2.params.scrollbar.el || !swiper2.scrollbar.el)
      return;
    events2("on");
  }
  function disableDraggable() {
    if (!swiper2.params.scrollbar.el || !swiper2.scrollbar.el)
      return;
    events2("off");
  }
  function init2() {
    const {
      scrollbar: scrollbar2,
      el: swiperEl
    } = swiper2;
    swiper2.params.scrollbar = createElementIfNotDefined(swiper2, swiper2.originalParams.scrollbar, swiper2.params.scrollbar, {
      el: "swiper-scrollbar"
    });
    const params = swiper2.params.scrollbar;
    if (!params.el)
      return;
    let el;
    if (typeof params.el === "string" && swiper2.isElement) {
      el = swiper2.el.querySelector(params.el);
    }
    if (!el && typeof params.el === "string") {
      el = document2.querySelectorAll(params.el);
      if (!el.length)
        return;
    } else if (!el) {
      el = params.el;
    }
    if (swiper2.params.uniqueNavElements && typeof params.el === "string" && el.length > 1 && swiperEl.querySelectorAll(params.el).length === 1) {
      el = swiperEl.querySelector(params.el);
    }
    if (el.length > 0)
      el = el[0];
    el.classList.add(swiper2.isHorizontal() ? params.horizontalClass : params.verticalClass);
    let dragEl;
    if (el) {
      dragEl = el.querySelector(classesToSelector(swiper2.params.scrollbar.dragClass));
      if (!dragEl) {
        dragEl = createElement("div", swiper2.params.scrollbar.dragClass);
        el.append(dragEl);
      }
    }
    Object.assign(scrollbar2, {
      el,
      dragEl
    });
    if (params.draggable) {
      enableDraggable();
    }
    if (el) {
      el.classList[swiper2.enabled ? "remove" : "add"](...classesToTokens(swiper2.params.scrollbar.lockClass));
    }
  }
  function destroy() {
    const params = swiper2.params.scrollbar;
    const el = swiper2.scrollbar.el;
    if (el) {
      el.classList.remove(...classesToTokens(swiper2.isHorizontal() ? params.horizontalClass : params.verticalClass));
    }
    disableDraggable();
  }
  on("changeDirection", () => {
    if (!swiper2.scrollbar || !swiper2.scrollbar.el)
      return;
    const params = swiper2.params.scrollbar;
    let {
      el
    } = swiper2.scrollbar;
    el = makeElementsArray(el);
    el.forEach((subEl) => {
      subEl.classList.remove(params.horizontalClass, params.verticalClass);
      subEl.classList.add(swiper2.isHorizontal() ? params.horizontalClass : params.verticalClass);
    });
  });
  on("init", () => {
    if (swiper2.params.scrollbar.enabled === false) {
      disable();
    } else {
      init2();
      updateSize2();
      setTranslate2();
    }
  });
  on("update resize observerUpdate lock unlock changeDirection", () => {
    updateSize2();
  });
  on("setTranslate", () => {
    setTranslate2();
  });
  on("setTransition", (_s, duration) => {
    setTransition2(duration);
  });
  on("enable disable", () => {
    const {
      el
    } = swiper2.scrollbar;
    if (el) {
      el.classList[swiper2.enabled ? "remove" : "add"](...classesToTokens(swiper2.params.scrollbar.lockClass));
    }
  });
  on("destroy", () => {
    destroy();
  });
  const enable = () => {
    swiper2.el.classList.remove(...classesToTokens(swiper2.params.scrollbar.scrollbarDisabledClass));
    if (swiper2.scrollbar.el) {
      swiper2.scrollbar.el.classList.remove(...classesToTokens(swiper2.params.scrollbar.scrollbarDisabledClass));
    }
    init2();
    updateSize2();
    setTranslate2();
  };
  const disable = () => {
    swiper2.el.classList.add(...classesToTokens(swiper2.params.scrollbar.scrollbarDisabledClass));
    if (swiper2.scrollbar.el) {
      swiper2.scrollbar.el.classList.add(...classesToTokens(swiper2.params.scrollbar.scrollbarDisabledClass));
    }
    destroy();
  };
  Object.assign(swiper2.scrollbar, {
    enable,
    disable,
    updateSize: updateSize2,
    setTranslate: setTranslate2,
    init: init2,
    destroy
  });
}
const init = async () => {
  const { default: Alpine } = await __vitePreload(() => import("./ma-chunk-module.esm-6060a866.js"), true ? [] : void 0, import.meta.url);
  const { default: AlpinePersist } = await __vitePreload(() => import("./ma-chunk-module.esm-76e08e03.js"), true ? [] : void 0, import.meta.url);
  const { default: AlpineIntersect } = await __vitePreload(() => import("./ma-chunk-module.esm-82e752cc.js"), true ? [] : void 0, import.meta.url);
  const { default: AlpineMorph } = await __vitePreload(() => import("./ma-chunk-module.esm-bb5d246a.js"), true ? [] : void 0, import.meta.url);
  const { default: AlpineFocus } = await __vitePreload(() => import("./ma-chunk-module.esm-12e7da14.js"), true ? [] : void 0, import.meta.url);
  const {
    MainBlogCustom,
    zoomImage,
    quickview,
    AccountResetPassword,
    CompareProducts
  } = await __vitePreload(() => import("./ma-chunk-index-f4defba7.js"), true ? [] : void 0, import.meta.url);
  Alpine.plugin(AlpinePersist);
  Alpine.plugin(AlpineIntersect);
  Alpine.plugin(AlpineMorph);
  Alpine.plugin(AlpineFocus);
  Alpine.plugin(MainBlogCustom);
  Alpine.plugin(zoomImage);
  Alpine.plugin(quickview);
  Alpine.plugin(AccountResetPassword);
  Alpine.plugin(CompareProducts);
  Alpine.start();
  window.Alpine = Alpine;
};
window.addEventListener("load", (event) => {
  init();
});
let defaults = {
  modules: [Navigation, Pagination, Scrollbar],
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
    draggable: false,
    dragSize: 110
  }
};
initSwipers(defaults);
function initSwipers(defaults2 = {}, selector = ".swiper-slider") {
  let swipers = document.querySelectorAll(selector);
  swipers.forEach((swiper2) => {
    let optionsData = swiper2.dataset.swiper ? JSON.parse(swiper2.dataset.swiper) : {};
    const onInitFn = typeof swiper2.dataset.swiperOnInitFunction === "string" ? {
      on: {
        init: (slider) => {
          if (typeof window[swiper2.dataset.swiperOnInitFunction] !== "function") {
            return;
          }
          window[swiper2.dataset.swiperOnInitFunction](slider);
        }
      }
    } : {};
    let options = {
      ...defaults2,
      ...optionsData,
      ...onInitFn
    };
    new Swiper(swiper2, options);
  });
}
initPopups();
function initPopups() {
  const popupBtns = document.querySelectorAll("[data-toggle-popup]");
  popupBtns.forEach((btn) => {
    btn.addEventListener("click", () => {
      const popupId = btn.getAttribute("for");
      const popup = document.getElementById(popupId);
      btn.classList.toggle("button--active");
      popup.classList.toggle("popup--active");
    });
  });
}
async function initProductBlocks() {
  var _a, _b, _c, _d, _e;
  const productBlocks = document.querySelectorAll("product-block");
  if (!(productBlocks == null ? void 0 : productBlocks.length)) {
    return;
  }
  const productIds = [...productBlocks].map((el) => {
    return el == null ? void 0 : el.getAttribute("product-id");
  }).filter((el) => !!el);
  if (!(productIds == null ? void 0 : productIds.length)) {
    return;
  }
  const limit = productIds.length;
  if (limit > 250) {
    productBlocks.forEach((productBlock) => {
      productBlock.parentNode.remove();
    });
    return;
  }
  const queryByTags = productIds.map((productId) => `tag:"bc_product_id:${productId}"`).join(" OR ");
  const ids = productIds.map((productId) => `gid://shopify/Product/${productId}`);
  const response = await fetch("/api/2023-07/graphql.json", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token": "0fc493aabdd689c3447b8e34b52b94f5"
    },
    body: JSON.stringify({
      query: `
        query MyQuery($query: String!, $limit: Int, $ids: [ID!]!) {
          productsByTags: products(query: $query, first: $limit) {
            edges {
              node {
                id
                handle
                featuredImage {
                  altText
                  url
                }
                title
                priceRange {
                  minVariantPrice {
                    amount
                    currencyCode
                  }
                }
                tags
              }
            }
          }

          productsByIds: nodes(ids: $ids) {
            ... on Product {
              id
              handle
              featuredImage {
                altText
                url
              }
              title
              priceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              tags
            }
          }
        }
      `,
      variables: {
        limit,
        query: queryByTags,
        ids
      }
    })
  }).then((res) => res.json());
  if (!response) {
    return;
  }
  const prodsByTags = (_c = ((_b = (_a = response.data) == null ? void 0 : _a.productsByTags) == null ? void 0 : _b.edges) || []) == null ? void 0 : _c.map((edge) => {
    return edge == null ? void 0 : edge.node;
  }).filter((product) => !!product);
  const prodsByIds = (_e = ((_d = response.data) == null ? void 0 : _d.productsByIds) || []) == null ? void 0 : _e.filter((prod) => {
    return !!prod && !prodsByTags.some((_prod) => _prod.id === (prod == null ? void 0 : prod.id));
  });
  const products = [
    ...prodsByTags,
    ...prodsByIds
  ];
  productBlocks.forEach((productBlock) => {
    const productId = productBlock == null ? void 0 : productBlock.getAttribute("product-id");
    if (!productId) {
      productBlock.parentNode.remove();
      return;
    }
    const product = products.find((p) => (p.tags || []).includes(`bc_product_id:${productId}`) || p.id.replace("gid://shopify/Product/", "") === productId);
    if (!product) {
      productBlock.parentNode.remove();
      return;
    }
    const formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });
    const formattedPrice = formatter.format(parseFloat(product.priceRange.minVariantPrice.amount));
    productBlock.insertAdjacentHTML("afterbegin", `
      <div x-init="$el.parentNode.parentNode.removeAttribute('href'), $el.parentNode.parentNode.removeAttribute('target');">
        <div class="builder-product--basic">
          <h4 class="builder-product--title">
            ${product.title}
          </h4>
          <div class="builder-product--image">
            <a href="/products/${product.handle}">
              <img src="${product.featuredImage.url}" alt="${product.featuredImage.altText}">
            </a>
          </div>
          <p class="builder-product--price">$${formattedPrice}</p>
          <button type="button" class="builder-product--add-to-cart" data-quickview-open="${product.handle}">
            Add To Cart
          </button>
        </div>
      </div>
    `);
  });
}
window.addEventListener("load", (event) => {
  initProductBlocks();
});
